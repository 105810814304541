import { useState, useEffect } from "react";

export function useMediaQueries(queries, values, defaultValue) {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      const handler = () => setValue(getValue);
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    },
    // We're disabling this line because we want to enforce this effect running
    // only on mount.
    //
    // Generally this is not recommended!!
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return value;
}
