/** @jsx jsx */
import { jsx } from "theme-ui";
import { keyframes } from "@emotion/core";

const fade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export function ActivityIndicator({
  color = "white",
  width = 48,
  speed = 0.6,
}: {
  color: string;
  width?: number;
  speed?: number;
}) {
  return (
    <svg viewBox="0 0 96 24" sx={{ width, height: width / 4 }}>
      <g sx={{ fill: color }}>
        <circle
          cx="12"
          cy="12"
          r="12"
          sx={{
            opacity: 0,
            animation: `${fade} ${speed}s ${(speed / 3) * 0}s ease infinite`,
          }}
        />
        <circle
          cx="48"
          cy="12"
          r="12"
          sx={{
            opacity: 0,
            animation: `${fade} ${speed}s ${(speed / 3) * 1}s ease infinite`,
          }}
        />
        <circle
          cx="84"
          cy="12"
          r="12"
          sx={{
            opacity: 0,
            animation: `${fade} ${speed}s ${(speed / 3) * 2}s ease infinite`,
          }}
        />
      </g>
    </svg>
  );
}
