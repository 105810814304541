/** @jsx jsx */
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { jsx, SxStyleProp } from "theme-ui";
import { usePrevious } from "../usePrevious";

const container = {
  hidden: {
    opacity: 0,
    transition: { when: "afterChildren" },
  },
  visible: {
    opacity: 1,
    // transition: { when: "beforeChildren" },
  },
};

const items = {
  hidden: (positionAtRest: any) => ({
    opacity: 0,
    ...positionAtRest,
    // transition: { duration: 0.15 },
  }),
  visible: { opacity: 1, x: 0, y: 0 },
};

export enum MammonAnimateListPresenceXDirection {
  NONE,
  FROM_LEFT,
  FROM_RIGHT,
}

export enum MammonAnimateListPresenceYDirection {
  NONE,
  FROM_TOP,
  FROM_BOTTOM,
}

const DEFAULT_WRAP_LIST_ITEMS = (children: ReactNode) => children;

export function MammonAnimateListPresence({
  list,
  getItemKey,
  renderListItem,
  xFromDirection,
  yFromDirection,
  wrapListItems = DEFAULT_WRAP_LIST_ITEMS,
  listSx,
  listItemSx,
}: {
  list: any[];
  getItemKey: (item: any) => string;
  renderListItem: (item: any) => ReactNode;
  xFromDirection: MammonAnimateListPresenceXDirection;
  yFromDirection: MammonAnimateListPresenceYDirection;
  wrapListItems?: (children: ReactNode) => ReactNode;
  listSx?: SxStyleProp;
  listItemSx?: SxStyleProp;
}) {
  const positionAtRest = useMemo(() => {
    return {
      x: 0,
      y: 0,
      ...(xFromDirection === MammonAnimateListPresenceXDirection.FROM_LEFT && {
        x: -10,
      }),
      ...(xFromDirection === MammonAnimateListPresenceXDirection.FROM_RIGHT && {
        x: 10,
      }),
      ...(yFromDirection === MammonAnimateListPresenceYDirection.FROM_TOP && {
        y: -10,
      }),
      ...(yFromDirection ===
        MammonAnimateListPresenceYDirection.FROM_BOTTOM && {
        y: 10,
      }),
    };
  }, [xFromDirection, yFromDirection]);

  // This is used to process a tick before exiting the parent container
  // which allows the inner AnimatePresence to run independently from the
  // outer one
  const prevList = (usePrevious(list) as unknown) as any[];
  const [hideList, setHideList] = useState(list.length === 0);

  useEffect(() => {
    if (prevList && prevList.length > 0 && list.length === 0) {
      setTimeout(() => {
        setHideList(true);
      }, 250);
    }
    if (prevList && prevList.length === 0 && list.length > 0) {
      setHideList(false);
    }
  }, [list, prevList]);

  return (
    <AnimatePresence>
      {!hideList && (
        <motion.div
          layout={true}
          key="container"
          variants={container}
          initial="hidden"
          animate="visible"
          exit="hidden"
          sx={listSx}
        >
          {wrapListItems(
            <AnimatePresence>
              {list.map(item => (
                <motion.div
                  key={String(getItemKey(item))}
                  layout="position"
                  custom={positionAtRest}
                  variants={items}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  sx={listItemSx}
                >
                  {renderListItem(item)}
                </motion.div>
              ))}
            </AnimatePresence>,
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
