import { useLocationCreatorWithPersistedSearchParams } from "@earnnest/earnnest-ui-web-library/src/routingHelpers";
import { LocationDescriptorObject } from "history";
import { useCallback } from "react";

export type CreateLocationFunc = (
  location: LocationDescriptorObject,
) => LocationDescriptorObject;

export function useDefaultLocationCreatorForPortal() {
  const createLocation = useLocationCreatorWithPersistedSearchParams();
  const createLocationWithParams = useCallback(
    (nextLocation: LocationDescriptorObject) => {
      return createLocation(nextLocation, ["auth"]);
    },
    [createLocation],
  );
  return createLocationWithParams;
}
