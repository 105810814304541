import { ParsedResponse } from "./apiHelpers";
import { useEffect } from "react";

export enum EarnnestClientErrorType {
  Unknown,
  CodeSplitError,
  AuthError,
  UnsupportedBrowser,
  NotFound, // Client-side routing can result in Not Found
  MalformedRequest,
  InvalidConfig,
  UnhandledRejection,
}

export enum EarnnestClientAuthErrorType {
  Unknown,
  LoginRequired,
  ConsentRequired,
  InvalidState,
}

export enum EarnnestServerErrorType {
  Unknown,
  UserFetchError,
}

export type EarnnestErrorOptions = {
  displayNotification?: boolean;
};

// Errors thrown from Auth0 have an additional error property with a code
// (i.e. invalid_login)
export type Auth0Error = Error & { error: string };

export class EarnnestError extends Error {
  options: EarnnestErrorOptions;
  constructor(
    message: string,
    options: EarnnestErrorOptions = { displayNotification: false },
  ) {
    super(message);
    this.options = options;
    Object.setPrototypeOf(this, EarnnestError.prototype);
  }
}

export class EarnnestClientError extends EarnnestError {
  clientErrorType: EarnnestClientErrorType;
  constructor(
    message: string,
    type: EarnnestClientErrorType = EarnnestClientErrorType.Unknown,
    options?: EarnnestErrorOptions,
  ) {
    super(message, options);
    this.clientErrorType = type;
    Object.setPrototypeOf(this, EarnnestClientError.prototype);
  }
}

export class EarnnestClientAuthError extends EarnnestClientError {
  authErrorType: EarnnestClientAuthErrorType;
  static fromAuth0Error(error: Auth0Error) {
    const { message, error: auth0Error } = error;
    if (auth0Error === "login_required") {
      return new EarnnestClientAuthError(
        message,
        EarnnestClientAuthErrorType.LoginRequired,
      );
    }
    if (auth0Error === "consent_required") {
      return new EarnnestClientAuthError(
        message,
        EarnnestClientAuthErrorType.ConsentRequired,
      );
    }
    if (message === "Invalid state") {
      return new EarnnestClientAuthError(
        message,
        EarnnestClientAuthErrorType.InvalidState,
      );
    }
    return new EarnnestClientAuthError(message);
  }
  constructor(
    message: string,
    type: EarnnestClientAuthErrorType = EarnnestClientAuthErrorType.Unknown,
    options?: EarnnestErrorOptions,
  ) {
    super(message, EarnnestClientErrorType.AuthError, options);
    this.authErrorType = type;
    Object.setPrototypeOf(this, EarnnestClientError.prototype);
  }
}

export class EarnnestServerError extends EarnnestError {
  request: Request;
  response: ParsedResponse;
  serverErrorType: EarnnestServerErrorType;
  constructor(
    message: string,
    request: Request,
    response: ParsedResponse,
    type: EarnnestServerErrorType = EarnnestServerErrorType.Unknown,
    options?: EarnnestErrorOptions,
  ) {
    super(message, options);
    this.request = request;
    this.response = response;
    this.serverErrorType = type;
    Object.setPrototypeOf(this, EarnnestServerError.prototype);
  }
}

export function TriggerNotFoundError() {
  useEffect(() => {
    throw new EarnnestClientError(
      "Page not found",
      EarnnestClientErrorType.NotFound,
    );
  }, []);
  return null;
}

export interface CodeSplitError extends Error {
  request?: string;
  type?: string;
}

export function isErrorFromFailureLoadingChunk(error: CodeSplitError) {
  return (
    error.name === "ChunkLoadError" ||
    (Boolean(error.request) && error.type === "missing")
  );
}
