import { padding, rgba, transitions } from "polished";
import { Theme } from "theme-ui";
import { MammonButtonState } from "./MammonButton/MammonButton";

declare module "theme-ui" {
  interface Theme {
    elements: any;
    iconSizes: any;
    movement: { spring: any };
  }
}

let theme: any = {};
theme.useBodyStyles = false;
theme.space = {
  xs: 8,
  sm: 24,
  md: 48,
  lg: 64,
};
// space: [0, 8, 24, 48, 64],
theme.radii = {
  standard: 4,
  card: 16,
};
theme.fonts = {
  body: "Ubuntu, sans-serif",
  heading: "Unna, serif",
};
theme.fontSizes = {
  body: 16,
  sectionHeading: 32,
  pageHeading: 48,
};
theme.fontWeights = {
  heading: "700",
};
theme.lineHeights = {
  body: "160%",
  heading: "120%",
};
theme.letterSpacings = {
  heading: "-0.02em",
};
theme.sizes = {
  icon: {
    xs: 10,
    sm: 16,
    md: 24,
    lg: 32,
  },
};
theme.colors = {
  white: "#fff",
  background: "#f9f9f9",
  body: "#484848",
  heading: "#484848",
  error: "#ed5828",
  grey1: "#484848",
  grey2: "#787878",
  grey3: "#ababab",
  grey4: "#d9d9d9",
  purple1: "#29283a",
  purple2: "#39384e",
  purple2Shadow: rgba("#39384e", 0.375),
  purple3: "#4f4c61",
  purple5: "#716f7e",
  green: "#30c97f",
  greenBackgroundOnFocus: rgba("#30c97f", 0.1),
  greenShadow: rgba("#30c97f", 0.375),
  blue1: "#41a0f9",
  kudzuGreenBg: "#30c97f",
  kudzuNeutralBg: "#eee",
  kudzuDarkBg: "#29283a",
  modalShadow: rgba("#1b1a23", 0.2),
};
theme.shadows = {
  z1: "0px 1px 4px rgba(0, 0, 0, 0.3)",
  standard: "0px 6px 12px rgba(0, 0, 0, 0.3)",
  elevated: "0px 6px 15px rgba(0, 0, 0, 0.375)",
};
theme.zIndices = {
  overlay: 100,
  notifications: 200,
};

theme.elements = {};
theme.elements.plainButton = {
  appearance: "none",
  background: "none",
  border: 0,
  padding: 0,
  margin: 0,
};
theme.elements.notification = {
  borderRadius: "standard",
  backgroundColor: "purple1",
  boxShadow: "standard",
  transition: "box-shadow 0.15s ease-out",
  "&:focus-within": {
    boxShadow: "elevated",
  },
};
theme.elements.errorNotification = {
  variant: "elements.notification",
  backgroundColor: "error",
};
theme.elements.fullScreenHeight = {
  minHeight: "100vh",
  "@supports (-webkit-touch-callout: none)": {
    minHeight: "-webkit-fill-available",
  },
};

theme.buttons = {};
theme.buttons.reset = {
  appearance: "none",
  m: 0,
  p: 0,
  background: "none",
  border: 0,
  borderRadius: 0,
};
theme.buttons.base = {
  variant: "buttons.reset",
  textDecoration: "none",
  textAlign: "center",
  borderWidth: 2,
  borderStyle: "solid",
  borderRadius: 999,
  outline: "none",
  ...transitions(["box-shadow", "background-color", "color"], "0.2s ease-out"),
  "&[disabled]": {
    cursor: "not-allowed",
  },
};
theme.buttons.small = {
  variant: "text.buttons.small",
  ...padding(5, 16),
  minWidth: 140,
};
theme.buttons.medium = {
  variant: "text.buttons.medium",
  ...padding(8, 16),
  minWidth: 160,
};
theme.buttons.large = {
  variant: "text.buttons.large",
  ...padding(14, 16),
  minWidth: 280,
};

theme.buttons.dark = {};
theme.buttons.dark.filled = {
  backgroundColor: "purple2",
  borderColor: "purple2",
  color: "white",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.purple2Shadow}`,
  },
};
theme.buttons.dark.hollow = {
  backgroundColor: "transparent",
  borderColor: "purple2",
  color: "purple2",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.purple2Shadow}`,
  },
};

theme.buttons.green = {};
theme.buttons.green.filled = {
  backgroundColor: "green",
  borderColor: "green",
  color: "white",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.greenShadow}`,
  },
};
theme.buttons.green.hollow = {
  backgroundColor: "transparent",
  borderColor: "green",
  color: "green",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.greenShadow}`,
  },
};

theme.buttons.light = {};
theme.buttons.light.filled = {
  backgroundColor: "white",
  borderColor: "white",
  color: "purple2",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.purple2Shadow}`,
  },
};
theme.buttons.light.hollow = {
  borderColor: "white",
  backgroundColor: "transparent",
  color: "white",
  "&:focus": {
    boxShadow: (theme: Theme) => `0 0 0 .15em ${theme.colors?.purple2Shadow}`,
  },
};

theme.buttons[MammonButtonState.Initial] = {};
theme.buttons[MammonButtonState.Initial].filled = {};
theme.buttons[MammonButtonState.Initial].hollow = {};

theme.buttons[MammonButtonState.Disabled] = {};
theme.buttons[MammonButtonState.Disabled].filled = {
  backgroundColor: "#d9d9d9",
  borderColor: "#d9d9d9",
};
theme.buttons[MammonButtonState.Disabled].hollow = {
  borderColor: "#d9d9d9",
  color: "#d9d9d9",
};

theme.buttons[MammonButtonState.Processing] = {};
theme.buttons[MammonButtonState.Processing].filled = {
  backgroundColor: "#d9d9d9",
  borderColor: "#d9d9d9",
};
theme.buttons[MammonButtonState.Processing].hollow = {
  borderColor: "#d9d9d9",
  color: "#d9d9d9",
};

theme.buttons[MammonButtonState.Processed] = {};
theme.buttons[MammonButtonState.Processed].filled = {};
theme.buttons[MammonButtonState.Processed].hollow = {};

theme.buttons.actionText = {};
theme.buttons.actionText.green = {
  variant: "buttons.reset",
  px: 4,
  py: 2,
  mx: -4,
  my: -2,
  outline: "none",
  backgroundColor: "transparent",
  transition: "background-color .2s ease-out",
  "&:focus": {
    backgroundColor: "greenBackgroundOnFocus",
  },
  "&:focus .text": {
    // This property would allow us to show an underline
    // under the text as a focus state
    borderBottomColor: "transparent",
  },
};

theme.text = {
  notification: {
    fontSize: 16,
    lineHeight: "120%",
    color: "white",
  },
  buttons: {
    small: {
      fontFamily: "Ubuntu",
      fontSize: 14 / 16 + "rem",
      fontWeight: "500",
      letterSpacing: "0.02em",
      lineHeight: "100%",
    },
    medium: {
      fontFamily: "Ubuntu",
      fontSize: 16 / 16 + "rem",
      fontWeight: "500",
      letterSpacing: "0.02em",
      lineHeight: "100%",
    },
    large: {
      fontFamily: "Ubuntu",
      fontSize: 20 / 16 + "rem",
      fontWeight: "500",
      lineHeight: "100%",
    },
  },
  body: {
    pageTitle: {
      fontFamily: "Unna",
      fontSize: "3rem", // 48px
      fontWeight: "700",
      fontStyle: "italic",
      lineHeight: "120%",
      letterSpacing: "-0.02em",
    },
    subPageTitle: {
      fontFamily: "Unna",
      fontSize: "2rem", // 32px
      fontWeight: "700",
      fontStyle: "italic",
      lineHeight: "120%",
      letterSpacing: "-0.02em",
    },
    sectionTitle: {
      fontFamily: "Ubuntu",
      fontSize: 20 / 16 + "rem",
      fontWeight: "500",
      lineHeight: "140%",
    },
    subSectionTitle: {
      fontFamily: "Ubuntu",
      fontSize: 16 / 16 + "rem",
      fontWeight: "500",
      lineHeight: "140%",
    },
    paragraph: {
      fontFamily: "Ubuntu",
      fontSize: "1rem", // 16px
      fontWeight: "400",
      lineHeight: "160%",
    },
  },
  pageHeading: {
    fontFamily: "heading",
    fontStyle: "italic",
    fontSize: "pageHeading",
    lineHeight: "heading",
    letterSpacing: "heading",
    fontWeight: "heading",
    color: "heading",
  },
  slat: {
    heading: {
      fontFamily: "Ubuntu",
      fontSize: "1rem",
      fontWeight: "500",
      lineHeight: "120%",
    },
    paragraph: {
      fontFamily: "Ubuntu",
      fontSize: 12 / 16 + "rem",
      lineHeight: "140%",
      letterSpacing: "0.02em",
    },
  },
  form: {
    inputLabel: {
      fontFamily: "Ubuntu",
      fontSize: 12 / 16 + "rem",
      fontWeight: "400",
      lineHeight: "120%",
    },
    inputAssist: {
      fontFamily: "Ubuntu",
      fontSize: 10 / 16 + "rem",
      lineHeight: "140%",
      color: "grey1",
    },
    inputText: {
      fontSize: 16 / 16 + "rem",
      lineHeight: "140%",
      color: "grey1",
    },
  },
};

theme.movement = {};
theme.movement.spring = { type: "spring", stiffness: "120", mass: 0.5 };

export { theme };
