import { useRequiredConfig } from "./Config/Config";
import React, { ReactNode, useEffect, useMemo } from "react";

declare global {
  interface Window {
    analytics: any; // Segment
    Sentry: any; // Sentry
    console: any;
  }
}

const AnalyticsJsMethods = [
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware",
  "addIntegrationMiddleware",
  "setAnonymousId",
  "addDestinationMiddleware",
];

// TODO: Determine actual definitions
export type DefaultTrackingFn = (...args: any) => any;
export type TrackingApi = {
  track: (event: string, properties: Object) => void;
  captureException: (exception: Error) => void;
  captureMessage: (message: string) => void;
  [k: string]: DefaultTrackingFn;
};

// For now, this can be easiy derived. If state is required in the future,
// we can move it to context.
//
// We also memo-ize so as to not have any expensive operations
export const useTracking = () => {
  const api = useMemo(() => {
    return makeExtendedTrackingApi();
  }, []);
  return api;
};

export function SetupTracking({ children }: { children: ReactNode }) {
  const { config } = useRequiredConfig();

  useEffect(() => {
    console.log("[Tracking] Setting up tracking...");

    var analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error("Segment snippet included twice.");
      }
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = AnalyticsJsMethods;

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function(method: any) {
      return function() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function(key: any, options: any) {
      // Create an async script element based on your key.
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";

      // Insert our script next to the first script element.
      var first = document.getElementsByTagName("script")[0];
      (first.parentNode as Element).insertBefore(script, first);
      analytics._loadOptions = options;
    };

    analytics.SNIPPET_VERSION = "4.1.0";
    analytics.load(config.segmentWriteKey);
  }, [config.segmentWriteKey]);

  return <>{children}</>;
}

function makeExtendedTrackingApi() {
  const buildGenericApiMethod = (namespace: any, method: string) => (
    ...args: any
  ) => {
    return namespace[method](...args);
  };

  const defaultAnalyticsJsApi = AnalyticsJsMethods.reduce((apiObj, method) => {
    return {
      ...apiObj,
      [method]: buildGenericApiMethod(window.analytics, method),
    };
  }, {});

  // Extend the default analytics js API with methods of our own.
  // Mostly for error reporting.
  const extendedTrackingApi = {
    ...defaultAnalyticsJsApi,
    captureException: buildGenericApiMethod(window.Sentry, "captureExpection"),
  } as TrackingApi;

  return extendedTrackingApi;
}
