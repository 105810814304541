/** @jsx jsx */
import { ButtonProps, InputProps, jsx, BoxProps } from "theme-ui";
import { transitions } from "polished";
import { FocusableElement } from "./FocusableElement";

export enum MammonInputBoxType {
  Underline,
  Outline,
}

type UnderlyingElementProps = InputProps | ButtonProps;
export type MammonInputProps = UnderlyingElementProps & {
  boxType?: MammonInputBoxType;
  hasFocus?: boolean;
  hidePlaceholder?: boolean;
  isErrored?: boolean;
  hasIcon?: boolean;
  placeholder?: string;
};

export function MammonInput({
  as: As = "input",
  boxType = MammonInputBoxType.Underline,
  hasFocus = false,
  isErrored = false,
  hasIcon = false,
  hidePlaceholder = false,
  sx: inSx = {},
  ...restProps
}: MammonInputProps) {
  return (
    <FocusableElement
      as={As}
      hasFocus={hasFocus}
      variant="form.inputText"
      sx={{
        appearance: "none",
        margin: 0,
        padding: 8,
        paddingRight: hasIcon ? 24 + 8 + 8 : 8,
        outline: "none",
        textAlign: "left",
        color: "inherit",
        backgroundColor: "transparent",
        borderRadius: 0,
        border: 0,
        borderColor: isErrored ? "error" : hasFocus ? "green" : "purple5",
        borderStyle: "solid",
        ...transitions(["border-color", "opacity"], ".15s ease-out"),
        ...(boxType === MammonInputBoxType.Outline && {
          borderWidth: 1,
          borderRadius: 4,
        }),
        ...(boxType === MammonInputBoxType.Underline && {
          borderBottomWidth: 2,
        }),
        "&::placeholder": {
          color: "grey3",
          transition: "opacity .15s ease-out",
          opacity: hidePlaceholder ? 0 : 1,
        },
        "&[disabled]": {
          opacity: 0.5,
          cursor: "not-allowed",
        },
        "&[readonly]": {
          opacity: 1,
          cursor: "not-allowed",
        },
        ...inSx,
      }}
      {...(restProps as BoxProps)}
    />
  );
}
