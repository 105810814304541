/** @jsx jsx */
import { css, Global } from "@emotion/core";
import upperFirst from "lodash/upperFirst";
import { linearGradient, margin, rgba } from "polished";
import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Flex, jsx, useThemeUI, FlexProps, BoxProps } from "theme-ui";
import { useMediaQueries } from "../useMediaQueries";
import wavePatternSvgGreen from "./wave-pattern-green.svg";
import wavePatternSvgWhite from "./wave-pattern-white.svg";

type EdgeSetterFn = (arg: EdgeProp) => void;

type EdgeProps = {
  children: ReactNode;
  sticky?: boolean;
};

type EdgeProp = EdgeProps | null;

interface MammonCardLayoutContextType {
  header: EdgeProp;
  setHeader: EdgeSetterFn;
  footer: EdgeProp;
  setFooter: EdgeSetterFn;
  theme: MammonCardLayoutTheme;
  isSmallScreen: boolean;
}

interface MammonCardLayoutProps {
  theme?: MammonCardLayoutTheme;
  children: ReactNode;
}

export enum MammonCardLayoutTheme {
  KUDZU,
  NEUTRAL,
  UNSTABLE_DARK,
}

const MammonCardLayoutContext = React.createContext<
  MammonCardLayoutContextType
>({
  header: null,
  setHeader: () => {},
  footer: null,
  setFooter: () => {},
  theme: MammonCardLayoutTheme.NEUTRAL,
  isSmallScreen: true,
});

export const useMammonCardLayout = () => useContext(MammonCardLayoutContext);

export function MammonCardLayout({
  theme: cardLayoutTheme = MammonCardLayoutTheme.NEUTRAL,
  children,
  ...restProps
}: MammonCardLayoutProps & BoxProps) {
  const isSmallScreen = useMediaQueries(["(min-width: 481px)"], [false], true);
  const [header, setHeader] = useState<EdgeProp>(null);
  const [footer, setFooter] = useState<EdgeProp>(null);
  const context = useMemo(() => {
    return {
      theme: cardLayoutTheme,
      isSmallScreen,
      header,
      setHeader,
      footer,
      setFooter,
    };
  }, [cardLayoutTheme, header, footer, isSmallScreen]);
  return (
    <MammonCardLayoutContext.Provider value={context}>
      <Fragment>
        <GlobalMammonCardLayoutStyles />
        <Container {...restProps}>
          <Card>
            {header ? <FilledCardHeader {...header} /> : <EmptyCardHeader />}
            <CardBody>{children}</CardBody>
            {footer && <CardFooter {...footer} />}
          </Card>
        </Container>
      </Fragment>
    </MammonCardLayoutContext.Provider>
  );
}

// This is a separate export to allow for splitting up certain pieces
// of the card layout component heierarchy. Useful for establishing
// global styles at the top of a higher tree from the container, for instance.
export function SetupMammonCardLayout({
  theme: cardLayoutTheme = MammonCardLayoutTheme.NEUTRAL,
  children,
}: MammonCardLayoutProps) {
  const isSmallScreen = useMediaQueries(["(min-width: 481px)"], [false], true);
  const [header, setHeader] = useState<EdgeProp>(null);
  const [footer, setFooter] = useState<EdgeProp>(null);
  const context = useMemo(() => {
    return {
      theme: cardLayoutTheme,
      isSmallScreen,
      header,
      setHeader,
      footer,
      setFooter,
    };
  }, [cardLayoutTheme, header, footer, isSmallScreen]);
  return (
    <MammonCardLayoutContext.Provider value={context}>
      {children}
    </MammonCardLayoutContext.Provider>
  );
}

export function MammonCardLayoutContainer({
  children,
  ...restProps
}: BoxProps) {
  const { header, footer } = useMammonCardLayout();
  return (
    <Container {...restProps}>
      <Card>
        {header ? <FilledCardHeader {...header} /> : <EmptyCardHeader />}
        <CardBody>{children}</CardBody>
        {footer && <CardFooter {...footer} />}
      </Card>
    </Container>
  );
}

function MammonCardLayoutEdge({
  setEdge,
  children,
  sticky,
}: {
  setEdge: EdgeSetterFn;
} & EdgeProps) {
  useEffect(() => {
    return () => {
      setEdge(null);
    };
  }, [setEdge]);
  useEffect(() => {
    setEdge({ children, sticky });
  }, [children, sticky, setEdge]);
  return null;
}

export function MammonCardLayoutHeader(props: EdgeProps) {
  const { setHeader } = useMammonCardLayout();
  return <MammonCardLayoutEdge {...props} setEdge={setHeader} />;
}

export function MammonCardLayoutFooter(props: EdgeProps) {
  const { setFooter } = useMammonCardLayout();
  return <MammonCardLayoutEdge {...props} setEdge={setFooter} />;
}

export function GlobalMammonCardLayoutStyles() {
  const { theme: cardLayoutTheme } = useMammonCardLayout();
  const { theme } = useThemeUI();
  const themeToBackground = {
    [MammonCardLayoutTheme.KUDZU]: `center no-repeat fixed ${theme.colors?.kudzuGreenBg} url('${wavePatternSvgGreen}')`,
    [MammonCardLayoutTheme.NEUTRAL]: `center no-repeat fixed ${theme.colors?.kudzuNeutralBg} url('${wavePatternSvgWhite}')`,
    [MammonCardLayoutTheme.UNSTABLE_DARK]: `center no-repeat fixed ${theme.colors?.kudzuDarkBg} url('${wavePatternSvgWhite}')`,
  };
  return (
    <Global
      styles={css`
        html {
          background: ${theme.colors?.background};
        }
        @media (min-width: 481px) {
          html {
            background: ${themeToBackground[cardLayoutTheme]};
          }
        }
        @media (max-width: 480px) {
          html,
          body,
          #root {
            height: 100%;
          }
        }
      `}
    />
  );
}

function CardEdge({
  children,
  sticky = false,
  stickyAnchor,
}: {
  children: ReactNode;
  sticky?: boolean;
  stickyAnchor: "top" | "bottom";
}) {
  const { theme } = useThemeUI();
  const flippedStickyAnchor = { top: "bottom", bottom: "top" }[stickyAnchor];
  return (
    <Flex
      paddingY={16}
      paddingX={24}
      sx={{
        flex: "0 0 auto",
        ...(sticky && {
          position: "sticky",
          [stickyAnchor]: 0,
          zIndex: 1,
        }),
        flexDirection: "column",
        ...linearGradient({
          colorStops: [
            theme.colors?.background + " 0%",
            theme.colors?.background + " 66.7%",
            rgba(theme.colors?.background as string, 0.0) + " 100%",
          ],
          toDirection: `to ${flippedStickyAnchor}`,
          fallback: "transparent",
        }),
        [`border${upperFirst(stickyAnchor)}LeftRadius`]: "1rem",
        [`border${upperFirst(stickyAnchor)}RightRadius`]: "1rem",
      }}
    >
      {children}
    </Flex>
  );
}

function FilledCardHeader({
  children,
  sticky,
}: {
  children: ReactNode;
  sticky?: boolean;
}) {
  return <CardEdge children={children} sticky={sticky} stickyAnchor="top" />;
}

function EmptyCardHeader() {
  const { theme } = useThemeUI();
  return (
    <Flex
      bg={theme.colors?.background}
      sx={{
        flex: "0 0 auto",
        top: 0,
        height: 16 + 24 + 16,
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
    >
      &nbsp;
    </Flex>
  );
}

function CardFooter({
  children,
  sticky,
}: {
  children: ReactNode;
  sticky?: boolean;
}) {
  return <CardEdge children={children} sticky={sticky} stickyAnchor="bottom" />;
}

function CardBody({ children }: { children: ReactNode }) {
  return (
    <Flex
      paddingTop={8}
      paddingBottom={32}
      paddingX={24}
      css={{
        flex: "1 0 auto",
        flexDirection: "column",
      }}
    >
      {children}
    </Flex>
  );
}

function Card({ children }: { children: ReactNode }) {
  const { theme } = useThemeUI();
  const { isSmallScreen } = useMammonCardLayout();
  const isNotSmallScreen = !isSmallScreen;
  return (
    <Flex
      bg={theme.colors?.background}
      sx={{
        position: "static",
        flexDirection: "column",
        width: "100%",
        maxWidth: 480,
        ...(isNotSmallScreen && {
          minHeight: `calc(100vh - ${72}px)`,
          [`@media (min-height: ${648 + 72}px)`]: {
            minHeight: 648,
          },
          ...margin("auto"),
          borderRadius: "1rem",
          boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
        }),
      }}
    >
      {children}
    </Flex>
  );
}

function Container({ children, ...restProps }: BoxProps) {
  const { isSmallScreen } = useMammonCardLayout();
  return (
    <Flex
      paddingY={isSmallScreen ? null : 36}
      sx={{
        width: "100%",
        height: isSmallScreen ? "100%" : undefined,
        minHeight: isSmallScreen ? undefined : "100vh",
      }}
      {...restProps}
    >
      {children}
    </Flex>
  );
}

export function MammonCardLayoutSplitHeader(props: FlexProps) {
  return (
    <Flex
      {...props}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    />
  );
}
