/** @jsx jsx */
import { css, Global } from "@emotion/core";
import { motion } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import { Flex, jsx, Text, useThemeUI } from "theme-ui";
import { Stack } from "../Stack/Stack";
import wave from "./wave.svg";

const container = {
  initial: { opacity: 0 },
  hidden: {
    opacity: 0,
    transition: { when: "afterChildren" },
  },
  visible: {
    opacity: 1,
    transition: { delayChildren: 0.5, staggerChildren: 0.75 },
  },
};

const text = {
  initial: { scale: 0.9, y: "5%", opacity: 0 },
  hidden: { scale: 1.0, y: 0, opacity: 0 },
  visible: { scale: 1.0, y: 0, opacity: 1.0 },
};

const ancillaryTextVariant = {
  initial: { opacity: 0 },
  hidden: { opacity: 0 },
  visible: { opacity: 1.0, transition: { delay: 3 } },
};

const cardVariant = {
  initial: { opacity: 0, y: "5%" },
  hidden: { opacity: 0, y: "5%" },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.5 },
  },
};

const DEFAULT_ON_BACKGROUND_ANIMATION_COMPLETE = () => {};

export type MammonCelebrationProps = {
  label: ReactNode;
  ancillaryText?: ReactNode;
  card?: ReactNode;
  onBackgroundAnimationComplete?: () => void;
};

export function MammonCelebration({
  label,
  ancillaryText,
  card,
  onBackgroundAnimationComplete = DEFAULT_ON_BACKGROUND_ANIMATION_COMPLETE,
}: MammonCelebrationProps) {
  const [showCard, setShowCard] = useState(false);
  // This needs to be controlled by framer motion somehow,
  // instead of by a timeout. For instance, right now, with it not being
  // controlled by framer, if the initial animation is set to not run, it
  // will still produce the delay, instead of no animation, which is not
  // what we want.
  //
  // Look into different framer motion component hooks, instead of the
  // timeout.
  useEffect(() => {
    setTimeout(() => {
      setShowCard(true);
    }, 2000);
  }, []);
  return (
    <motion.div
      variants={container}
      initial="initial"
      animate="visible"
      exit="hidden"
      onAnimationComplete={onBackgroundAnimationComplete}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "green",
      }}
    >
      <motion.div
        layout="position"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          // Because margin is 'auto', we need to tell it to try to expand
          width: "100%",
          maxWidth: 452,
        }}
      >
        <motion.div
          variants={text}
          sx={{
            display: "flex",
            minHeight: 452,
            alignItems: "flex-start",
            justifyContent: "center",
            background: `url(${wave}) center center no-repeat`,
          }}
        >
          <Stack
            gap={4 * 4}
            mt={
              // Wave height
              452 / 2 -
              // Icon height
              96 / 2 -
              // Icon stroke offset
              2
            }
            sx={{ justifyItems: "center" }}
          >
            <Flex>
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M94 48C94 73.4051 73.4051 94 48 94C22.5949 94 2 73.4051 2 48C2 22.5949 22.5949 2 48 2C73.4051 2 94 22.5949 94 48Z"
                  stroke="#EEEEEE"
                  strokeWidth="4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M68.6109 33.2762C70.4129 35.0261 70.469 37.92 68.7364 39.7399L46.9753 62.5971C46.0921 63.5247 44.8627 64.0334 43.589 63.9983C42.3152 63.9631 41.1151 63.3874 40.2834 62.4124L28.0972 48.1267C26.4656 46.2139 26.6781 43.3274 28.572 41.6795C30.4659 40.0316 33.3239 40.2463 34.9556 42.159L43.8963 52.6401L62.211 33.4029C63.9436 31.583 66.809 31.5263 68.6109 33.2762Z"
                  fill="white"
                />
              </svg>
            </Flex>
            <Flex>
              <Text
                variant="body.pageTitle"
                color="white"
                sx={{ textAlign: "center" }}
              >
                {label}
              </Text>
            </Flex>
            <motion.div
              variants={ancillaryTextVariant}
              sx={{ display: "flex" }}
            >
              <Text variant="body.paragraph" color="white">
                {ancillaryText ? (
                  ancillaryText
                ) : (
                  <React.Fragment>&nbsp;</React.Fragment>
                )}
              </Text>
            </motion.div>
          </Stack>
        </motion.div>
        {showCard && card && (
          <motion.div
            variants={cardVariant}
            sx={{
              display: "flex",
              mt: -12,
              px: 12,
              pb: 48,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Flex
              py={32}
              px={24}
              backgroundColor="background"
              sx={{ borderRadius: "card" }}
            >
              {card}
            </Flex>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}

export function MammonCelebrationTakeover({
  onBackgroundAnimationComplete = DEFAULT_ON_BACKGROUND_ANIMATION_COMPLETE,
  ...restProps
}: MammonCelebrationProps) {
  const { theme } = useThemeUI();
  const [animationComplete, setAnimationComplete] = useState(false);
  return (
    <Flex
      sx={{
        flexDirection: "column",
        "& > *": { ...theme.elements.fullScreenHeight },
      }}
    >
      {animationComplete && (
        <Global
          styles={css`
            html {
              background: ${theme.colors?.green};
            }
          `}
        />
      )}
      <MammonCelebration
        {...restProps}
        onBackgroundAnimationComplete={() => {
          setAnimationComplete(true);
          onBackgroundAnimationComplete();
        }}
      />
    </Flex>
  );
}

// This will allow the wave background to already be loaded by the
// time celebration runs. Otherwise, it doesn't quite look as good
export function preloadMammonCelebration(onLoad = () => {}) {
  const waveImg = new Image();
  waveImg.onload = onLoad;
  waveImg.src = wave;
}

export function useMammonCelebrationPreloader(onLoad = () => {}) {
  useEffect(
    () => {
      const timeout = setTimeout(() => {
        preloadMammonCelebration(onLoad);
      });
      return () => {
        clearTimeout(timeout);
      };
    },
    // We DO want to enforce only running on mount
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
