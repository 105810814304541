import { FetchEscrowAccountFunc } from "@earnnest/earnnest-ui-web-library/src/api";
import { ProcessingStates } from "@earnnest/earnnest-ui-web-library/src/common";
import React, { ReactNode, useContext, useEffect } from "react";
import { useEffectReducer } from "use-effect-reducer";

export type FetchEscrowAccountProps = {
  escrowAccountId?: string;
  fetchEscrowAccount: FetchEscrowAccountFunc;
  children: ReactNode;
};

export type FetchEscrowAccountContextType = {
  processingState: ProcessingStates;
  escrowAccount: any | null;
  error: Error | null;
};

const FetchEscrowAccountContext = React.createContext<
  FetchEscrowAccountContextType
>({
  processingState: ProcessingStates.Idle,
  escrowAccount: null,
  error: null,
});

export const useFetchedEscrowAccount = () =>
  useContext(FetchEscrowAccountContext);

export function FetchEscrowAccount({
  fetchEscrowAccount,
  escrowAccountId,
  children,
}: FetchEscrowAccountProps) {
  const [state, dispatch] = useEffectReducer(
    (
      state: {
        processingState: ProcessingStates;
        escrowAccount: string | null;
        error: Error | null;
      },
      action:
        | { type: "escrow_account_matches"; payload: any }
        | { type: "escrow_account_invalid"; payload: Error },
    ) => {
      switch (action.type) {
        case "escrow_account_matches":
          return {
            ...state,
            escrowAccount: action.payload,
            processingState: ProcessingStates.Processed,
          };
        case "escrow_account_invalid":
          return {
            ...state,
            processingState: ProcessingStates.Error,
            error: action.payload,
          };
        default:
          throw new Error(
            "[RequestPortalRoute] Unrecognized action in primary dispatcher",
          );
      }
    },
    () => {
      const initialProcessingState = !escrowAccountId
        ? ProcessingStates.Processed
        : ProcessingStates.Processing;
      return {
        processingState: initialProcessingState,
        escrowAccount: null,
        error: null,
      };
    },
  );

  useEffect(() => {
    if (state.processingState === ProcessingStates.Processed) {
      return;
    }

    (async () => {
      try {
        // Effect will not run if prefilledEscrowAccount is undefined
        const { escrowAccount } = await fetchEscrowAccount(escrowAccountId!);
        dispatch({
          type: "escrow_account_matches",
          payload: {
            id: escrowAccount.id,
            brandName: escrowAccount.organization?.name,
            bankFriendlyName: escrowAccount.name,
            organizationEmail: escrowAccount.organization?.email,
            operatingRegion: escrowAccount.operatingRegion,
            maxAmount: escrowAccount.maxAmount,
          },
        });
      } catch (error) {
        dispatch({ type: "escrow_account_invalid", payload: error });
      }
    })();
  }, [dispatch, escrowAccountId, fetchEscrowAccount, state.processingState]);

  return (
    <FetchEscrowAccountContext.Provider value={state}>
      {children}
    </FetchEscrowAccountContext.Provider>
  );
}
