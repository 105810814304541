import { margin, padding } from "polished";
import React from "react";
import { Button, ButtonProps, Flex, Text } from "theme-ui";
import { IconSize } from "../Icon";
import { IconCaretLeft } from "../IconCaretLeft";
import { IconCaretRight } from "../IconCaretRight";

export enum MammonTextNavDirection {
  Neutral,
  Progression,
  Regression,
}

export type MammonTextNavProps = ButtonProps & {
  color?: string;
  direction?: MammonTextNavDirection;
};

export function MammonTextNav({
  color = "green",
  direction = MammonTextNavDirection.Neutral,
  children,
  ...restProps
}: MammonTextNavProps) {
  return (
    <Button
      as="button"
      type="button"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        outline: "none",
        ...padding(4),
        ...margin(-4),
        backgroundColor: "transparent",
        transition: "background-color .2s ease-out",
        "&:focus": {
          backgroundColor: `${color}BackgroundOnFocus`,
        },
        "&:focus .children-text": {
          // This property would allow us to show an underline
          // under the text as a focus state
          borderBottomColor: "transparent",
        },
      }}
      {...restProps}
    >
      {direction === MammonTextNavDirection.Regression && (
        <Flex mr={4} ml={-2}>
          <IconCaretLeft size={IconSize.SM} color={color} />
        </Flex>
      )}
      <Flex>
        <Text
          className="children-text"
          variant="buttons.small"
          color={color}
          sx={{
            transition: "border .2s ease-out",
            border: "0 solid transparent",
            borderTopWidth: 1,
            borderBottomWidth: 1,
          }}
        >
          {children}
        </Text>
      </Flex>
      {direction === MammonTextNavDirection.Progression && (
        <Flex ml={4} mr={-2}>
          <IconCaretRight size={IconSize.SM} color={color} />
        </Flex>
      )}
    </Button>
  );
}
