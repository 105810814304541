import { MammonHelpLink } from "@earnnest/earnnest-ui-web-library/src/MammonHelpLink/MammonHelpLink";
import { Stack } from "@earnnest/earnnest-ui-web-library/src/Stack/Stack";
import React from "react";
import { Flex, Text } from "theme-ui";
import { DefaultPortalOverlayContent } from "./DefaultPortalOverlayContent";

export type GetHelpOverlayContentProps = {
  onClose: () => void;
};

export function GetHelpOverlayContent({ onClose }: GetHelpOverlayContentProps) {
  return (
    <DefaultPortalOverlayContent onClose={onClose}>
      <Stack gap={36}>
        <Stack gap={8}>
          <Flex>
            <Text variant="body.pageTitle">Get help</Text>
          </Flex>
          <Flex>
            <Text variant="body.paragraph">
              We are here to help with requesting, or to answer questions you
              might have. Please select which help option is right for you.
            </Text>
          </Flex>
        </Stack>
        <Stack gap={24}>
          <Flex>
            <MammonHelpLink
              heading={<>FAQ</>}
              description={<>Self-help resource</>}
              href="https://earnnest.com/resources/faq"
              target="_blank"
              children={<>Find an answer</>}
            />
          </Flex>
          <Flex>
            <MammonHelpLink
              heading={<>Email support</>}
              description={
                <>
                  Any time
                  <br />
                  Send us an email and we’ll get back to you within (1) business
                  day.
                </>
              }
              href="mailto:support@earnnest.com"
              target="_blank"
              children={<>support@earnnest.com</>}
            />
          </Flex>
          <Flex>
            <MammonHelpLink
              heading={<>Phone support</>}
              description={
                <>
                  Monday – Friday
                  <br />8 a.m. to 8 p.m. EST
                </>
              }
              href="tel:18888702336"
              target="_blank"
              children={<>1-888-870-2336</>}
            />
          </Flex>
        </Stack>
      </Stack>
    </DefaultPortalOverlayContent>
  );
}
