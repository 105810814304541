import { useEffect, RefObject } from "react";

export function useFocusSync<T extends HTMLElement>(
  ref: RefObject<T>,
  focus: boolean,
) {
  useEffect(() => {
    if (focus && ref && ref.current && ref.current !== document.activeElement) {
      ref.current.focus();
    }
    if (
      !focus &&
      ref &&
      ref.current &&
      ref.current === document.activeElement
    ) {
      ref.current.blur();
    }
  }, [ref, focus]);
}
