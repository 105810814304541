import React, { FormEvent, useCallback, useEffect, useRef } from "react";
import MaskedInputFromLibrary from "react-text-mask";

interface MaskInputProps extends React.HTMLProps<HTMLInputElement> {
  guide?: any;
  pipe?: any;
  mask?: any;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskInputProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const setRef = useCallback(
      (instance: MaskedInputFromLibrary | null) => {
        inputRef.current = instance
          ? (instance.inputElement as HTMLInputElement)
          : null;
        if (typeof ref === "function") {
          ref(inputRef.current);
        } else if (ref) {
          // We need ref.current to be writable, and this
          // massaging is required to pass to react-text-mask
          (ref as any).current = inputRef.current;
        }
      },
      [ref],
    );

    // Trigger a change event on the masked input on mount if the incoming
    // value differs from the one in the local input state, so that the
    // mask functionality can run on it
    useEffect(
      () => {
        const { value, onChange = () => {} } = props;
        if (inputRef.current && value !== inputRef.current.value) {
          const target = inputRef.current;
          const event = ({ target } as unknown) as FormEvent<HTMLInputElement>;
          onChange(event);
        }
      },
      // Disabling this because we want this change to
      // only fire if there is an initial change on mount
      //
      // eslint-disable-next-line
      [],
    );

    // Don't know why "any" needs to be explicit here
    return <MaskedInputFromLibrary ref={setRef as any} {...props} />;
  },
);

export { MaskedInput };
