import { useCallback } from "react";
import { useIsMounted } from "./useIsMounted";

export function useDispatchOnlyIfMounted<T extends (...args: any) => any>(
  dispatch: T,
) {
  const isMounted = useIsMounted();
  const dispatchSafely = useCallback(
    (...args: Parameters<T>) => {
      if (!isMounted.current) {
        console.log(
          "[User] Skipping dispatch due to component being unmounted",
        );
        return;
      }
      dispatch(...args);
    },
    [dispatch, isMounted],
  );
  return dispatchSafely;
}
