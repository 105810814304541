import React, { useCallback } from "react";
import { MaskedInput } from "./MaskedInput";

export const STATE_ABBREVIATION_MASK = [/[A-Z]/i, /[A-Z]/i];

const StateAbbreviationInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const pipe = useCallback(conformedValue => conformedValue.toUpperCase(), []);
  return (
    <MaskedInput
      guide={false}
      mask={STATE_ABBREVIATION_MASK}
      pipe={pipe}
      ref={ref as any}
      {...props}
    />
  );
});

export { StateAbbreviationInput };
