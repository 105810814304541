import React, { ReactNode } from "react";
import { Flex, Link, LinkProps, Text } from "theme-ui";
import { Stack } from "../Stack/Stack";

export type MammonHelpLinkProps = LinkProps & {
  heading?: ReactNode;
  description?: ReactNode;
};

const DEFAULT_SX = {};

export function MammonHelpLink({
  heading,
  description,
  sx = DEFAULT_SX,
  ...restProps
}: MammonHelpLinkProps) {
  return (
    <Stack gap={4 * 1}>
      <Flex>
        <Text variant="body.sectionTitle" color="grey1">
          {heading}
        </Text>
      </Flex>
      <Stack gap={4 * 2}>
        <Flex>
          <Link
            variant="text.body.subSectionTitle"
            color="green"
            sx={(theme: any) => ({
              ...theme.buttons.actionText.green,
              ...theme.text.body.subSectionTitle,
              textDecoration: "none",
            })}
            {...restProps}
          />
        </Flex>
        <Flex>
          <Text variant="body.paragraph" color="grey2">
            {description}
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
}
