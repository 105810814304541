import { Box, BoxProps } from "theme-ui";
import React from "react";

type StackProps = {
  gap?: string | number;
};

const DEFAULT_SX = {};

export function Stack({
  gap = 4,
  sx = DEFAULT_SX,
  ...props
}: StackProps & BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        display: "grid",
        gridAutoFlow: "row",
        gap,
        ...sx,
      }}
    />
  );
}
