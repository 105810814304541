/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconCheck({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4288 6.18413C29.6022 7.35074 29.6387 9.28001 28.5105 10.4933L14.3405 25.7314C13.7654 26.3498 12.9648 26.689 12.1354 26.6655C11.306 26.6421 10.5246 26.2582 9.98299 25.6083L2.04778 16.0844C0.985309 14.8093 1.12373 12.8849 2.35696 11.7863C3.59018 10.6877 5.4512 10.8309 6.51367 12.106L12.3355 19.0934L24.2614 6.26862C25.3896 5.05535 27.2554 5.01752 28.4288 6.18413Z"
        sx={{ fill: color }}
      />
    </IconSvg>
  );
}
