/** @jsx jsx */
import { ReactNode, SVGProps } from "react";
import { jsx, SxStyleProp } from "theme-ui";

export enum IconSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
}

export type IconProps = {
  size: IconSize;
  color?: string;
};

export function IconSvg({
  size,
  children,
  sx = {},
  ...restProps
}: SVGProps<SVGSVGElement> & {
  size: IconSize;
  children: ReactNode;
  sx?: SxStyleProp;
}) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: `icon.${size}`, height: `icon.${size}`, ...sx }}
      {...restProps}
    >
      {children}
    </svg>
  );
}
