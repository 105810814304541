/** @jsx jsx */
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { jsx, useThemeUI } from "theme-ui";
import { useEscape } from "./useEscape";

const container = {
  hidden: {
    opacity: 0,
    transition: { delay: 0.25 },
  },
  visible: {
    opacity: 1,
    transition: { when: "beforeChildren" },
  },
};

const content = {
  hidden: { opacity: 0, y: "2%" },
  visible: { opacity: 1, y: 0 },
};

export type MammonGenericDialogProps = HTMLMotionProps<"div"> & {
  onClose?: () => void;
};

export type MammonGenericDialogContentProps = HTMLMotionProps<"div"> & {
  autoFocus?: boolean;
};

const DEFAULT_ON_CLOSE = () => {};
const DEFAULT_SX = {};

export function MammonGenericDialog({
  children,
  onClose = DEFAULT_ON_CLOSE,
  sx = DEFAULT_SX,
  ...restProps
}: MammonGenericDialogProps) {
  useEscape(onClose);
  return (
    <AnimatePresence>
      {children && (
        <RemoveScroll forwardProps={true} removeScrollBar={false}>
          <motion.div
            key="container"
            transition={{ duration: 0.375 }}
            variants={container}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={{ WebkitOverflowScrolling: "touch" }}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              overflow: "scroll",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: "overlay",
              ...sx,
            }}
            onClick={() => {
              onClose();
            }}
            {...restProps}
          >
            {children}
          </motion.div>
        </RemoveScroll>
      )}
    </AnimatePresence>
  );
}

export function DefaultMammonGenericDialogContent({
  children,
  autoFocus = true,
  sx = DEFAULT_SX,
  ...restProps
}: MammonGenericDialogContentProps) {
  const { theme } = useThemeUI();
  return (
    <FocusLock
      as={motion.div}
      autoFocus={autoFocus}
      returnFocus={true}
      lockProps={{
        key: "content",
        transition: theme.movement.spring,
        variants: content,
        onClick: (e: any) => {
          e.stopPropagation();
        },
        ...restProps,
      }}
      sx={{ display: "flex", flexDirection: "column", ...sx }}
    >
      {children}
    </FocusLock>
  );
}
