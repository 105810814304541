import { InlineLink } from "@earnnest/earnnest-ui-web-library/src/InlineLink/InlineLink";
import React from "react";
import { Helmet } from "react-helmet";
import { EarnnestServerError } from "../errors";
import { MarkScreenAsDonePrepping } from "../MarkScreenAsDonePrepping";
import { TakeoverScreen } from "./TakeoverScreen";
import { ErrorScreenProps } from "./ErrorScreen";

export type ErrorServerScreenProps = ErrorScreenProps & {
  error: EarnnestServerError;
};

const DEFAULT_ON_CONTACT_SUPPORT = () => {};

export function ErrorServerScreen({
  error,
  onContactSupport = DEFAULT_ON_CONTACT_SUPPORT,
}: ErrorServerScreenProps) {
  // In general, we want to hide unauthorized errors as not founds, so as to
  // thwart discoverability of assets in our system.
  //
  // That said, we do have messages for this in case we want to support it
  // in the future
  const { response } = error;
  const { status: targetStatusCode } = response;
  const statusCode =
    targetStatusCode === 401 || targetStatusCode === 403
      ? 404
      : targetStatusCode;
  return (
    <>
      <Helmet>
        <title>
          {statusCode === 404
            ? "Not Found"
            : statusCode === 400
            ? "Bad Request"
            : "Error"}
        </title>
      </Helmet>
      <MarkScreenAsDonePrepping />
      <TakeoverScreen
        primaryMessage={<>Well, that’s awkward.</>}
        secondaryDescriptor={statusCode ? <>Error {statusCode}</> : undefined}
        details={
          <>
            {statusCode === 404 ? (
              <>We tried to find that page but came up empty-handed.</>
            ) : statusCode === 400 ? (
              <>The request we received was malformed or incorrect.</>
            ) : statusCode === 403 ? (
              <>You are not authorized to access this page.</>
            ) : statusCode === 401 ? (
              <>You are not authorized to access this page.</>
            ) : Boolean(statusCode) ? (
              <>
                The server encountered an internal error and could not complete
                your request.
              </>
            ) : (
              <>We encountered an error and could not complete your request.</>
            )}
            <>
              {" "}
              If this issue persists, please{" "}
              <InlineLink onClick={onContactSupport}>
                contact support
              </InlineLink>
              .
            </>
          </>
        }
      />
    </>
  );
}
