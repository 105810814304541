import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export function useSearchParams() {
  const location = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  return searchParams;
}
