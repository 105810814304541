import {
  FetchEscrowAccountFunc,
  PaymentRequestSourceParams,
} from "@earnnest/earnnest-ui-web-library/src/api";
import {
  AgentRole,
  ProcessingStates,
  UserRoles,
} from "@earnnest/earnnest-ui-web-library/src/common";
import {
  EarnnestClientError,
  EarnnestClientErrorType,
} from "@earnnest/earnnest-ui-web-library/src/errors";
import {
  MammonCardLayoutContainer,
  MammonCardLayoutHeader,
  MammonCardLayoutSplitHeader,
} from "@earnnest/earnnest-ui-web-library/src/MammonCardLayout/MammonCardLayout";
import { MammonOverlay } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import {
  MammonTextNav,
  MammonTextNavDirection,
} from "@earnnest/earnnest-ui-web-library/src/MammonTextNav/MammonTextNav";
import { MarkScreenAsDonePrepping } from "@earnnest/earnnest-ui-web-library/src/MarkScreenAsDonePrepping";
import { runPromiseForMinimumTime } from "@earnnest/earnnest-ui-web-library/src/promiseHelpers";
import { User } from "@earnnest/earnnest-ui-web-library/src/User";
import { differenceInMinutes } from "date-fns";
import { motion } from "framer-motion";
import React, { ComponentType, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Flex } from "theme-ui";
import { useEffectReducer } from "use-effect-reducer";
import {
  FetchEscrowAccount,
  useFetchedEscrowAccount,
} from "./FetchEscrowAccount";
import { RequestPortalForm, RequestPortalFormProps } from "./RequestPortalForm";
import { RoleSelectionOverlayContent } from "./RoleSelectionOverlayContent";
import { WelcomeOverlayContent } from "./WelcomeOverlayContent";

export enum OverlayStatus {
  Open,
  Processing,
  Closed,
}

// For legacy authentication (ZipForms), users should be determined as "prepared"
// based on the time they were created, since they will already have the
// necessary roles.
//
// User preparation determines whether or not to show the welcome overlay
export enum UserPreparationMode {
  InsertionTimeBased,
  RoleBased,
}

type RequestPortalScreenProps = Omit<RequestPortalFormProps, "selectedRole"> & {
  prefilledEscrowAccountId?: string;
  additionalEmailsToBeNotified: string[];
  prefilledRole?: string;
  isPrefillingEscrowAccountDisabled: boolean;
  fetchEscrowAccount: FetchEscrowAccountFunc;
  userPreparationMode: UserPreparationMode;
  isFormGeneratedByARequestorLink: boolean;
  isListingAgentFlowDisabled: boolean;
  user: User;
  paymentRequestSource: PaymentRequestSourceParams;
  prepareUserForPaymentRequest: () => Promise<void>;
  onUserPreparationError: (error: Error) => void;
  onRoleSelected: (role: AgentRole) => void;
  onShowHelpOverlay: () => void;
};

const RequestPortalFormWithPrefilledEscrowAccount = withPrefilledEscrowAccountValues(
  RequestPortalForm,
);

export function RequestPortalScreen({
  userPreparationMode,
  isListingAgentFlowDisabled,
  prefilledRole,
  prefilledFormValues,
  prefilledEscrowAccountId,
  additionalEmailsToBeNotified,
  isPrefillingEscrowAccountDisabled,
  isFormGeneratedByARequestorLink,
  user,
  fetchEscrowAccount,
  searchEscrowAccounts,
  createInboundPaymentRequest,
  paymentRequestSource,
  prepareUserForPaymentRequest,
  escrowHolderInvitationUrl,
  onUserPreparationError,
  onFormSubmitted,
  onFormSubmissionError,
  onRoleSelected,
  onShowHelpOverlay,
}: RequestPortalScreenProps) {
  const isRemitterInfoProvidedInQuery = Boolean(
    prefilledFormValues.remitterEmail,
  );

  const [isBackButtonDisabled] = useState(() => {
    return (
      isFormGeneratedByARequestorLink ||
      isRemitterInfoProvidedInQuery ||
      isListingAgentFlowDisabled
    );
  });

  const [initialSelectedRole] = useState(() => {
    if (
      isFormGeneratedByARequestorLink ||
      isRemitterInfoProvidedInQuery ||
      isListingAgentFlowDisabled
    ) {
      return AgentRole.Requestor;
    }
    if (
      prefilledRole &&
      [AgentRole.Requestor, AgentRole.Creator].includes(
        prefilledRole as AgentRole,
      )
    ) {
      return prefilledRole as AgentRole;
    }
    return null;
  });

  const isUserPreparedForPaymentRequest = useMemo(() => {
    const minutesSinceUserWasCreated = differenceInMinutes(
      new Date(),
      new Date(user.insertedAt),
    );
    const isUserPrepared = Boolean(
      userPreparationMode === UserPreparationMode.InsertionTimeBased
        ? minutesSinceUserWasCreated > 15
        : user.roles.includes(UserRoles.RequestEscrow),
    );
    return isUserPrepared;
  }, [user.insertedAt, user.roles, userPreparationMode]);

  const [state, dispatch] = useEffectReducer(
    (
      state: {
        showEscrowSearchOverlay: boolean;
        showRoleSelectionOverlay: boolean;
        welcomeOverlayStatus: OverlayStatus;
        role: AgentRole | null;
      },
      action:
        | { type: "role_selected"; payload: AgentRole }
        | { type: "continue_from_welcome_overlay" }
        | { type: "close_welcome_overlay" }
        | { type: "welcome_overlay_processed" }
        | { type: "welcome_overlay_errored"; payload: Error }
        | { type: "on_back_clicked" }
        | { type: "closed_real_quick" },
      exec,
    ) => {
      switch (action.type) {
        case "continue_from_welcome_overlay":
          return {
            ...state,
            welcomeOverlayStatus: OverlayStatus.Processing,
          };
        case "close_welcome_overlay":
        case "welcome_overlay_processed":
          return {
            ...state,
            welcomeOverlayStatus: OverlayStatus.Closed,
          };
        case "welcome_overlay_errored":
          const welcomeOverlayError = action.payload;
          exec(() => {
            onUserPreparationError(welcomeOverlayError);
          });
          return { ...state, welcomeOverlayStatus: OverlayStatus.Open };
        case "on_back_clicked":
          return { ...state, showRoleSelectionOverlay: true };
        case "closed_real_quick":
          return { ...state, showRoleSelectionOverlay: false };
        case "role_selected":
          exec(() => {
            onRoleSelected(action.payload);
          });
          return {
            ...state,
            role: action.payload,
            showRoleSelectionOverlay: false,
          };
        default:
          throw new Error(
            "[RequestPortalScreen] Unrecognized action in screen level dispatcher",
          );
      }
    },
    {
      welcomeOverlayStatus: isUserPreparedForPaymentRequest
        ? OverlayStatus.Closed
        : OverlayStatus.Open,
      showEscrowSearchOverlay: false,
      showRoleSelectionOverlay: !initialSelectedRole,
      role: initialSelectedRole,
    },
  );

  // Unfortunately at this time, we have to accommodate for users
  // coming from Zipform that see the welcome overlay and acknowledge it
  const isWelcomeScreenAcknowledged =
    state.welcomeOverlayStatus === OverlayStatus.Closed;
  const isReadyToLoadContent =
    userPreparationMode === UserPreparationMode.InsertionTimeBased
      ? isUserPreparedForPaymentRequest || isWelcomeScreenAcknowledged
      : isUserPreparedForPaymentRequest;

  const handleCloseWelcomeOverlay = useCallback(() => {
    dispatch({ type: "close_welcome_overlay" });
  }, [dispatch]);

  const handleCloseRoleSelection = useCallback(() => {
    dispatch({ type: "closed_real_quick" });
  }, [dispatch]);

  const handleContinueFromWelcomeOverlay = useCallback(async () => {
    try {
      dispatch({ type: "continue_from_welcome_overlay" });
      await runPromiseForMinimumTime(prepareUserForPaymentRequest());
      dispatch({ type: "welcome_overlay_processed" });
    } catch (error) {
      console.log(
        "[RequestPortal] Error occurred preparing user for payment request.",
      );
      console.error(error);
      dispatch({ type: "welcome_overlay_errored", payload: error as Error });
    }
  }, [dispatch, prepareUserForPaymentRequest]);

  const handleClickOnBack = useCallback(() => {
    dispatch({ type: "on_back_clicked" });
  }, [dispatch]);

  const handleContinueFromRoleSelection = useCallback(
    (role: AgentRole) => {
      dispatch({ type: "role_selected", payload: role });
    },
    [dispatch],
  );

  // A source and correlation_id is required in the query params for now.
  // If they aren't provided, render an error to the user.
  const { source, sourceCorrelationId } = paymentRequestSource;
  if (!source || !sourceCorrelationId) {
    let missingParts: string[] = [];
    if (!source) {
      missingParts = [...missingParts, "source"];
    }
    if (!sourceCorrelationId) {
      missingParts = [...missingParts, "correlation_id"];
    }
    throw new EarnnestClientError(
      `Request portal is missing ${missingParts.join(" and ")}`,
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {state.role === AgentRole.Creator
            ? "Setup Payment"
            : "Request Payment"}
        </title>
      </Helmet>
      <MarkScreenAsDonePrepping />
      <MammonOverlay onClose={handleCloseRoleSelection}>
        {state.showRoleSelectionOverlay && (
          <RoleSelectionOverlayContent
            initialRole={state.role}
            onContinue={handleContinueFromRoleSelection}
          />
        )}
      </MammonOverlay>
      <MammonOverlay onClose={handleCloseWelcomeOverlay}>
        {state.welcomeOverlayStatus !== OverlayStatus.Closed && (
          <WelcomeOverlayContent
            status={state.welcomeOverlayStatus}
            onContinue={handleContinueFromWelcomeOverlay}
          />
        )}
      </MammonOverlay>
      {isReadyToLoadContent && (
        /* This handles the transition out of the Request Route */
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 1, duration: 0.5 } }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <MammonCardLayoutContainer>
            <ScreenHeader
              onBack={isBackButtonDisabled ? undefined : handleClickOnBack}
              onShowHelpOverlay={onShowHelpOverlay}
            />
            <FetchEscrowAccount
              escrowAccountId={
                isPrefillingEscrowAccountDisabled
                  ? undefined
                  : prefilledEscrowAccountId
              }
              fetchEscrowAccount={fetchEscrowAccount}
            >
              <RequestPortalFormWithPrefilledEscrowAccount
                prefilledFormValues={prefilledFormValues}
                additionalEmailsToBeNotified={additionalEmailsToBeNotified}
                isEscrowAccountProvidedByCreator={
                  isFormGeneratedByARequestorLink
                }
                escrowHolderInvitationUrl={escrowHolderInvitationUrl}
                selectedRole={state.role}
                searchEscrowAccounts={searchEscrowAccounts}
                createInboundPaymentRequest={createInboundPaymentRequest}
                user={user}
                isFormGeneratedByARequestorLink={
                  isFormGeneratedByARequestorLink
                }
                onFormSubmitted={onFormSubmitted}
                onFormSubmissionError={onFormSubmissionError}
              />
            </FetchEscrowAccount>
          </MammonCardLayoutContainer>
        </motion.div>
      )}
    </>
  );
}

function ScreenHeader({
  onShowHelpOverlay,
  onBack,
}: {
  onShowHelpOverlay: () => void;
  onBack?: () => void;
}) {
  return (
    <MammonCardLayoutHeader sticky={false}>
      {useMemo(
        () => (
          <MammonCardLayoutSplitHeader>
            <Flex>
              {onBack ? (
                <MammonTextNav
                  direction={MammonTextNavDirection.Regression}
                  onClick={onBack}
                >
                  Back
                </MammonTextNav>
              ) : (
                <>&nbsp;</>
              )}
            </Flex>
            <Flex>
              <MammonTextNav onClick={onShowHelpOverlay}>
                Need help?
              </MammonTextNav>
            </Flex>
          </MammonCardLayoutSplitHeader>
        ),
        [onBack, onShowHelpOverlay],
      )}
    </MammonCardLayoutHeader>
  );
}

function withPrefilledEscrowAccountValues<
  P extends RequestPortalFormProps & {
    isEscrowAccountProvidedByCreator: boolean;
  }
>(Component: ComponentType<P>) {
  return (props: P) => {
    const { prefilledFormValues, isEscrowAccountProvidedByCreator } = props;
    const { processingState, escrowAccount, error } = useFetchedEscrowAccount();

    const prefilledEscrowAccountValues = useMemo(() => {
      if (!escrowAccount) {
        return {};
      }
      return {
        selectedEscrowAccount: escrowAccount,
        escrowAccountMatchesContract: isEscrowAccountProvidedByCreator,
      };
    }, [escrowAccount, isEscrowAccountProvidedByCreator]);

    const prefilledFormValuesWithEscrowAccount = useMemo(() => {
      return { ...prefilledFormValues, ...prefilledEscrowAccountValues };
    }, [prefilledEscrowAccountValues, prefilledFormValues]);

    // Because an error fetching an escrow account puts the request in an
    // invalid state,
    if (error) {
      throw new EarnnestClientError(
        error.message,
        EarnnestClientErrorType.MalformedRequest,
      );
    }

    // Because this component is essentially making the escrow account
    // required, block rendering until we have processed it
    if (processingState !== ProcessingStates.Processed) {
      return null;
    }

    return (
      <Component
        {...props}
        prefilledFormValues={prefilledFormValuesWithEscrowAccount}
      />
    );
  };
}
