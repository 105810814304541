import { STATE_ABBREVIATIONS, STATE_NAMES } from "./constants";
import { Address } from "./common";

export function formatAddress({
  addressLine1: line1,
  addressLine2: line2,
  city,
  stateOrRegion,
  postalCode,
}: Address): string {
  const commaSeparatedAddressPart = [line1, line2, city]
    .filter(n => Boolean(n))
    .join(", ");
  const spaceSeparatedAddressPart = [
    commaSeparatedAddressPart,
    stateOrRegion,
    postalCode,
  ]
    .filter(n => Boolean(n))
    .join(" ");
  return spaceSeparatedAddressPart;
}

export function convertUsdStringToNumber(amount: string) {
  if (!amount) {
    return amount;
  }
  let amountStr = String(amount);
  amountStr = amountStr.replace(/[$,]/g, "");
  return parseInt(amountStr, 10);
}

export function formatNumberToIncludeCommas(number: string | number) {
  const withCommas = number
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  return withCommas;
}

export function formatNumberAsUsd(number: string | number) {
  const withCommas = formatNumberToIncludeCommas(number);
  const withoutDollarSign = withCommas.replace(/\$/g, "");
  const withDollarSign = "$" + withoutDollarSign;
  return withDollarSign;
}

// (555) 123-1234 -> 555-123-1234
// 555 123-1234 -> 555-123-1234
// 15551231234 -> 555-123-1234
// +15551231234 -> 555-123-1234
// TODO: Handle internation phone numbers?
export function formatUsPhone(taintedPhone: string) {
  // Remove all non-digits from the phone
  let phone = taintedPhone.replace(/\D/g, "");

  // If the numbers are less than 10, not sure we can do anything
  if (phone.length < 10) {
    return taintedPhone;
  }

  // Get the last 10 letters
  phone = phone.slice(-10);

  // Divide and conquer
  phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  return phone;
}

export function isValidUsPhone(taintedPhone: string) {
  if (/[^ .\d)+(-]/.test(taintedPhone)) {
    return false;
  }

  let phone = taintedPhone.replace(/\D/g, "");

  if (phone.length < 10 || phone.length > 11) {
    return false;
  }

  // US phones can't start with '0'
  if (phone[0] === "0") {
    return false;
  }

  const validWithCountryCode = phone[0] === "1" && phone.length === 11;
  const validWithoutCountryCode = phone[0] !== "1" && phone.length === 10;
  const firstDigit = phone.length === 11 ? phone[1] : phone[0];
  const validFirstDigit = firstDigit !== "0" && firstDigit !== "1";
  return (validWithCountryCode || validWithoutCountryCode) && validFirstDigit;
}

export function isValidEmail(email: string) {
  const dotIndex = email.lastIndexOf(".");
  const atIndex = email.lastIndexOf("@");
  return atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex;
}

export function isValidZip(zip: string) {
  return /^\d\d\d\d\d$/.test(zip) || /^\d\d\d\d\d-\d\d\d\d$/.test(zip);
}

export function findStateAbbreviationFromStateCodeOrStateName(
  potentialState: string,
) {
  const moddedString = potentialState.toLowerCase();
  const matchingState = STATE_NAMES.find(
    state =>
      moddedString === state.abbreviation.toLowerCase() ||
      moddedString === state.name.toLowerCase(),
  );
  return matchingState ? matchingState.abbreviation : "";
}

export function isValidStateAbbreviation(state: string) {
  return STATE_ABBREVIATIONS.find((abbr: string) => {
    return abbr.toLowerCase() === state.toLowerCase();
  });
}
