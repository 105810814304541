import { useEffect } from "react";

declare global {
  interface Window {
    earnnestStartedPreppingAt: Date;
    earnnestPreppingTimeout: NodeJS.Timeout;
  }
}

// In order to not have a jumpy experience, ensure the loading screen
// shows for at least some time before fading away
const DEFAULT_INITIAL_MINIMUM_TIME_THRESHOLD = 2500;

export function cancelPrepping() {
  clearTimeout(window.earnnestPreppingTimeout);
}

export function removePrepping() {
  const $prepping = document.getElementById("prepping");
  ($prepping as HTMLElement).remove();
  clearTimeout(window.earnnestPreppingTimeout);
}

export function markDonePrepping() {
  const $prepping = document.getElementById("prepping");
  const $preppingInnerBox = document.getElementById("prepping-inner-box");
  if (!$prepping || !$preppingInnerBox) {
    return;
  }
  $preppingInnerBox?.addEventListener("transitionend", e => {
    e.stopPropagation();
    ($prepping as HTMLElement).classList.add("exit");
  });
  $prepping?.addEventListener("transitionend", e => {
    $prepping.remove();
  });
  ($preppingInnerBox as HTMLElement).classList.remove("enter");
}

export function MarkScreenAsDonePrepping({
  initialMinimumTime = DEFAULT_INITIAL_MINIMUM_TIME_THRESHOLD,
}) {
  useEffect(() => {
    if (!window.earnnestStartedPreppingAt) {
      removePrepping();
    } else {
      const msSincePreppingStarted =
        new Date().getTime() - window.earnnestStartedPreppingAt.getTime();
      console.log(
        "[DonePrepping] Marking prepping done in [%s] ms.",
        Math.max(0, initialMinimumTime - msSincePreppingStarted),
      );
      setTimeout(() => {
        markDonePrepping();
      }, Math.max(0, initialMinimumTime - msSincePreppingStarted));
    }
  }, [initialMinimumTime]);
  return null;
}
