import React, { ReactElement } from "react";
import { Global, css } from "@emotion/core";
import { useThemeUI } from "theme-ui";

const GlobalStyles = (): ReactElement => {
  const { theme } = useThemeUI();
  return (
    <Global
      styles={css`
        html {
          font-family: Ubuntu, sans-serif;
          line-height: 160%;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          // Allows 100vh on iOS Safari to not overflow browser chrome
          height: -webkit-fill-available;
          color: ${theme.colors?.grey1};
        }

        body {
          min-height: 100vh;
          min-height: -webkit-fill-available;
        }
      `}
    />
  );
};

export { GlobalStyles };
