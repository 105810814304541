// This function will return a promise that runs the passed in promise, but
// for at least the time specified. This is helpful for UI interactions
// that should look like they're processing something.
//
// By design, if an error occurs, it'll still wait for the time specified
// before throwing.
export async function runPromiseForMinimumTime<T = any>(
  promise: Promise<T>,
  minimumTimeInMs = 750,
) {
  let error;
  const [, result] = await Promise.all([
    new Promise(r => setTimeout(r, minimumTimeInMs)),
    promise.catch(e => {
      error = e;
    }),
  ]);
  if (error) {
    throw error;
  }
  return result as T;
}
