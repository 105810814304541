import { ActAsButton } from "@earnnest/earnnest-ui-web-library/src/ActAsButton/ActAsButton";
import { EscrowSearchParams } from "@earnnest/earnnest-ui-web-library/src/api";
import { ProcessingStates } from "@earnnest/earnnest-ui-web-library/src/common";
import { IconSize } from "@earnnest/earnnest-ui-web-library/src/Icon";
import { IconSearch } from "@earnnest/earnnest-ui-web-library/src/IconSearch";
import { MammonDescriptiveActionButton } from "@earnnest/earnnest-ui-web-library/src/MammonDescriptiveActionButton/MammonDescriptiveActionButton";
import { MammonField } from "@earnnest/earnnest-ui-web-library/src/MammonForm/MammonField";
import {
  MammonInput,
  MammonInputBoxType,
} from "@earnnest/earnnest-ui-web-library/src/MammonForm/MammonInput";
import { MammonProfileListSlat } from "@earnnest/earnnest-ui-web-library/src/MammonProfileListSlat/MammonProfileListSlat";
import { Stack } from "@earnnest/earnnest-ui-web-library/src/Stack/Stack";
import { useUniqueId } from "@earnnest/earnnest-ui-web-library/src/useUniqueId";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import React, { useCallback, useMemo, useReducer } from "react";
import { Flex, Text, Theme } from "theme-ui";
import { DefaultPortalOverlayContent } from "../DefaultPortalOverlayContent";

const DEFAULT_MINIMUM_INPUT_LENGTH_TO_TRIGGER_SEARCH = 2;

export type EscrowSearchOverlayContentProps = {
  onClose: () => void;
  onSelect: (ea: any) => void;
  propertyState: string;
  inviteHref: string;
  searchEscrowAccounts: (p: EscrowSearchParams) => Promise<any>;
  minimumInputLengthToTriggerSearch?: number;
};

type State = {
  searchState: ProcessingStates;
  searchInput: string;
  searchingInput: string;
  searchError: Error | null;
  focus: string | null;
  escrowAccounts: any[];
};

type Action =
  | { type: "change_search_input"; payload: string }
  | { type: "change_focus"; payload: string | null }
  | { type: "failed_searching_escrow_accounts"; payload: Error }
  | { type: "success_searching_escrow_accounts"; payload: any[] };

export function EscrowSearchOverlayContent({
  onClose,
  onSelect,
  propertyState,
  inviteHref,
  searchEscrowAccounts,
  minimumInputLengthToTriggerSearch = DEFAULT_MINIMUM_INPUT_LENGTH_TO_TRIGGER_SEARCH,
}: EscrowSearchOverlayContentProps) {
  const inputId = useUniqueId();
  const [state, dispatch] = useReducer(
    (prevState: State, action: Action) => {
      if (action.type === "change_search_input") {
        const searchInput = action.payload;
        const isSearching =
          searchInput.length >= minimumInputLengthToTriggerSearch;
        return {
          ...prevState,
          searchInput,
          searchState: isSearching
            ? ProcessingStates.Processing
            : ProcessingStates.Idle,
          searchingInput: isSearching ? searchInput : prevState.searchingInput,
          escrowAccounts: [],
        };
      }
      if (action.type === "success_searching_escrow_accounts") {
        return {
          ...prevState,
          escrowAccounts: action.payload,
          searchState: ProcessingStates.Idle,
        };
      }
      if (action.type === "failed_searching_escrow_accounts") {
        return {
          ...prevState,
          searchState: ProcessingStates.Error,
          searchError: action.payload,
        };
      }
      if (action.type === "change_focus") {
        return {
          ...prevState,
          focus: action.payload,
        };
      }
      return prevState;
    },
    {
      searchState: ProcessingStates.Idle,
      searchInput: "",
      searchingInput: "",
      searchError: null,
      focus: null,
      escrowAccounts: [],
    },
  );

  const searchEscrowAccountsDebounced = useMemo(() => {
    return AwesomeDebouncePromise(searchEscrowAccounts, 500);
  }, [searchEscrowAccounts]);

  const handleSearchInputChange = useCallback(
    async e => {
      const target = e.target;
      dispatch({ type: "change_search_input", payload: target.value });
      if (target.value.length < minimumInputLengthToTriggerSearch) {
        return;
      }
      try {
        const { escrowAccounts } = await searchEscrowAccountsDebounced({
          state: propertyState,
          query: target.value,
        });
        const escrowAccountDataForSearch = escrowAccounts.map(
          (escrowAccount: any) => {
            return {
              id: escrowAccount.id,
              brandName: escrowAccount.organization.name,
              bankFriendlyName: escrowAccount.name,
              organizationEmail: escrowAccount.organization.email,
              operatingRegion: escrowAccount.operatingRegion,
              maxAmount: escrowAccount.maxAmount,
            };
          },
        );
        if (
          target &&
          target.value.length >= minimumInputLengthToTriggerSearch
        ) {
          dispatch({
            type: "success_searching_escrow_accounts",
            payload: escrowAccountDataForSearch,
          });
        }
      } catch (error) {
        if (
          target &&
          target.value.length >= minimumInputLengthToTriggerSearch
        ) {
          console.log(
            "[EscrowSearchOverlayContent] Error occurred searching escrow holders",
          );
          console.error(error);
          dispatch({
            type: "failed_searching_escrow_accounts",
            payload: error,
          });
        }
      }
    },
    [
      minimumInputLengthToTriggerSearch,
      propertyState,
      searchEscrowAccountsDebounced,
    ],
  );

  const handleFocus = useCallback(() => {
    dispatch({ type: "change_focus", payload: "escrow-search" });
  }, []);

  const handleBlur = useCallback(() => {
    dispatch({ type: "change_focus", payload: null });
  }, []);

  return (
    <DefaultPortalOverlayContent onClose={onClose}>
      <Stack gap={8}>
        <Flex>
          <Text variant="body.subSectionTitle">The escrow holder</Text>
        </Flex>
        <Flex sx={{ flexDirection: "column" }}>
          <MammonField
            inputBoxType={MammonInputBoxType.Outline}
            hasValue={state.searchInput.length > 0}
            hasFocus={state.focus === "escrow-search"}
            isErrored={false}
            icon={<IconSearch size={IconSize.SM} />}
            inputId={inputId}
          >
            <MammonInput
              id={inputId}
              type="text"
              autoComplete="escrow-holder-search"
              boxType={MammonInputBoxType.Outline}
              placeholder="Search for escrow holder"
              hidePlaceholder={false}
              hasFocus={state.focus === "escrow-search"}
              value={state.searchInput}
              onChange={handleSearchInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </MammonField>
        </Flex>
      </Stack>
      <Flex>
        {state.searchState === ProcessingStates.Error ? (
          <Flex>
            <Text variant="body.paragraph">
              We encountered an error while loading escrow holders. Please close
              the search and try again.
            </Text>
          </Flex>
        ) : state.searchState === ProcessingStates.Processing ? (
          <Flex>
            <Text variant="body.paragraph" color="grey3">
              Searching our escrow network...
            </Text>
          </Flex>
        ) : state.escrowAccounts.length > 0 ? (
          <Flex sx={{ flexDirection: "column" }}>
            <Flex
              as="ul"
              style={{
                flexDirection: "column",
                margin: 0,
                padding: 0,
                listStyleType: "none",
              }}
            >
              {state.escrowAccounts.map((escrowAccount: any, index: number) => (
                <Flex
                  as="li"
                  key={escrowAccount.id}
                  sx={{ flexDirection: "column" }}
                >
                  {index !== 0 && (
                    <Flex bg="white" mt={3} style={{ height: 1 }} />
                  )}
                  <ActAsButton
                    onClick={() => {
                      onSelect(escrowAccount);
                    }}
                    sx={{
                      appearance: "none",
                      py: 0,
                      px: 12,
                      my: 0,
                      mx: -12,
                      background: "none",
                      border: 0,
                      borderRadius: "standard",
                      display: "flex",
                      flexDirection: "column",
                      outline: "none",
                      cursor: "pointer",
                      transition: "box-shadow .15s ease-out",
                      "&:focus": {
                        boxShadow: (theme: Theme) =>
                          `0 0 0 0.15em ${theme.colors?.purple2Shadow}`,
                      },
                    }}
                  >
                    <MammonProfileListSlat
                      heading={escrowAccount.bankFriendlyName}
                      paragraph={escrowAccount.brandName}
                    />
                  </ActAsButton>
                </Flex>
              ))}
            </Flex>
            <Flex bg="white" mt={3} sx={{ height: 1 }} />
            <Flex mt={24}>
              <MammonDescriptiveActionButton
                heading={<>Invite an escrow holder</>}
                description={
                  <>
                    Don’t see your prefered escrow holder? Inviting them to join
                    Earnnest is simple and takes about five minutes.
                  </>
                }
                as="a"
                href={inviteHref}
                target="_blank"
                children={<>Invite them</>}
              />
            </Flex>
          </Flex>
        ) : state.searchInput.length < minimumInputLengthToTriggerSearch ? (
          <Flex>
            <Text variant="body.paragraph" color="grey3">
              Type in an escrow holder's name to show matching results.
            </Text>
          </Flex>
        ) : (
          <Flex>
            <MammonDescriptiveActionButton
              heading={<>No matches found</>}
              description={
                <>
                  Inviting your preferred escrow holder to join Earnnest is
                  simple and takes about five minutes.
                </>
              }
              as="a"
              href={inviteHref}
              target="_blank"
              children={<>Invite them</>}
            />
          </Flex>
        )}
      </Flex>
    </DefaultPortalOverlayContent>
  );
}
