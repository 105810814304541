export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// Extract all elements from a tuple, except the first one
export type Tail<T extends any[]> = ((...x: T) => void) extends (
  h: infer A,
  ...t: infer R
) => void
  ? R
  : never;

// Unwraps a promise type
export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type RequiredKeys<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

export enum Key {
  Enter = 13,
  Space = 32,
  Down = 40,
  Escape = 27,
}

export enum ProcessingStates {
  Idle,
  Dirty,
  Processing,
  Processed,
  Error,
}

export enum AuthModes {
  Legacy,
  Modern,
}

export enum Auth0Env {
  Sandbox = "sandbox",
  Dev = "dev",
  Preview = "preview",
  Stag = "stag",
  Demo = "demo",
  Prod = "prod",
}

export enum NamedApiEnv {
  Stag = "stag",
  Release = "release",
  Demo = "demo",
  Prod = "prod",
}

export enum ApiPipeline {
  Preview,
  Stag,
  Prod,
}

// These are roles that are on our `user.roles` field
export enum UserRoles {
  Track = "track",
  Remit = "remit",
  Request = "request",
  RequestEscrow = "request_escrow",
}

// These are roles that are on our `organization_users.roles` field
export enum OrgRoles {
  Admin = "admin",
  RequestEscrow = "request_escrow",
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  stateOrRegion?: string;
  postalCode?: string;
}

export enum AgentRole {
  Creator = "creator",
  Requestor = "requestor",
}
