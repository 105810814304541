import template from "lodash/template";

/* eslint-disable no-template-curly-in-string */

//
// API / Auth Origins
//
// It's easier to manage these in a constants file than it is to manage
// them as part of the environment. Instead, the environment just specifies
// a more specific type of environment, from which the code builds everything
// else off of.
//
// The templates below use the lodash template function in their
// respective areas.
//

export const PROD_AUTH_DOMAIN = "signin.earnnest.com";
export const STAG_AUTH_DOMAIN = "stag.signin.earnnest.com";
export const SANDBOX_AUTH_DOMAIN = "earnnest-sandbox.us.auth0.com";
export const DEFAULT_AUTH_DOMAIN_TEMPLATE = "earnnest-${env}.auth0.com";
export const DEFAULT_AUTH_AUDIENCE_TEMPLATE =
  "https://api-gateway.earnnest.com/api${suffix}";

export const PROD_GATEWAY_ORIGIN = "https://api-gateway.earnnest.com";
export const DEV_GATEWAY_ORIGIN = "https://api-dev-gateway.earnnest.com"; // deprecated please use https://api-[classic-]{env}.earnnest.com
export const DEVELOPMENT_GATEWAY_ORIGIN = "https://api-classic-dev.earnnest.com";
export const SANDBOX_GATEWAY_ORIGIN =
  "https://api-classic-sandbox.earnnest.com";
export const DEMO_GATEWAY_ORIGIN = "https://api-classic-demo.earnnest.com";
export const UNITY_GATEWAY_ORIGIN = "https://api-j.earnnest.com";

export const DEFAULT_API_ORIGIN_TEMPLATE =
  "https://en-main-${env}.herokuapp.com";
export const PREVIEW_API_ORIGIN_TEMPLATE =
  "https://en-services-preview-pr-${env}.herokuapp.com";

export const createAuthDomainFromTemplate = template(
  DEFAULT_AUTH_DOMAIN_TEMPLATE,
) as (data: { env: string }) => string;
export const createAuthAudienceFromTemplate = template(
  DEFAULT_AUTH_AUDIENCE_TEMPLATE,
) as (data: { suffix: string }) => string;
export const createDefaultApiOriginFromTemplate = template(
  DEFAULT_API_ORIGIN_TEMPLATE,
) as (data: { env: string }) => string;
export const createPreviewApiOriginFromTemplate = template(
  PREVIEW_API_ORIGIN_TEMPLATE,
) as (data: { env: string }) => string;

/* eslint-enable no-template-curly-in-string */

export const STATE_NAMES = [
  { abbreviation: "AL", name: "Alabama" },
  { abbreviation: "AK", name: "Alaska" },
  { abbreviation: "AZ", name: "Arizona" },
  { abbreviation: "AR", name: "Arkansas" },
  { abbreviation: "CA", name: "California" },
  { abbreviation: "CO", name: "Colorado" },
  { abbreviation: "CT", name: "Connecticut" },
  { abbreviation: "DE", name: "Delaware" },
  { abbreviation: "DC", name: "District of Columbia" },
  { abbreviation: "FL", name: "Florida" },
  { abbreviation: "GA", name: "Georgia" },
  { abbreviation: "HI", name: "Hawaii" },
  { abbreviation: "ID", name: "Idaho" },
  { abbreviation: "IL", name: "Illinois" },
  { abbreviation: "IN", name: "Indiana" },
  { abbreviation: "IA", name: "Iowa" },
  { abbreviation: "KS", name: "Kansas" },
  { abbreviation: "KY", name: "Kentucky" },
  { abbreviation: "LA", name: "Louisiana" },
  { abbreviation: "ME", name: "Maine" },
  { abbreviation: "MD", name: "Maryland" },
  { abbreviation: "MA", name: "Massachusetts" },
  { abbreviation: "MI", name: "Michigan" },
  { abbreviation: "MN", name: "Minnesota" },
  { abbreviation: "MS", name: "Mississippi" },
  { abbreviation: "MO", name: "Missouri" },
  { abbreviation: "MT", name: "Montana" },
  { abbreviation: "NE", name: "Nebraska" },
  { abbreviation: "NV", name: "Nevada" },
  { abbreviation: "NH", name: "New Hampshire" },
  { abbreviation: "NJ", name: "New Jersey" },
  { abbreviation: "NM", name: "New Mexico" },
  { abbreviation: "NY", name: "New York" },
  { abbreviation: "NC", name: "North Carolina" },
  { abbreviation: "ND", name: "North Dakota" },
  { abbreviation: "OH", name: "Ohio" },
  { abbreviation: "OK", name: "Oklahoma" },
  { abbreviation: "OR", name: "Oregon" },
  { abbreviation: "PA", name: "Pennsylvania" },
  { abbreviation: "RI", name: "Rhode Island" },
  { abbreviation: "SC", name: "South Carolina" },
  { abbreviation: "SD", name: "South Dakota" },
  { abbreviation: "TN", name: "Tennessee" },
  { abbreviation: "TX", name: "Texas" },
  { abbreviation: "UT", name: "Utah" },
  { abbreviation: "VT", name: "Vermont" },
  { abbreviation: "VA", name: "Virginia" },
  { abbreviation: "WA", name: "Washington" },
  { abbreviation: "WV", name: "West Virginia" },
  { abbreviation: "WI", name: "Wisconsin" },
  { abbreviation: "WY", name: "Wyoming" },
  { abbreviation: "VI", name: "US Virgin Islands" },
  { abbreviation: "PR", name: "Puerto Rico" },
  { abbreviation: "GU", name: "Guam" },
];

export const STATE_ABBREVIATIONS = STATE_NAMES.map(state => state.abbreviation);
