import { AgentRole } from "@earnnest/earnnest-ui-web-library/src/common";
import {
  convertUsdStringToNumber,
  findStateAbbreviationFromStateCodeOrStateName,
  formatNumberAsUsd,
  isValidEmail,
  isValidStateAbbreviation,
  isValidUsPhone,
  isValidZip,
} from "@earnnest/earnnest-ui-web-library/src/sharedStringHelpers";
import {
  PaymentRequestFormValues,
  PrefilledPaymentRequestFormValues,
} from "./RequestPortalForm";

export function validatePaymentRequest(
  role: AgentRole,
  values: PaymentRequestFormValues,
) {
  let errors = {};
  // These need to be at the top because the position of the error key
  // determines what input to focus on error
  if (role === AgentRole.Creator) {
    errors = { ...errors, ...validateCreatorValues(values) };
  }
  if (role === AgentRole.Requestor) {
    errors = { ...errors, ...validateRequestorValues(values) };
  }
  if (!values.propertyAddress) {
    errors = { ...errors, propertyAddress: "Address is required" };
  }
  if (!values.propertyCity) {
    errors = { ...errors, propertyCity: "City is required" };
  }
  if (!isValidStateAbbreviation(values.propertyState)) {
    errors = { ...errors, propertyState: "Valid state is required" };
  }
  if (!isValidZip(values.propertyZip)) {
    errors = { ...errors, propertyZip: "Valid zip code is required" };
  }
  if (!values.selectedEscrowAccount) {
    errors = { ...errors, selectedEscrowAccount: "Escrow account is required" };
  }
  if (
    values.selectedEscrowAccount &&
    isValidStateAbbreviation(values.propertyState) &&
    !values.selectedEscrowAccount.operatingRegion.includes(values.propertyState)
  ) {
    errors = {
      ...errors,
      selectedEscrowAccount:
        "Escrow account doesn't operate in the provided state",
    };
  }
  if (!values.escrowAccountMatchesContract) {
    errors = {
      ...errors,
      escrowAccountMatchesContract: "Escrow account should match contract",
    };
  }
  return errors;
}

function validateRequestorValues(values: PaymentRequestFormValues) {
  let errors = {};
  if (!values.remitterFirstName) {
    errors = { ...errors, remitterFirstName: "First name is required" };
  }
  if (!values.remitterLastName) {
    errors = { ...errors, remitterLastName: "Last name is required" };
  }
  if (!values.remitterEmail) {
    errors = { ...errors, remitterEmail: "Email is required" };
  } else if (!isValidEmail(values.remitterEmail)) {
    errors = { ...errors, remitterEmail: "Email must be valid" };
  }
  if (!values.remitterPhone) {
    errors = { ...errors, remitterPhone: "Phone is required" };
  } else if (!isValidUsPhone(values.remitterPhone)) {
    errors = { ...errors, remitterPhone: "Phone must be valid" };
  }
  if (!values.amount) {
    errors = { ...errors, amount: "Amount is required" };
  } else {
    const amount = convertUsdStringToNumber(values.amount);
    // Dwolla imposes a minimum limit that's double the platform processing fee.
    // This shouldn't be lowered below 30 unless Dwolla changes their policy.
    if ((amount as number) < 48) {
      errors = { ...errors, amount: "Amount should be at least $48" };
    }
    if ((amount as number) > 250000) {
      errors = { ...errors, amount: "Amount should be at most $250,000" };
    }
    if (
      values.selectedEscrowAccount &&
      values.selectedEscrowAccount.maxAmount &&
      amount > values.selectedEscrowAccount.maxAmount
    ) {
      errors = {
        ...errors,
        amount: `Selected escrow account requires that the amount be at most ${formatNumberAsUsd(
          values.selectedEscrowAccount.maxAmount,
        )}`,
      };
    }
  }
  if (!values.fullyExecutedContract) {
    errors = {
      ...errors,
      fullyExecutedContract: "Confirm you have a fully executed contract",
    };
  }
  return errors;
}

function validateCreatorValues(values: PaymentRequestFormValues) {
  let errors = {};
  if (!values.requestorEmail) {
    errors = { ...errors, requestorEmail: "Email is required" };
  } else if (!isValidEmail(values.requestorEmail)) {
    errors = { ...errors, requestorEmail: "Email must be valid" };
  }
  return errors;
}

export function sanitizePaymentRequest(
  values: PrefilledPaymentRequestFormValues,
): Partial<PaymentRequestFormValues> {
  let newValues = { ...values };
  if (newValues.amount) {
    newValues = { ...newValues, amount: newValues.amount.split(".")[0] };
  }
  if (newValues.amount && /[^\d$,]/g.test(newValues.amount)) {
    newValues = { ...newValues, amount: "" };
  }
  if (newValues.propertyZip && /[^\d-]/g.test(newValues.propertyZip)) {
    newValues = { ...newValues, propertyZip: "" };
  }
  if (newValues.remitterPhone && /[^\d.() +-]/g.test(newValues.remitterPhone)) {
    newValues = { ...newValues, remitterPhone: "" };
  } else if (newValues.remitterPhone) {
    const strippedNumber = newValues.remitterPhone.replace(/[^\d]/g, "");
    const maxAllowedDigits = strippedNumber[0] === "1" ? 11 : 10;
    newValues = {
      ...newValues,
      remitterPhone:
        strippedNumber.length > maxAllowedDigits ? "" : strippedNumber,
    };
  }
  if (newValues.propertyState) {
    newValues = {
      ...newValues,
      propertyState: findStateAbbreviationFromStateCodeOrStateName(
        newValues.propertyState,
      ),
    };
  }
  return newValues;
}
