import { useState } from "react";

let globalId = 1;

export function useUniqueId(prefix: string = "id") {
  const [id] = useState(() => {
    const localId = prefix + "-" + globalId;
    globalId += 1;
    return localId;
  });
  return id;
}
