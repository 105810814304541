import { MammonCelebrationTakeover } from "@earnnest/earnnest-ui-web-library/src/MammonCelebration/MammonCelebration";
import { MammonPaymentRequestNextStepsCardContent } from "@earnnest/earnnest-ui-web-library/src/MammonPaymentRequestNextStepsCardContent/MammonPaymentRequestNextStepsCardContent";
import { MarkScreenAsDonePrepping } from "@earnnest/earnnest-ui-web-library/src/MarkScreenAsDonePrepping";
import React from "react";
import { Helmet } from "react-helmet";

export enum CelebrationType {
  PaymentRequested,
  InviteSent,
}

export function RequestCelebration({
  variant = CelebrationType.PaymentRequested,
}) {
  return (
    <>
      <Helmet>
        <title>Payment Requested</title>
      </Helmet>
      <MarkScreenAsDonePrepping initialMinimumTime={0} />
      {variant === CelebrationType.PaymentRequested && (
        <MammonCelebrationTakeover
          label={<>Requested</>}
          ancillaryText={<>You can safely close this window</>}
          card={
            <MammonPaymentRequestNextStepsCardContent
              bodyText={
                <>
                  Once payment is made, you will receive an email with the
                  payment receipt attached.
                </>
              }
            />
          }
        />
      )}
      {variant === CelebrationType.InviteSent && (
        <MammonCelebrationTakeover
          label={<>Sent</>}
          ancillaryText={<>You can safely close this window</>}
          card={
            <MammonPaymentRequestNextStepsCardContent
              bodyText={
                <>
                  The person finishing the request will input the payment
                  amount, payer’s email address and send the request. Once
                  payment is made you will receive an email with a receipt
                  attached.
                </>
              }
            />
          }
        />
      )}
    </>
  );
}
