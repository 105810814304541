import {
  CreateInboundPaymentRequestFunc,
  SearchEscrowAccountFunc,
} from "@earnnest/earnnest-ui-web-library/src/api";
import {
  AgentRole,
  ProcessingStates,
} from "@earnnest/earnnest-ui-web-library/src/common";
import { IconSize } from "@earnnest/earnnest-ui-web-library/src/Icon";
import { IconCaretDown } from "@earnnest/earnnest-ui-web-library/src/IconCaretDown";
import {
  MammonButton,
  MammonButtonState,
} from "@earnnest/earnnest-ui-web-library/src/MammonButton/MammonButton";
import { MammonButtonActionText } from "@earnnest/earnnest-ui-web-library/src/MammonButtonActionText/MammonButtonActionText";
import { MammonCardLayoutFooter } from "@earnnest/earnnest-ui-web-library/src/MammonCardLayout/MammonCardLayout";
import { MammonInputBoxType } from "@earnnest/earnnest-ui-web-library/src/MammonForm/MammonInput";
import {
  ConnectedMammonCheckboxFieldAndInput,
  ConnectedMammonFieldAndInput,
  MammonProvideForm,
  useMammonFormBuilder,
} from "@earnnest/earnnest-ui-web-library/src/MammonForm/useMammonForm";
import { MammonOverlay } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import { StateAbbreviationInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/StateAbbreviationInput";
import { USDollarInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USDollarInput";
import { USPhoneInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USPhoneInput";
import { USZip4Input } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USZip4Input";
import { runPromiseForMinimumTime } from "@earnnest/earnnest-ui-web-library/src/promiseHelpers";
import { formatNumberAsUsd } from "@earnnest/earnnest-ui-web-library/src/sharedStringHelpers";
import {
  InlineChild,
  InlineContainer,
} from "@earnnest/earnnest-ui-web-library/src/Stack/Inline";
import { Stack } from "@earnnest/earnnest-ui-web-library/src/Stack/Stack";
import { User } from "@earnnest/earnnest-ui-web-library/src/User";
import omit from "lodash/omit";
import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Flex, Text } from "theme-ui";
import { useEffectReducer } from "use-effect-reducer";
import { EscrowSearchOverlayContent } from "./EscrowSearchOverlayContent";
import {
  sanitizePaymentRequest,
  validatePaymentRequest,
} from "./paymentRequestHelpers";

export type PaymentRequestFormValues = {
  remitterFirstName: string;
  remitterLastName: string;
  remitterEmail: string;
  remitterPhone: string;
  requestorEmail: string;
  amount: string;
  propertyAddress: string;
  propertyAddress2: string;
  propertyCity: string;
  propertyState: string;
  propertyZip: string;
  selectedEscrowAccount: any | null;
  escrowAccountMatchesContract: boolean;
  fullyExecutedContract: boolean;
};

export type PaymentRequestFormValuesKeys = keyof PaymentRequestFormValues;

export type PaymentRequestFormDirty = {
  [k in PaymentRequestFormValuesKeys]: boolean;
};

export type PrefilledPaymentRequestFormValues = Partial<
  PaymentRequestFormValues
>;

export type RequestPortalFormProps = {
  additionalEmailsToBeNotified: string[];
  isFormGeneratedByARequestorLink: boolean;
  user: User;
  selectedRole: AgentRole | null;
  prefilledFormValues: PrefilledPaymentRequestFormValues;
  searchEscrowAccounts: SearchEscrowAccountFunc;
  createInboundPaymentRequest: CreateInboundPaymentRequestFunc;
  escrowHolderInvitationUrl: URL;
  onFormSubmitted: (role: AgentRole, resourceId: string) => void;
  onFormSubmissionError: (error: Error) => void;
};

export function RequestPortalForm({
  prefilledFormValues,
  additionalEmailsToBeNotified,
  isFormGeneratedByARequestorLink,
  user,
  selectedRole,
  escrowHolderInvitationUrl,
  createInboundPaymentRequest,
  searchEscrowAccounts,
  onFormSubmitted,
  onFormSubmissionError,
}: RequestPortalFormProps) {
  const sanitizedPrefilledValues = useMemo(() => {
    return sanitizePaymentRequest(prefilledFormValues);
  }, [prefilledFormValues]);

  const defaultValues = useMemo(() => {
    return {
      remitterFirstName: "",
      remitterLastName: "",
      remitterEmail: "",
      remitterPhone: "",
      requestorEmail: "",
      amount: "",
      propertyAddress: "",
      propertyAddress2: "",
      propertyCity: "",
      propertyState: "",
      propertyZip: "",
      selectedEscrowAccount: null,
      escrowAccountMatchesContract: false,
      fullyExecutedContract: false,
    };
  }, []);

  const initialValues = useMemo(() => {
    return { ...defaultValues, ...sanitizedPrefilledValues };
  }, [defaultValues, sanitizedPrefilledValues]);

  // Mark all the values dirty that are prefilled
  const initialDirty = useMemo(() => {
    const valuesKeys = Object.keys(
      initialValues,
    ) as PaymentRequestFormValuesKeys[];
    const dirty = valuesKeys.reduce((dirtyObj, key) => {
      return { ...dirtyObj, [key]: Boolean(prefilledFormValues[key]) };
    }, {} as PaymentRequestFormDirty);
    return dirty;
  }, [initialValues, prefilledFormValues]);

  function isFieldLocked(key: PaymentRequestFormValuesKeys): boolean {
    return isFormGeneratedByARequestorLink && initialDirty[key];
  }

  const [state, dispatch] = useEffectReducer(
    (
      state: {
        showEscrowSearchOverlay: boolean;
        createdPaymentRequest: any;
        generatedLink: string | null;
      },
      action:
        | { type: "select_escrow_holder_clicked" }
        | { type: "escrow_holder_overlay_closed" }
        | { type: "select_escrow_account"; payload: boolean }
        | { type: "remove_selected_escrow_account" }
        | {
            type: "form_submitted";
            payload: { requestType: string; requestData: any };
          },
      exec,
    ) => {
      switch (action.type) {
        case "select_escrow_holder_clicked":
          return { ...state, showEscrowSearchOverlay: true };
        case "escrow_holder_overlay_closed":
          return { ...state, showEscrowSearchOverlay: false };
        case "select_escrow_account":
          const selectedEscrowAccount = action.payload;
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return { ...prevValues, selectedEscrowAccount };
              },
              ["selectedEscrowAccount"],
            );
          });
          return { ...state, showEscrowSearchOverlay: false };
        case "remove_selected_escrow_account":
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return {
                  ...prevValues,
                  selectedEscrowAccount: null,
                  escrowAccountMatchesContract: false,
                };
              },
              ["selectedEscrowAccount", "escrowAccountMatchesContract"],
            );
          });
          return { ...state };
        case "form_submitted":
          if (action.payload.requestType === "requestor") {
            return { ...state, generatedLink: action.payload.requestData };
          }
          if (action.payload.requestType === "payer") {
            return {
              ...state,
              createdPaymentRequest: action.payload.requestData,
            };
          }
          return state;
        default:
          throw new Error(
            "[RequestPortalScreen] Unrecognized action in screen level dispatcher",
          );
      }
    },
    () => {
      return {
        showEscrowSearchOverlay: false,
        generatedLink: null,
        createdPaymentRequest: null,
      };
    },
  );

  const handleFormSubmit = useCallback(
    async values => {
      // If we get here, we know the form is already valid.
      const amountInCents = values?.amount
        ? `${values.amount}00`
        : values?.amount;
      const toSendToServer = omit(
        {
          ...values,
          amount: amountInCents, // Unified backend wants cents
          ...(additionalEmailsToBeNotified.length > 0
            ? { emails: additionalEmailsToBeNotified }
            : {}),
        },
        selectedRole === AgentRole.Creator
          ? [
              "remitterEmail",
              "remitterPhone",
              "remitterFirstName",
              "remitterLastName",
              "amount",
              "emails",
            ]
          : ["requestorEmail"],
      );
      console.log("[Request Portal] Submitting payment request.");
      console.log(JSON.stringify(toSendToServer));
      const { requestType, requestData } = await runPromiseForMinimumTime<{
        requestType: string;
        requestData: any;
      }>(createInboundPaymentRequest!(toSendToServer));
      dispatch({
        type: "form_submitted",
        payload: { requestType, requestData },
      });

      // Error handling is done by the form handler changing the processing
      // state to be an error, and an effect below listens for that state.
      // We simply display a notification that an error has occurred.
    },
    [
      createInboundPaymentRequest,
      dispatch,
      additionalEmailsToBeNotified,
      selectedRole,
    ],
  );

  const validateWithRoleEmbedded = useCallback(
    values => {
      return validatePaymentRequest(selectedRole as AgentRole, values);
    },
    [selectedRole],
  );

  // Find the first non-dirty field to receive initial focus
  const dirtyKeys = Object.keys(initialDirty) as PaymentRequestFormValuesKeys[];
  const initialFocus = dirtyKeys.find(key => {
    return initialDirty[key] === false;
  });

  const form = useMammonFormBuilder<PaymentRequestFormValues>({
    initialFocus,
    initialValues,
    initialDirty,
    validate: validateWithRoleEmbedded,
    submit: handleFormSubmit,
  });

  const { submissionState, submissionError, setValues: setFormValues } = form;
  const hidePropertyAddress2 =
    isFieldLocked("propertyAddress") && !form.values.propertyAddress2;

  const roleDependentFormConfig = useMemo(() => {
    const config = {
      [AgentRole.Requestor]: {
        pageTitle: "Request Payment",
        detailsHeader: "What are the details?",
        submitButtonText: `Request ${formatNumberAsUsd(
          form.values.amount || 0,
        )}`,
        showBuyerInfoSection: true,
        showInviteRequestorSection: false,
        showEarnestMoneySection: true,
        showFullyExecutedCheck: true,
      },
      [AgentRole.Creator]: {
        pageTitle: "Setup Payment",
        detailsHeader: "What's the property?",
        submitButtonText: "Send",
        showBuyerInfoSection: false,
        showInviteRequestorSection: true,
        showEarnestMoneySection: false,
        showFullyExecutedCheck: false,
      },
    };
    return config[(selectedRole as AgentRole) || AgentRole.Requestor];
  }, [selectedRole, form.values.amount]);

  const emailsToBeNotified = useMemo(() => {
    const allEmails = [
      user.email,
      selectedRole === AgentRole.Creator
        ? form.values.requestorEmail
        : form.values.remitterEmail,
      form.values.selectedEscrowAccount?.organizationEmail,
      ...additionalEmailsToBeNotified,
    ];
    const presentEmails = allEmails.filter(e => Boolean(e));
    const uniqueEmails = [...new Set(presentEmails)];
    return uniqueEmails;
  }, [
    user.email,
    selectedRole,
    form.values.requestorEmail,
    form.values.remitterEmail,
    form.values.selectedEscrowAccount,
    additionalEmailsToBeNotified,
  ]);

  const userDisplayName = useMemo(() => {
    return [user.firstName, user.lastName].filter(p => Boolean(p)).join(" ");
  }, [user.firstName, user.lastName]);

  const handleClickSelectEscrowHolder = useCallback(() => {
    dispatch({ type: "select_escrow_holder_clicked" });
  }, [dispatch]);

  const handleCloseEscrowSearchOverlay = useCallback(() => {
    dispatch({ type: "escrow_holder_overlay_closed" });
  }, [dispatch]);

  const handleSelectEscrowAccount = useCallback(
    selectedEscrowAccount => {
      dispatch({
        type: "select_escrow_account",
        payload: selectedEscrowAccount,
      });
    },
    [dispatch],
  );

  const handleClickRemoveEscrowHolder = useCallback(() => {
    if (window.confirm("Remove designated escrow holder?")) {
      dispatch({ type: "remove_selected_escrow_account" });
    }
  }, [dispatch]);

  useEffect(() => {
    if (submissionState === ProcessingStates.Processed) {
      let resourceId;
      if (selectedRole === AgentRole.Requestor) {
        resourceId = state.createdPaymentRequest.id;
      } else if (selectedRole === AgentRole.Creator) {
        resourceId = window.btoa(encodeURIComponent(state.generatedLink!));
      } else {
        throw new Error(
          "Invalid agent role supplied in processed form handler",
        );
      }
      onFormSubmitted(selectedRole, resourceId);
    }
  }, [
    onFormSubmitted,
    selectedRole,
    state.createdPaymentRequest,
    state.generatedLink,
    submissionState,
  ]);

  useEffect(() => {
    if (submissionState === ProcessingStates.Error) {
      console.log(
        "[RequestPortal] Error occurred submitting inbound payment request.",
      );
      console.error(submissionError);
      onFormSubmissionError(submissionError);
    }
  }, [submissionState, submissionError, onFormSubmissionError]);

  return (
    <>
      <MammonOverlay onClose={handleCloseEscrowSearchOverlay}>
        {state.showEscrowSearchOverlay && (
          <EscrowSearchOverlayContent
            onClose={handleCloseEscrowSearchOverlay}
            propertyState={form.values.propertyState}
            inviteHref={escrowHolderInvitationUrl.href}
            searchEscrowAccounts={searchEscrowAccounts!}
            onSelect={handleSelectEscrowAccount}
          />
        )}
      </MammonOverlay>
      <MammonProvideForm form={form}>
        <form id="request-portal-form" onSubmit={form.handleSubmit}>
          <Box>
            <Stack gap={4 * 8}>
              <Stack gap={4 * 2}>
                <Text variant="body.pageTitle">
                  {roleDependentFormConfig?.pageTitle}
                </Text>
                <Text variant="body.paragraph">
                  {userDisplayName}, we’ve added in the information you have
                  already supplied. Fill out any remaining details.
                </Text>
              </Stack>
              {roleDependentFormConfig?.showBuyerInfoSection && (
                <>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 4}>
                    <Text variant="body.subPageTitle">Who's paying?</Text>
                    <Stack gap={4 * 3}>
                      <Stack gap={4 * 2}>
                        <Text variant="body.subSectionTitle">Their name</Text>
                        <Box>
                          <InlineContainer gap={4 * 4}>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterFirstName"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="First name"
                                name="remitter-fn"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterLastName"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="Last name"
                                name="remitter-ln"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                          </InlineContainer>
                        </Box>
                      </Stack>
                      <Stack gap={4 * 2}>
                        <Text variant="body.subSectionTitle">
                          Their contact info
                        </Text>
                        <Box>
                          <InlineContainer gap={4 * 4}>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterEmail"
                                boxType={MammonInputBoxType.Underline}
                                type="email"
                                label="Email address"
                                name="remitter-e"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterPhone"
                                boxType={MammonInputBoxType.Underline}
                                as={USPhoneInput}
                                type="tel"
                                label="Mobile phone"
                                name="remitter-p"
                                autoComplete="dontcomplete"
                                placeholder="###-###-####"
                                assist={
                                  <>Must be able to receive SMS messages</>
                                }
                              />
                            </InlineChild>
                          </InlineContainer>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              )}
              {roleDependentFormConfig?.showInviteRequestorSection && (
                <>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 4}>
                    <Text variant="body.subPageTitle">
                      Who has the payer's info?
                    </Text>
                    <Stack gap={4 * 2}>
                      <Text variant="body.subSectionTitle">
                        This is the person who has the contact information of
                        the person paying. Often this is the buyer’s agent.
                      </Text>
                      <Box>
                        <ConnectedMammonFieldAndInput
                          fieldKey="requestorEmail"
                          boxType={MammonInputBoxType.Underline}
                          type="email"
                          label="Email address"
                          name="requestor-e"
                          autoComplete="dontcomplete"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </>
              )}
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 4}>
                <Text variant="body.subPageTitle">
                  {roleDependentFormConfig?.detailsHeader}
                </Text>
                <Stack gap={4 * 4}>
                  {roleDependentFormConfig?.showEarnestMoneySection && (
                    <Stack gap={4 * 2}>
                      <Text variant="body.subSectionTitle">
                        Earnest money deposit
                      </Text>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "50%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="amount"
                              boxType={MammonInputBoxType.Underline}
                              as={USDollarInput}
                              type="tel"
                              inputMode="decimal"
                              label="Amount"
                              name="earnest-money-a"
                              autoComplete="dontcomplete"
                              placeholder="$0.00"
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                    </Stack>
                  )}
                  <Stack gap={4 * 2}>
                    <Text variant="body.subSectionTitle">
                      The property address
                    </Text>
                    {isFieldLocked("propertyAddress") && (
                      <Text variant="body.paragraph">
                        This information has already been set and can’t be
                        edited.
                      </Text>
                    )}
                    <Stack gap={0}>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild
                            sx={{
                              width: hidePropertyAddress2 ? "100%" : "66.66%",
                            }}
                          >
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyAddress"
                              boxType={MammonInputBoxType.Underline}
                              type="text"
                              label="Street address"
                              name="earnest-money-pa"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyAddress")}
                            />
                          </InlineChild>
                          {!hidePropertyAddress2 && (
                            <InlineChild sx={{ width: "33.33%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="propertyAddress2"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="Address 2"
                                name="earnest-money-pa-2"
                                autoComplete="dontcomplete"
                                readOnly={
                                  isFieldLocked("propertyAddress2") ||
                                  isFieldLocked("propertyAddress")
                                }
                              />
                            </InlineChild>
                          )}
                        </InlineContainer>
                      </Box>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "66.66%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyCity"
                              boxType={MammonInputBoxType.Underline}
                              type="text"
                              label="City"
                              name="earnest-money-pc"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyCity")}
                            />
                          </InlineChild>
                          <InlineChild sx={{ width: "33.33%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyState"
                              boxType={MammonInputBoxType.Underline}
                              as={StateAbbreviationInput}
                              type="text"
                              label="State"
                              name="earnest-money-ps"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyState")}
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "33.33%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyZip"
                              boxType={MammonInputBoxType.Underline}
                              as={USZip4Input}
                              type="tel"
                              label="Zip code"
                              name="earnest-money-pz"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyZip")}
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 4}>
                <Text variant="body.subPageTitle">
                  Who's receiving the payment?
                </Text>
                <Stack gap={4 * 3}>
                  <Stack gap={4 * 2}>
                    <Text variant="body.subSectionTitle">
                      The escrow holder
                    </Text>
                    {isFieldLocked("selectedEscrowAccount") && (
                      <Text variant="body.paragraph">
                        This information has already been set and can’t be
                        edited.
                      </Text>
                    )}
                    <Stack gap={16}>
                      <SelectedEscrowHolderFieldBox
                        fieldKey="selectedEscrowAccount"
                        disabled={
                          form.errors.propertyState ||
                          isFieldLocked("selectedEscrowAccount")
                        }
                        readOnly={isFieldLocked("selectedEscrowAccount")}
                        selectedEscrowAccount={
                          form.values.selectedEscrowAccount
                        }
                        onClickSelectEscrowHolder={
                          handleClickSelectEscrowHolder
                        }
                        onClickRemoveEscrowHolder={
                          handleClickRemoveEscrowHolder
                        }
                      />
                      {!isFieldLocked("escrowAccountMatchesContract") && (
                        <Box>
                          <ConnectedMammonCheckboxFieldAndInput
                            fieldKey="escrowAccountMatchesContract"
                            disabled={!form.values.selectedEscrowAccount}
                            label={
                              <>
                                The above listed Escrow Holder is the one on the
                                purchase contract.
                              </>
                            }
                            size="sm"
                          />
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 2}>
                <Text variant="body.sectionTitle">Notification receipts</Text>
                <Stack gap={4 * 4}>
                  <Text variant="body.paragraph">
                    These email addresses will receive receipt payment
                    notifications.
                  </Text>
                  <Stack gap={4 * 2}>
                    {emailsToBeNotified.map((email, index) => {
                      return (
                        <Box key={index}>
                          <Text
                            variant="body.subSectionTitle"
                            sx={{ wordBreak: "break-word" }}
                          >
                            {email}
                          </Text>
                        </Box>
                      );
                    })}
                  </Stack>
                </Stack>
              </Stack>
              {roleDependentFormConfig?.showFullyExecutedCheck && (
                <Stack gap={32}>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ConnectedMammonCheckboxFieldAndInput
                        fieldKey="fullyExecutedContract"
                        label={
                          <Text variant="body.sectionTitle">
                            Fully executed contract
                          </Text>
                        }
                        size="sm"
                      />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Text variant="body.paragraph">
                        Do not request funds without a fully executed contract
                      </Text>
                    </Box>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
        </form>
      </MammonProvideForm>
      <FormFooter
        buttonText={roleDependentFormConfig?.submitButtonText}
        submissionState={form.submissionState}
        shouldSubmissionButtonBeDisabled={form.shouldSubmissionButtonBeDisabled}
      />
    </>
  );
}

function FormFooter({
  buttonText,
  submissionState,
  shouldSubmissionButtonBeDisabled,
}: {
  buttonText?: string;
  submissionState: ProcessingStates;
  shouldSubmissionButtonBeDisabled: boolean;
}) {
  const buttonState =
    submissionState === ProcessingStates.Processed
      ? MammonButtonState.Processed
      : submissionState === ProcessingStates.Processing
      ? MammonButtonState.Processing
      : shouldSubmissionButtonBeDisabled
      ? MammonButtonState.Disabled
      : MammonButtonState.Initial;
  return (
    <MammonCardLayoutFooter sticky={false}>
      {useMemo(
        () => (
          <Flex sx={{ justifyContent: "center", paddingBottom: 4 * 5 }}>
            <MammonButton
              form="request-portal-form"
              type="submit"
              size="large"
              fillMode="filled"
              color="dark"
              state={buttonState}
            >
              {buttonText}
            </MammonButton>
          </Flex>
        ),
        [buttonState, buttonText],
      )}
    </MammonCardLayoutFooter>
  );
}

function SelectedEscrowHolderFieldBox({
  fieldKey,
  disabled,
  readOnly,
  onClickSelectEscrowHolder = () => {},
  onClickRemoveEscrowHolder = () => {},
  selectedEscrowAccount,
}: {
  fieldKey: string;
  disabled: boolean;
  readOnly: boolean;
  onClickSelectEscrowHolder: () => void;
  onClickRemoveEscrowHolder: () => void;
  selectedEscrowAccount: any;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <ConnectedMammonFieldAndInput
          fieldKey={fieldKey}
          disabled={disabled}
          readOnly={readOnly}
          boxType={MammonInputBoxType.Outline}
          as="button"
          type="button"
          onClick={onClickSelectEscrowHolder}
          icon={readOnly ? null : <IconCaretDown size={IconSize.SM} />}
          children={
            selectedEscrowAccount ? (
              <>
                <>{selectedEscrowAccount.brandName}</>
                <> - </>
                <>{selectedEscrowAccount.bankFriendlyName}</>
              </>
            ) : (
              <>Select escrow holder</>
            )
          }
        />
      </Box>
      {!disabled && !readOnly && selectedEscrowAccount && (
        <Box
          sx={{
            display: "inline-flex",
            position: "absolute",
            right: 0,
            bottom: -4,
          }}
        >
          <MammonButtonActionText onClick={onClickRemoveEscrowHolder}>
            Remove escrow holder
          </MammonButtonActionText>
        </Box>
      )}
    </Box>
  );
}
