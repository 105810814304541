/** @jsx jsx */
import { transitions } from "polished";
import { FormEvent, useMemo, ReactNode } from "react";
import { ButtonProps, Flex, jsx, Theme, Label, Text } from "theme-ui";
import { IconCheck } from "../IconCheck";
import { FocusableElement } from "./FocusableElement";
import { useUniqueId } from "../useUniqueId";
import { IconSize } from "../Icon";
import { Stack } from "../Stack/Stack";

export type MammonInputCheckboxProps = ButtonProps & {
  size: "xs" | "sm" | "md";
  checked: boolean;
  onCheck: (checked: boolean) => void;
  label?: ReactNode;
  subtext?: ReactNode;
  hasFocus?: boolean;
  disabled?: boolean;
};

const DEFAULT_ON_CHECK = (checked: boolean) => {};
const DEFAULT_ON_CLICK = (e: FormEvent<HTMLButtonElement>) => {};

export function MammonInputCheckbox({
  size,
  checked = false,
  hasFocus = false,
  disabled = false,
  label,
  subtext,
  onCheck = DEFAULT_ON_CHECK,
  onClick = DEFAULT_ON_CLICK,
  ...restProps
}: MammonInputCheckboxProps) {
  const id = useUniqueId();
  const config = useMemo(() => {
    const config = {
      xs: {
        width: 16,
        height: 16,
        iconSize: IconSize.XS,
      },
      sm: {
        width: 24,
        height: 24,
        iconSize: IconSize.SM,
      },
      md: {
        width: 32,
        height: 32,
        iconSize: IconSize.MD,
      },
    };
    return config[size];
  }, [size]);
  return (
    <Flex>
      <Flex
        sx={{
          flex: "0 0 auto",
          display: "inline-flex",
          // Center the checkbox icon in the middle of the label text
          mt: label ? Math.max(0, (16 * 1.4 - config.height) / 2) : 0,
        }}
      >
        <FocusableElement<HTMLButtonElement, ButtonProps>
          id={id}
          as="button"
          type="button"
          hasFocus={hasFocus}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            appearance: "none",
            margin: 0,
            padding: 0,
            width: config.width,
            height: config.height,
            borderRadius: 99,
            backgroundColor: checked ? "green" : "transparent",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: checked ? "green" : "purple5",
            outline: "none",
            ...transitions(
              ["box-shadow", "background-color", "color", "opacity"],
              "0.2s ease-out",
            ),
            "&:focus": {
              boxShadow: (theme: Theme) =>
                `0 0 0 .15em ${theme.colors?.purple2Shadow}`,
            },
            "&[disabled]": {
              opacity: 0.5,
              cursor: "not-allowed",
            },
          }}
          onClick={e => {
            onClick(e);
            onCheck(!checked);
          }}
          disabled={disabled}
          {...restProps}
        >
          <Flex sx={{ display: "inline-flex" }}>
            <IconCheck
              size={config.iconSize}
              color={checked ? "white" : "transparent"}
            />
          </Flex>
        </FocusableElement>
      </Flex>
      {label && (
        <Flex
          sx={{
            display: "inline-flex",
            flex: "1 1 auto",
            mt: Math.max(0, (config.height - 16 * 1.4) / 2),
            ml: 16,
          }}
        >
          <Label
            htmlFor={id}
            sx={{
              opacity: disabled ? 0.5 : 1.0,
              cursor: disabled ? "not-allowed" : undefined,
            }}
          >
            <Stack>
              <Text
                variant="form.inputText"
                sx={{ fontWeight: subtext ? 500 : undefined }}
              >
                {label}
              </Text>
              {subtext && <Text variant="slat.paragraph">{subtext}</Text>}
            </Stack>
          </Label>
        </Flex>
      )}
    </Flex>
  );
}
