/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconClose({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.06066 6.06065C5.47487 6.64643 5.47487 7.59618 6.06066 8.18197L9.94965 12.071L6.06043 15.9602C5.47465 16.546 5.47465 17.4957 6.06043 18.0815C6.64622 18.6673 7.59597 18.6673 8.18175 18.0815L12.071 14.1923L15.9602 18.0815C16.5459 18.6672 17.4957 18.6672 18.0815 18.0815C18.6673 17.4957 18.6673 16.5459 18.0815 15.9601L14.1923 12.071L18.0812 8.18199C18.667 7.59621 18.667 6.64646 18.0812 6.06067C17.4955 5.47489 16.5457 5.47489 15.9599 6.06067L12.071 9.94963L8.18198 6.06065C7.59619 5.47486 6.64645 5.47486 6.06066 6.06065Z"
        sx={{ fill: color }}
      />
    </IconSvg>
  );
}
