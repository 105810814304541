/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconCaretDown({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.98938 10.9133C2.80925 10.7137 2.81927 10.4073 3.01206 10.2199L6.28886 7.03401C6.49424 6.83433 6.82507 6.84767 7.0137 7.06325L15.6237 16.9033C15.8229 17.131 16.1771 17.131 16.3763 16.9033L24.9863 7.06325C25.175 6.84767 25.5058 6.83433 25.7112 7.03401L28.988 10.2199C29.1808 10.4073 29.1908 10.7137 29.0107 10.9133L16.3712 24.9219C16.1727 25.142 15.8274 25.142 15.6288 24.9219L2.98938 10.9133Z"
        sx={{ fill: color }}
      />
    </IconSvg>
  );
}
