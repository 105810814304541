import React, { useMemo, MouseEvent, KeyboardEvent, useCallback } from "react";
import { Button, ButtonProps, Box } from "theme-ui";
import { Key } from "../common";

// Can come from either a click or key up/down
export type DisableableClickEvent =
  | MouseEvent<HTMLButtonElement>
  | KeyboardEvent<HTMLButtonElement>;

// Override the click event to accept our more generic click handler
export type ActAsButtonProps = Omit<ButtonProps, "onClick"> & {
  onClick?: (e: DisableableClickEvent) => void;
};

const DEFAULT_ON_CLICK = (e: DisableableClickEvent) => {};
const DEFAULT_ON_KEY_DOWN = () => {};
const DEFAULT_ON_KEY_UP = () => {};

export function ActAsButton({
  onClick = DEFAULT_ON_CLICK,
  onKeyDown = DEFAULT_ON_KEY_DOWN,
  onKeyUp = DEFAULT_ON_KEY_UP,
  disabled = false,
  ...restProps
}: ActAsButtonProps) {
  const onDisableableClick = useMemo(() => {
    function onDisableableClick(e: DisableableClickEvent) {
      if (disabled) {
        return;
      }
      onClick(e);
    }
    return onDisableableClick;
  }, [disabled, onClick]);
  const onKeyDownHandler = useCallback(
    e => {
      if (e.keyCode === Key.Space) {
        e.preventDefault();
      } else if (e.keyCode === Key.Enter) {
        e.preventDefault();
        onDisableableClick(e);
      }
      onKeyDown(e);
    },
    [onDisableableClick, onKeyDown],
  );
  const onKeyUpHandler = useCallback(
    e => {
      if (e.keyCode === Key.Space) {
        e.preventDefault();
        onDisableableClick(e);
      }
      onKeyUp(e);
    },
    [onDisableableClick, onKeyUp],
  );
  // We do this in order to not pick up any of the default Theme UI
  // button styles
  const BoxAsButton = (Box as unknown) as typeof Button;
  return (
    <BoxAsButton
      as="span"
      disabled={disabled}
      aria-disabled={disabled ? "true" : undefined}
      role={disabled ? undefined : "button"}
      tabIndex={disabled ? undefined : 0}
      onClick={onDisableableClick}
      onKeyDown={onKeyDownHandler}
      onKeyUp={onKeyUpHandler}
      {...restProps}
    />
  );
}
