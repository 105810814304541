import { DefaultMammonOverlayContent } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import {
  MammonTextNav,
  MammonTextNavDirection,
} from "@earnnest/earnnest-ui-web-library/src/MammonTextNav/MammonTextNav";
import React, { ReactNode } from "react";
import { Flex } from "theme-ui";

export type DefaultPortalOverlayContentProps = {
  onClose: () => void;
  children: ReactNode;
};

export function DefaultPortalOverlayContent({
  onClose,
  children,
}: DefaultPortalOverlayContentProps) {
  return (
    <DefaultMammonOverlayContent>
      <Flex
        py={
          // This is in addition to the already default overlay 24px
          40
        }
        sx={{ position: "relative", flexDirection: "column" }}
      >
        {children}
        <Flex
          sx={{
            display: "inline-flex",
            position: "absolute",
            top: -4,
            left: 0,
          }}
        >
          <MammonTextNav
            direction={MammonTextNavDirection.Regression}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </MammonTextNav>
        </Flex>
      </Flex>
    </DefaultMammonOverlayContent>
  );
}
