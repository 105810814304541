import { AuthRedirectCallback } from "@earnnest/earnnest-ui-web-library/src/Auth/Auth";
import { SetupAndRequireConfig } from "@earnnest/earnnest-ui-web-library/src/Config/Config";
import { TriggerNotFoundError } from "@earnnest/earnnest-ui-web-library/src/errors";
import {
  GlobalMammonCardLayoutStyles,
  MammonCardLayoutTheme,
  SetupMammonCardLayout,
} from "@earnnest/earnnest-ui-web-library/src/MammonCardLayout/MammonCardLayout";
import { RequireAuthAndUser } from "@earnnest/earnnest-ui-web-library/src/User";
import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  RequestCelebration,
  CelebrationType,
} from "./RequestPortal/RequestCelebration";
import { RequestPortalRoute } from "./RequestPortal/RequestPortalRoute";
import { SetupApp } from "./SetupApp";

ReactDOM.render(
  <SetupAndRequireConfig>
    <Router>
      <SetupApp>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <AuthRedirectCallback fallback={<TriggerNotFoundError />} />
            )}
          />
          {/* If any additional routes are added, make sure to update the
              public/index.html session pre-loader condition */}
          <Route
            exact
            path={[
              "/request",
              "/request/payer/:requestId",
              "/request/requestor/:link",
            ]}
            render={({ location }) => (
              <RequireAuthAndUser>
                <SetupMammonCardLayout theme={MammonCardLayoutTheme.NEUTRAL}>
                  <GlobalMammonCardLayoutStyles />
                  {/*
                    See the individual motion.div components in each Route for
                    details on the enter and exit animations for each route
                  */}
                  <AnimatePresence exitBeforeEnter={true} initial={false}>
                    <Switch location={location} key={location.pathname}>
                      <Route
                        exact
                        path="/request"
                        component={RequestPortalRoute}
                      />
                      <Route exact path="/request/payer/:requestId">
                        <RequestCelebration
                          variant={CelebrationType.PaymentRequested}
                        />
                      </Route>
                      <Route exact path="/request/requestor/:link">
                        <RequestCelebration
                          variant={CelebrationType.InviteSent}
                        />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </SetupMammonCardLayout>
              </RequireAuthAndUser>
            )}
          />
          <TriggerNotFoundError />
        </Switch>
      </SetupApp>
    </Router>
  </SetupAndRequireConfig>,
  document.getElementById("root"),
);
