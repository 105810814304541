import { InlineLink } from "@earnnest/earnnest-ui-web-library/src/InlineLink/InlineLink";
import React from "react";
import { Helmet } from "react-helmet";
import { EarnnestClientError, EarnnestClientErrorType } from "../errors";
import { MarkScreenAsDonePrepping } from "../MarkScreenAsDonePrepping";
import { TakeoverScreen } from "./TakeoverScreen";
import { ErrorScreenProps } from "./ErrorScreen";

export type ErrorClientScreenProps = ErrorScreenProps & {
  error: EarnnestClientError;
};

const DEFAULT_ON_CONTACT_SUPPORT = () => {};

export function ErrorClientScreen({
  error,
  onContactSupport = DEFAULT_ON_CONTACT_SUPPORT,
}: ErrorClientScreenProps) {
  const { clientErrorType } = error;
  return (
    <>
      <Helmet>
        <title>
          {clientErrorType === EarnnestClientErrorType.NotFound
            ? "Not Found"
            : clientErrorType === EarnnestClientErrorType.UnsupportedBrowser
            ? "Unsupported Browser"
            : clientErrorType === EarnnestClientErrorType.MalformedRequest
            ? "Malformed Request"
            : "Error"}
        </title>
      </Helmet>
      <MarkScreenAsDonePrepping />
      <TakeoverScreen
        primaryMessage={
          clientErrorType === EarnnestClientErrorType.UnsupportedBrowser ? (
            <>We don’t support your browser</>
          ) : clientErrorType === EarnnestClientErrorType.MalformedRequest ? (
            <>Problem with request</>
          ) : (
            <>Well, that’s awkward.</>
          )
        }
        secondaryDescriptor={
          clientErrorType === EarnnestClientErrorType.NotFound ? (
            <>Error 404</>
          ) : clientErrorType === EarnnestClientErrorType.UnsupportedBrowser ? (
            <>Please switch to a modern browser</>
          ) : clientErrorType === EarnnestClientErrorType.MalformedRequest ? (
            <>Have the request creator send you a new request</>
          ) : (
            undefined
          )
        }
        details={
          clientErrorType ===
          EarnnestClientErrorType.MalformedRequest ? null : (
            <>
              {clientErrorType === EarnnestClientErrorType.NotFound ? (
                <>
                  <>We tried to find that page but came up empty-handed.</>
                  <>
                    {" "}
                    If this issue persists, please{" "}
                    <InlineLink onClick={onContactSupport}>
                      contact support
                    </InlineLink>
                    .
                  </>
                </>
              ) : clientErrorType ===
                EarnnestClientErrorType.UnsupportedBrowser ? (
                <>
                  To access this page use one of these browsers:
                  <br />
                  Chrome, Safari, Microsoft Edge or Firefox
                </>
              ) : (
                <>
                  <>
                    We encountered an error and could not complete your request.
                  </>
                  <>
                    {" "}
                    If this issue persists, please{" "}
                    <InlineLink onClick={onContactSupport}>
                      contact support
                    </InlineLink>
                    .
                  </>
                </>
              )}
            </>
          )
        }
      />
    </>
  );
}
