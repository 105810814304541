import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";

//
// Camel case keys on all nested objects.
//
export function camelCaseKeysOnObjectRecursively(object: any): any {
  if (!isPlainObject(object) && !isArray(object)) {
    return object;
  }

  if (isArray(object)) {
    return object.map(obj => {
      return camelCaseKeysOnObjectRecursively(obj);
    });
  }

  return Object.entries(object).reduce((cased, [key, value]) => {
    return {
      ...cased,
      [camelCase(key)]: camelCaseKeysOnObjectRecursively(value),
    };
  }, {});
}
