import React, { ReactNode } from "react";
import { Flex, Text } from "theme-ui";
import { Stack } from "../Stack/Stack";

export type MammonWelcomeSlatProps = {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
};

export function MammonWelcomeSlat({
  icon,
  title,
  description,
}: MammonWelcomeSlatProps) {
  return (
    <Flex sx={{ alignItems: "flex-start", flexWrap: "nowrap" }} color="grey1">
      <Flex sx={{ flex: "0 0 auto" }}>
        <Flex sx={{ display: "inline-flex", width: 40, height: 40 }}>
          {icon}
        </Flex>
      </Flex>
      <Flex ml={4 * 4} sx={{ flex: "1 1 auto" }}>
        <Stack gap={4 * 1}>
          <Flex>
            <Text variant="body.subPageTitle">{title}</Text>
          </Flex>
          <Flex>
            <Text variant="body.paragraph">{description}</Text>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
}
