/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconCaretLeft({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size} viewBox="0 0 16 16">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3645 1.65602C10.5642 1.47589 10.8705 1.48591 11.058 1.6787L12.2993 2.9555C12.499 3.16088 12.4857 3.49171 12.2701 3.68034L7.76338 7.62371C7.53572 7.82291 7.53572 8.17708 7.76338 8.37628L12.2701 12.3197C12.4857 12.5083 12.499 12.8391 12.2993 13.0445L11.058 14.3213C10.8705 14.5141 10.5642 14.5241 10.3645 14.344L3.74478 8.37122C3.52471 8.17266 3.52471 7.82733 3.74478 7.62877L10.3645 1.65602Z"
          sx={{ fill: color }}
        />
      </svg>
    </IconSvg>
  );
}
