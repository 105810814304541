import React, { useCallback } from "react";
import { MaskedInput } from "./MaskedInput";

// prettier-ignore
export const US_PHONE_MASK = [
  /[1-9]/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];

// prettier-ignore
export const US_PHONE_WITH_COUNTRY_CODE_MASK = [
  /1/, "-",
  /[1-9]/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];

const USPhoneInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const mask = useCallback((rawValue: string) => {
    if (rawValue.length > 0 && rawValue[0] === "1") {
      return US_PHONE_WITH_COUNTRY_CODE_MASK;
    }
    return US_PHONE_MASK;
  }, []);
  return <MaskedInput guide={false} mask={mask} ref={ref as any} {...props} />;
});

export { USPhoneInput };
