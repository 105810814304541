/** @jsx jsx */
import { useRef } from "react";
import { BoxOwnProps, jsx } from "theme-ui";
import { useFocusSync } from "../useFocusSync";

export type FocusableInputProps = {
  hasFocus?: boolean;
};

export function FocusableElement<T extends HTMLElement, P extends BoxOwnProps>({
  as: As = "div",
  hasFocus = false,
  ...restProps
}: FocusableInputProps & P) {
  const inputRef = useRef<T>(null);
  useFocusSync<T>(inputRef, hasFocus);
  return <As ref={inputRef} {...restProps} />;
}
