import {
  EarnnestClientError,
  EarnnestClientErrorType,
} from "@earnnest/earnnest-ui-web-library/src/errors";
import debounce from "lodash/debounce";
import React, { ReactNode, useEffect, useState } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";

export type GlobalErrorListenerProps = {
  children: ReactNode;
  renderFallbackOnUnhandledGlobalError?: boolean;
  onError?: (error: Error) => void;
};

const DEFAULT_ON_ERROR = (error: Error) => {};

export function GlobalErrorListener({
  children,
  renderFallbackOnUnhandledGlobalError = false,
  onError = DEFAULT_ON_ERROR,
}: GlobalErrorListenerProps) {
  const [, setState] = useState();

  useEffect(() => {
    function handleUnhandledRejection(event: PromiseRejectionEvent) {
      const { reason } = event;
      let error: Error;
      if (reason instanceof Error) {
        error = reason;
      } else if (typeof reason === "string") {
        error = new EarnnestClientError(
          reason,
          EarnnestClientErrorType.UnhandledRejection,
        );
      } else {
        error = new EarnnestClientError(
          "An unknown unhandled rejection occurred",
          EarnnestClientErrorType.UnhandledRejection,
        );
      }
      console.log("[GlobalErrorListener] Unhandled rejection occurred.");
      if (renderFallbackOnUnhandledGlobalError) {
        setState(() => {
          throw error;
        });
      }
      onError(error);
    }

    function handleWindowError(event: ErrorEvent) {
      const { error } = event;
      if ((error as any).handledByReact) {
        console.debug(
          "[GlobalErrorListener] Error on window has already been handled by React. Not propagating to GlobalErrorListener.",
        );
        return;
      }
      console.log(
        "[GlobalErrorListener] Unhandled error propagated to window.",
      );
      if (renderFallbackOnUnhandledGlobalError) {
        setState(() => {
          throw error;
        });
      }
      onError(error);
    }

    const deferredHandleUnhandledRejection = debounce(handleUnhandledRejection);
    const deferredHandleWindowError = debounce(handleWindowError);

    window.addEventListener(
      "unhandledrejection",
      deferredHandleUnhandledRejection,
    );
    window.addEventListener("error", deferredHandleWindowError);

    console.log(
      "[GlobalErrorListener] Attached global error handlers to window.",
    );

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        deferredHandleUnhandledRejection,
      );
      window.removeEventListener("error", deferredHandleWindowError);
    };
  }, [onError, renderFallbackOnUnhandledGlobalError]);

  return <>{children}</>;
}

export type ErrorBoundaryProps = ReactErrorBoundaryProps & {
  children: ReactNode;
  onError: (error: Error) => void;
};

export function ErrorBoundaryWithGlobalErrorListener({
  children,
  onError = DEFAULT_ON_ERROR,
  ...restProps
}: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      onError={(error: Error) => {
        (error as any).handledByReact = true;

        console.log("[ErrorBoundary] Error occurred in React error boundary.");

        // TODO:
        // Report to our error reporting service
        //
        // Extract out a common utility for understanding our error
        // versions and reporting appropriately

        onError(error);
      }}
      {...restProps}
    >
      <GlobalErrorListener onError={onError}>{children}</GlobalErrorListener>
    </ReactErrorBoundary>
  );
}
