/** @jsx jsx */
import { jsx, useThemeUI, SxStyleProp } from "theme-ui";
import React, {
  useEffect,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { MoveFocusInside } from "react-focus-lock";
import { usePrevious } from "../usePrevious";
import { CloseButton } from "../CloseButton";
import { useInternalKeyManager } from "../useInternalKeyManager";
import {
  MammonAnimateListPresence,
  MammonAnimateListPresenceXDirection,
  MammonAnimateListPresenceYDirection,
} from "../MammonAnimateListPresence/MammonAnimateListPresence";

export const FRIENDLY_DEFAULT_HIDE_AFTER_ELAPSED_MS = 2500;

export enum MammonNotificationType {
  Error,
  Info,
}

export interface DismissNotificationFunc {
  (notification: InternalMammonNotification): void;
}

export interface DisplayNotificationFunc {
  (notification: ExternalMammonNotification): void;
}

interface SetupMammonNotificationsProps {
  children: ReactNode;
}

interface MammonNotification {
  type: MammonNotificationType;
  message: ReactNode;
  hideAfterElapsedMilliseconds?: number;
  dismissable?: boolean;
}

export interface ExternalMammonNotification extends MammonNotification {
  pathname?: string;
}

interface InternalMammonNotification extends MammonNotification {
  key: string;
  pathnames: string[];
}

interface MammonNotificationProps {
  notification: InternalMammonNotification;
}

interface MammonNotificationContext {
  notifications: InternalMammonNotification[];
  dismissNotification: DismissNotificationFunc;
  displayNotification: DisplayNotificationFunc;
}

export const MammonNotificationsContext = React.createContext<
  MammonNotificationContext
>({
  notifications: [],
  dismissNotification: () => {},
  displayNotification: () => {},
});

export const useMammonNotifications = () =>
  useContext(MammonNotificationsContext);

export function SetupMammonNotifications({
  children,
}: SetupMammonNotificationsProps) {
  let location = useLocation();
  const prevLocation = usePrevious(location);
  const [lastScheduledNotification, setLastScheduledNotification] = useState<
    InternalMammonNotification
  >();
  const [notifications, setNotifications] = useState<
    InternalMammonNotification[]
  >([]);
  const getKey = useInternalKeyManager("mammon-notification");

  const displayNotification: DisplayNotificationFunc = useCallback(
    notification => {
      const key = getKey();
      const { pathname, ...restNotification } = notification;
      const processedNotification = {
        key,
        pathnames: ([location.pathname, pathname].filter(p =>
          Boolean(p),
        ) as string[]).flat(),
        ...restNotification,
      };
      setNotifications(prevNotifications => {
        return [processedNotification, ...prevNotifications];
      });
      if (notification.hideAfterElapsedMilliseconds) {
        setLastScheduledNotification(processedNotification);
      }
      return processedNotification;
    },
    [location.pathname, getKey],
  );

  const dismissNotification: DismissNotificationFunc = useCallback(
    notification => {
      setNotifications(prevNotifications => {
        return prevNotifications.filter(n => n !== notification);
      });
    },
    [],
  );

  const context = useMemo(() => {
    return {
      notifications,
      displayNotification,
      dismissNotification,
    };
  }, [notifications, displayNotification, dismissNotification]);

  // When the page changes, clear notifications
  useEffect(() => {
    if (location !== prevLocation) {
      notifications.forEach(notification => {
        if (!notification.pathnames.includes(location.pathname)) {
          dismissNotification(notification);
        }
      });
    }
  }, [location, prevLocation, dismissNotification, notifications]);

  // Handle clearing notification that should auto elapse
  let timeoutsRef = useRef<number[]>([]);
  useEffect(() => {
    return () => {
      timeoutsRef.current.forEach(timeout => {
        clearTimeout(timeout);
      });
    };
  }, []);

  useEffect(() => {
    if (lastScheduledNotification) {
      const dismissTimeout = setTimeout(() => {
        dismissNotification(lastScheduledNotification);
      }, lastScheduledNotification.hideAfterElapsedMilliseconds);
      timeoutsRef.current = [...timeoutsRef.current, dismissTimeout];
    }
  }, [dismissNotification, lastScheduledNotification]);

  return (
    <MammonNotificationsContext.Provider value={context}>
      {children}
    </MammonNotificationsContext.Provider>
  );
}

export function TriggerMammonNotification({
  notification,
}: {
  notification: ExternalMammonNotification;
}) {
  const { displayNotification } = useMammonNotifications();
  useEffect(() => {
    displayNotification(notification);
  }, [displayNotification, notification]);
  return null;
}

export function MammonNotificationListItem({
  notification,
}: MammonNotificationProps) {
  const { theme } = useThemeUI();
  const { dismissNotification } = useMammonNotifications();
  const { type, message, dismissable = false } = notification;
  const handleClose = useCallback(() => {
    dismissNotification(notification);
  }, [notification, dismissNotification]);
  const variantByType = {
    [MammonNotificationType.Error]: "elements.errorNotification",
    [MammonNotificationType.Info]: "elements.notification",
  };
  return (
    <MoveFocusInside>
      <div
        sx={{
          variant: variantByType[type],
          display: "flex",
          alignItems: "center",
          padding: 16,
        }}
      >
        <div sx={{ flex: "1 1 auto" }}>
          <span sx={{ variant: "text.notification" }}>{message}</span>
        </div>
        {dismissable && (
          <div
            sx={{ display: "inline-flex", flex: "0 0 auto", marginLeft: 16 }}
          >
            <CloseButton
              iconColor={theme.colors?.white as string}
              onClick={handleClose}
            />
          </div>
        )}
      </div>
    </MoveFocusInside>
  );
}

export function MammonNotificationList({
  containerSx = defaultContainerSx,
}: {
  containerSx?: SxStyleProp;
}) {
  const { notifications } = useMammonNotifications();
  return (
    <MammonAnimateListPresence
      list={notifications}
      renderListItem={notification => (
        <div sx={{ padding: 6 }}>
          <div sx={{ pointerEvents: "auto" }}>
            <MammonNotificationListItem notification={notification} />
          </div>
        </div>
      )}
      getItemKey={notification => notification.key}
      xFromDirection={MammonAnimateListPresenceXDirection.NONE}
      yFromDirection={MammonAnimateListPresenceYDirection.FROM_TOP}
      wrapListItems={children => (
        <div sx={{ display: "flex", flexDirection: "column" }}>
          <div sx={{ margin: -6, pointerEvents: "none" }}>{children}</div>
        </div>
      )}
      listSx={containerSx}
    />
  );
}

export const defaultContainerSx = {
  position: "fixed",
  top: 16,
  right: 16,
  width: "calc(100% - 32px)",
  maxWidth: 340,
  zIndex: "notifications",
} as SxStyleProp;
