import React, { ReactNode } from "react";
import { Flex, Link, Text } from "theme-ui";
import { Stack } from "../Stack/Stack";
import { InlineLink } from "../InlineLink/InlineLink";

export function MammonPaymentRequestNextStepsCardContent({
  bodyText,
}: {
  bodyText: ReactNode;
}) {
  return (
    <Stack gap={32}>
      <Stack gap={24}>
        <Stack gap={8}>
          <Flex>
            <Text variant="body.subPageTitle">What’s next?</Text>
          </Flex>
          <Flex>
            <Text variant="body.paragraph">{bodyText}</Text>
          </Flex>
        </Stack>
        <Flex>
          <Link
            variant="text.body.subSectionTitle"
            color="green"
            href="https://drive.google.com/file/d/1B2nzCtlRr2XehqpJ0BZGDUekp9DRtUng/view"
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            Payment receipt example
          </Link>
        </Flex>
      </Stack>
      <Stack gap={8}>
        <Flex>
          <Text variant="body.sectionTitle">
            Check out our frequently asked questions
          </Text>
        </Flex>
        <Flex>
          <Text variant="body.paragraph">
            <>
              If you want to learn more about how Earnnest works you can find
              helpful articles in our{" "}
            </>
            <InlineLink
              href="https://earnnest.com/resources/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              frequently asked questions
            </InlineLink>
            .
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
}
