import { Location, LocationDescriptorObject } from "history";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export function createLocationWithPersistedSearchParams(
  currLocation: Location,
  nextLocation: LocationDescriptorObject,
  searchParamKeysToKeep: string[],
): LocationDescriptorObject {
  const { search: currSearch } = currLocation;
  const { search: nextSearch } = nextLocation;
  const currSearchParams = new URLSearchParams(currSearch);
  const nextSearchParams = new URLSearchParams(nextSearch);
  searchParamKeysToKeep.forEach(spKey => {
    const spValue = nextSearchParams.has(spKey)
      ? nextSearchParams.get(spKey)
      : currSearchParams.get(spKey);
    if (spValue) {
      nextSearchParams.set(spKey, spValue);
    }
  });
  const newNextSearch = nextSearchParams.toString();
  return { ...nextLocation, search: newNextSearch };
}

export function useLocationCreatorWithPersistedSearchParams() {
  const currLocation = useLocation();
  const createLocation = useCallback(
    (
      nextLocation: LocationDescriptorObject,
      searchParamKeysToKeep: string[],
    ) => {
      return createLocationWithPersistedSearchParams(
        currLocation,
        nextLocation,
        searchParamKeysToKeep,
      );
    },
    [currLocation],
  );
  return createLocation;
}
