/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconCaretRight({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27441 21.6773C8.07477 21.8574 7.76842 21.8474 7.58098 21.6546L5.36734 19.3778C5.16766 19.1724 5.18101 18.8416 5.39658 18.653L12.57 12.3763C12.7976 12.1771 12.7976 11.8229 12.57 11.6237L5.39658 5.34701C5.18101 5.15838 5.16766 4.82755 5.36734 4.62218L7.58098 2.34538C7.76842 2.15258 8.07477 2.14256 8.27441 2.32269L18.5886 11.6288C18.8086 11.8273 18.8086 12.1727 18.5886 12.3712L8.27441 21.6773Z"
        sx={{ fill: color }}
      />
    </IconSvg>
  );
}
