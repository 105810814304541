import React, { ReactNode } from "react";
import { Box, Flex, Text } from "theme-ui";
import { Stack } from "../Stack/Stack";
import { IconCaretRight } from "../IconCaretRight";
import { IconSize } from "../Icon";

export type MammonProfileListSlatProps = {
  heading: ReactNode;
  paragraph: ReactNode;
};

export function MammonProfileListSlat({
  heading,
  paragraph,
}: MammonProfileListSlatProps) {
  return (
    <Box py={4 * 3}>
      <Flex sx={{ alignItems: "center" }}>
        <Flex sx={{ flex: "1 1 auto" }}>
          <Stack gap={4} sx={{ textAlign: "left" }}>
            <Box>
              <Text variant="slat.heading">{heading}</Text>
            </Box>
            <Box>
              <Text variant="slat.paragraph">{paragraph}</Text>
            </Box>
          </Stack>
        </Flex>
        <Flex sx={{ flex: "0 0 auto", marginLeft: 8 }}>
          <Box>
            <IconCaretRight size={IconSize.SM} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
