import {
  fetchCurrentUser,
  updateUser,
} from "@earnnest/earnnest-ui-web-library/src/api";
import {
  RequireAuth,
  useAuthenticatedApiEndpoint,
} from "@earnnest/earnnest-ui-web-library/src/Auth/Auth";
import {
  EarnnestServerError,
  EarnnestServerErrorType,
} from "@earnnest/earnnest-ui-web-library/src/errors";
import { useDispatchOnlyIfMounted } from "@earnnest/earnnest-ui-web-library/src/useDispatchOnlyIfMounted";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useErrorHandler } from "react-error-boundary";

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: string[];
  insertedAt: string;
};

export type UserMeta = {};

type ApiOnLoadState = { loading: boolean; data: any | null; error: Error };
type ApiOnLoadAction =
  | { type: "successful_load"; payload: any }
  | { type: "failed_load"; payload: any }
  | { type: "update"; payload: any };

type UserUpdaterFn = (user: User) => User;
type UserContextType = {
  user?: User | null;
  userMeta?: UserMeta | null;
  userLoadError?: Error | null;
  updateCurrentUser: (changes: Partial<User>) => Promise<User>;
};
// Allows us to mark a user context type as required, when we are under a
// RequireUser component tree
export type RequiredUserContext = UserContextType & {
  user: User;
};

const UserContext = React.createContext<UserContextType>({
  user: null,
  userMeta: null,
  userLoadError: null,
  updateCurrentUser: async (changes: Partial<User>) => {
    return {} as User;
  },
});

export const useUser = () => useContext(UserContext);

// TODO: We should read/write the user into a local database
export function LoadUser({ children }: { children: ReactNode }) {
  const updateUserWithAuth = useAuthenticatedApiEndpoint(updateUser);
  const fetchCurrentUserWithAuth = useAuthenticatedApiEndpoint(
    fetchCurrentUser,
  );
  const handleFetchUserOnLoad = useCallback(async () => {
    const { user, meta } = await fetchCurrentUserWithAuth();
    return { user, userMeta: meta };
  }, [fetchCurrentUserWithAuth]);

  const [state, dispatch] = useReducer(
    (state: ApiOnLoadState, action: ApiOnLoadAction) => {
      switch (action.type) {
        case "successful_load":
          return { ...state, data: action.payload, loading: false };
        case "failed_load":
          return { ...state, error: action.payload, loading: false };
        case "update":
          return { ...state, data: { ...state.data, user: action.payload } };
        default:
          throw new Error();
      }
    },
    { loading: true, data: null, error: null },
  );
  const dispatchOnlyIfMounted = useDispatchOnlyIfMounted(dispatch);

  // Propagate the error to the nearest error boundary
  useErrorHandler(state.error);

  useEffect(
    () => {
      (async () => {
        try {
          const response = await handleFetchUserOnLoad();
          dispatchOnlyIfMounted({ type: "successful_load", payload: response });
        } catch (error) {
          let userFetchError = error;
          if (error instanceof EarnnestServerError) {
            userFetchError = new EarnnestServerError(
              error.message,
              error.request,
              error.response,
              EarnnestServerErrorType.UserFetchError,
            );
          }
          dispatchOnlyIfMounted({
            type: "failed_load",
            payload: userFetchError,
          });
        }
      })();
    },
    // Only run on load.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const user = state.data ? state.data.user : null;
  const userMeta = state.data ? state.data.userMeta : null;

  const updateCurrentUser = useCallback(
    async (changes: Partial<User>, opts = { optimistic: false }) => {
      if (!user) {
        throw new Error("Current user not loaded");
      }

      if (opts.optimistic) {
        const newUser = { ...user, ...changes };
        dispatch({ type: "update", payload: newUser });
      }

      const { user: updatedUser } = await updateUserWithAuth(user.id, changes);
      dispatch({ type: "update", payload: updatedUser });

      return updatedUser;
    },
    [user, updateUserWithAuth],
  );

  const context = useMemo(() => {
    return { user, userMeta, userLoadError: state.error, updateCurrentUser };
  }, [user, userMeta, state.error, updateCurrentUser]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

export function RequireUser({ children }: { children: ReactNode }) {
  const { user } = useUser();
  if (!user) {
    return null;
  }
  return <>{children}</>;
}

export function LoadAndRequireUser({ children }: { children: ReactNode }) {
  return (
    <LoadUser>
      <RequireUser>{children}</RequireUser>
    </LoadUser>
  );
}

export function RequireAuthAndUser({ children }: { children: ReactNode }) {
  return (
    <RequireAuth>
      <LoadAndRequireUser>{children}</LoadAndRequireUser>
    </RequireAuth>
  );
}
