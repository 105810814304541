/** @jsx jsx */
import { jsx } from "theme-ui";
import { PropsWithoutRef } from "react";
import { IconClose } from "./IconClose";
import { IconSize } from "./Icon";

interface CloseButtonProps
  extends PropsWithoutRef<JSX.IntrinsicElements["button"]> {
  containerSize?: string | number;
  iconSize?: string | number;
  iconColor?: string;
}

export function CloseButton({
  containerSize = 24,
  iconSize = 24,
  iconColor = "currentColor",
  ...restProps
}: CloseButtonProps) {
  return (
    <button
      sx={{
        variant: "elements.plainButton",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: containerSize,
        height: containerSize,
        cursor: "pointer",
        outline: "none",
        borderRadius: 3,
        transition: "background .15s ease-out",
        "&:focus": {
          background: "rgba(0, 0, 0, .25)",
        },
      }}
      {...restProps}
    >
      <IconClose size={IconSize.MD} color={iconColor} />
    </button>
  );
}
