import React from "react";
import { Box, Button, ButtonProps, Text } from "theme-ui";

export type MammonButtonActionTextProps = ButtonProps & {
  color?: string;
};

export function MammonButtonActionText({
  color = "green",
  children,
  ...restProps
}: MammonButtonActionTextProps) {
  // We do this in order to not pick up any of the default Theme UI
  // button styles
  const BoxAsButton = (Box as unknown) as typeof Button;
  return (
    <BoxAsButton
      as="button"
      type="button"
      variant={`buttons.actionText.${color}`}
      {...restProps}
    >
      <Text
        className="text"
        variant="buttons.small"
        color={color}
        sx={{
          lineHeight: 20 / 14,
          transition: "border .2s ease-out",
          border: "0 solid transparent",
          borderTopWidth: 1,
          borderBottomWidth: 1,
        }}
      >
        {children}
      </Text>
    </BoxAsButton>
  );
}
