import React, { ReactNode } from "react";
import { Flex, Text } from "theme-ui";
import { MammonButton, MammonButtonProps } from "../MammonButton/MammonButton";
import { Stack } from "../Stack/Stack";

export type MammonDescriptiveActionButtonProps = MammonButtonProps & {
  heading?: ReactNode;
  description?: ReactNode;
};

export function MammonDescriptiveActionButton({
  heading,
  description,
  ...restProps
}: MammonDescriptiveActionButtonProps) {
  return (
    <Stack gap={4 * 8}>
      {(heading || description) && (
        <Stack gap={4 * 2}>
          {heading && (
            <Flex>
              <Text variant="body.subSectionTitle">{heading}</Text>
            </Flex>
          )}
          {description && (
            <Flex>
              <Text variant="body.paragraph">{description}</Text>
            </Flex>
          )}
        </Stack>
      )}
      <Flex>
        <MammonButton
          size="medium"
          fillMode="hollow"
          color="dark"
          {...restProps}
        />
      </Flex>
    </Stack>
  );
}
