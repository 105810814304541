import React, { useMemo } from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { MaskedInput } from "./MaskedInput";

const USDollarInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const mask = useMemo(
    () =>
      createNumberMask({
        prefix: "$",
        suffix: "",
      }),
    [],
  );
  return <MaskedInput mask={mask} ref={ref as any} {...props} />;
});

export { USDollarInput };
