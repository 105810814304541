import React, { ReactNode } from "react";
import { Flex, useThemeUI } from "theme-ui";
import {
  DefaultMammonGenericDialogContent,
  MammonGenericDialog,
  MammonGenericDialogContentProps,
  MammonGenericDialogProps,
} from "../MammonGenericDialog";

export type MammonOverlayProps = MammonGenericDialogProps;

const DEFAULT_SX = {};

export function MammonOverlay({
  children,
  sx = DEFAULT_SX,
  ...restProps
}: MammonOverlayProps) {
  return (
    <MammonGenericDialog sx={{ backgroundColor: "background" }} {...restProps}>
      {children}
    </MammonGenericDialog>
  );
}

export function DefaultMammonOverlayContent({
  children,
  sx = DEFAULT_SX,
  ...restProps
}: MammonGenericDialogContentProps) {
  const { theme } = useThemeUI();
  return (
    <DefaultMammonGenericDialogContent
      sx={{ width: "100vw", ...DEFAULT_SX }}
      {...restProps}
    >
      <Flex
        m="auto"
        p={24}
        sx={{
          ...theme.elements.fullScreenHeight,
          maxWidth: 480,
          width: "100vw",
          flexDirection: "column",
        }}
      >
        {children}
      </Flex>
    </DefaultMammonGenericDialogContent>
  );
}

export type SplitContentAndActionOverlayContentProps = MammonGenericDialogContentProps & {
  content: ReactNode;
  action: ReactNode;
};

export function SplitContentAndActionOverlayContent({
  content,
  action,
  ...restProps
}: SplitContentAndActionOverlayContentProps) {
  return (
    <DefaultMammonOverlayContent {...restProps}>
      <Flex py={40} sx={{ flex: "1 0 auto", flexDirection: "column" }}>
        <Flex sx={{ flex: "1 0 auto", flexDirection: "column" }}>
          {content}
        </Flex>
        <Flex mt={72} sx={{ flex: "0 0 auto", flexDirection: "column" }}>
          {action}
        </Flex>
      </Flex>
    </DefaultMammonOverlayContent>
  );
}
