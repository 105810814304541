import React from "react";
import { Flex, Text } from "theme-ui";
import { MammonFieldProps } from "./MammonField";

export function MammonFieldCheckbox({
  isErrored,
  assist,
  children,
  disabled = false,
}: Pick<MammonFieldProps, "isErrored" | "assist" | "children" | "disabled">) {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex>{children}</Flex>
      <Flex pl={40} py={4}>
        <Text
          variant="form.inputAssist"
          color={isErrored ? "error" : "grey1"}
          sx={{ transition: "color .15s ease-out" }}
        >
          {isErrored ? <>{assist}</> : <>&nbsp;</>}
        </Text>
      </Flex>
    </Flex>
  );
}
