import React, { useCallback } from "react";
import { MaskedInput } from "./MaskedInput";

export const US_ZIP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

// prettier-ignore
export const US_ZIP_4_MASK = [
  /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/
];

const USZip4Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const mask = useCallback(rawValue => {
    const effectiveRawValue = rawValue.replace(/\D/g, "");
    if (effectiveRawValue.length > 5) {
      return US_ZIP_4_MASK;
    }
    return US_ZIP_MASK;
  }, []);
  return <MaskedInput guide={false} mask={mask} ref={ref as any} {...props} />;
});

export { USZip4Input };
