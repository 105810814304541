import { AgentRole } from "@earnnest/earnnest-ui-web-library/src/common";
import {
  MammonButton,
  MammonButtonState,
} from "@earnnest/earnnest-ui-web-library/src/MammonButton/MammonButton";
import { MammonInputCheckbox } from "@earnnest/earnnest-ui-web-library/src/MammonForm/MammonInputCheckbox";
import { SplitContentAndActionOverlayContent } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import { Stack } from "@earnnest/earnnest-ui-web-library/src/Stack/Stack";
import React, { useState } from "react";
import { Box, Flex, Text } from "theme-ui";

export type RoleSelectionOverlayContentProps = {
  initialRole: AgentRole | null;
  onContinue: (role: AgentRole) => void;
};

export function RoleSelectionOverlayContent({
  initialRole,
  onContinue,
}: RoleSelectionOverlayContentProps) {
  const [role, setRole] = useState(initialRole);
  return (
    <SplitContentAndActionOverlayContent
      autoFocus={false}
      content={
        <Stack gap={4 * 8}>
          <Stack gap={8}>
            <Flex>
              <Text variant="body.pageTitle">Real quick...</Text>
            </Flex>
            <Flex>
              <Text variant="body.paragraph">
                Do you have the email address of the person who is paying?
              </Text>
            </Flex>
          </Stack>
          <Box bg="white" sx={{ height: 1 }} />
          <Stack gap={24}>
            <Flex>
              <MammonInputCheckbox
                size="sm"
                checked={role === AgentRole.Requestor}
                onCheck={() => setRole(AgentRole.Requestor)}
                label={<>Yes, I have their email</>}
                subtext={
                  <>
                    You can add it in the next step and complete the payment
                    request
                  </>
                }
              />
            </Flex>
            <Flex>
              <MammonInputCheckbox
                size="sm"
                checked={role === AgentRole.Creator}
                onCheck={() => setRole(AgentRole.Creator)}
                label={<>No, I don’t have their email</>}
                subtext={
                  <>
                    You can invite the person that does have the payer’s
                    information (often the buyer’s agent) to complete the
                    payment request.
                  </>
                }
              />
            </Flex>
          </Stack>
        </Stack>
      }
      action={
        <Flex sx={{ justifyContent: "center" }}>
          <MammonButton
            state={role ? undefined : MammonButtonState.Disabled}
            disabled={!role}
            onClick={() => onContinue(role as AgentRole)}
          >
            Continue
          </MammonButton>
        </Flex>
      }
    />
  );
}
