import { ConfigSource, ConfigSourceMode } from "./Config";
import camelCase from "lodash/camelCase";

export class ProcessDotEnvConfigSource implements ConfigSource {
  mode = ConfigSourceMode.ReadOnly;
  getConfig() {
    const entries = Object.entries(process.env);
    const config = entries.reduce((configObj, entry) => {
      const [key, value] = entry;
      const keyWithoutReactApp = key.replace(/REACT_APP_/, "");
      const keyCasedProperly = camelCase(keyWithoutReactApp);
      return { ...configObj, [keyCasedProperly]: value };
    }, {});
    return config;
  }
  setConfig() {
    throw new Error("Incompatable method for process.env config source");
  }
}
