import { Flex, FlexProps, useThemeUI } from "theme-ui";
import React from "react";

type InlineProps = {
  gap?: string | number;
};

const DEFAULT_SX = {};

export function InlineContainer({
  gap = 4,
  sx = DEFAULT_SX,
  ...props
}: InlineProps & FlexProps) {
  const { theme } = useThemeUI();
  const effectiveGap = (theme.space as any)[gap] || gap;
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        margin: effectiveGap / -2,
        "> *": {
          padding: effectiveGap / 2,
        },
        ...sx,
      }}
    />
  );
}

export function InlineChild({ sx = DEFAULT_SX, ...restProps }: FlexProps) {
  return <Flex {...restProps} sx={{ flexDirection: "column", ...sx }} />;
}
