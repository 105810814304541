import { useCallback, useMemo, useReducer, useContext, ReactNode } from "react";
import React from "react";
import { MammonField, MammonFieldProps } from "./MammonField";
import { MammonInput, MammonInputProps } from "./MammonInput";
import { InputProps } from "theme-ui";
import { useUniqueId } from "../useUniqueId";
import {
  MammonInputCheckboxProps,
  MammonInputCheckbox,
} from "./MammonInputCheckbox";
import { Optional, ProcessingStates } from "../common";
import { MammonFieldCheckbox } from "./MammonFieldCheckbox";

export type MammonFormProps<T> = {
  initialFocus?: keyof T;
  initialValues: T;
  initialDirty?: Partial<{ [K in keyof T]: boolean }>;
  sanitize?: (values: T) => T;
  validate?: (values: T) => Partial<{ [K in keyof T]: string }>;
  submit: (values: T) => Promise<void>;
};

export type MammonInputHandlers = {
  onBlur: () => any;
  onChange: (e: any) => any;
  onFocus: () => any;
};

export type MammonFieldMetadata = {
  error: any;
  isErrored: boolean;
  hasFocus: boolean;
  hasValue: boolean;
};

const submitNoop = async () => {
  await Promise.resolve(null);
};
function validateNoop<T>(_: T) {
  return {} as { [key in keyof T]: string };
}
function sanitizeNoop<T>(values: T) {
  return values;
}

export function useMammonFormBuilder<T>({
  initialFocus,
  initialValues,
  initialDirty = {},
  sanitize = sanitizeNoop,
  validate = validateNoop,
  submit = submitNoop,
}: MammonFormProps<T>) {
  const [state, dispatch] = useReducer(
    (prevState: any, action: any) => {
      if (action.type === "CHANGE_INPUT") {
        const { key, value } = action.payload;
        const values = { ...prevState.values, [key]: value };
        const errors = validate(values);
        return {
          ...prevState,
          errors,
          values,
          modified: { ...prevState.modified, [key]: true },
        };
      }
      if (action.type === "SET_VALUES") {
        const [updaterFn, modifiedKeys] = action.payload;
        const newValues = updaterFn(prevState.values);
        let newModified = { ...prevState.modified };
        modifiedKeys.forEach((modifiedKey: string) => {
          newModified = { ...newModified, [modifiedKey]: true };
        });
        const errors = validate(newValues);
        return {
          ...prevState,
          errors,
          values: newValues,
          modified: newModified,
        };
      }
      if (action.type === "FOCUS_INPUT") {
        return { ...prevState, focus: action.payload };
      }
      if (action.type === "BLUR_INPUT") {
        return {
          ...prevState,
          visited: { ...prevState.visited, [action.payload]: true },
          dirty: {
            ...prevState.dirty,
            [action.payload]:
              prevState.dirty[action.payload] ||
              prevState.modified[action.payload],
          },
          focus: null,
        };
      }
      if (action.type === "SUBMITTING") {
        return { ...prevState, submissionState: ProcessingStates.Processing };
      }
      if (action.type === "SUBMISSION_INLINE_ERROR") {
        return {
          ...prevState,
          submissionState: ProcessingStates.Dirty,
          focus: action.payload,
        };
      }
      if (action.type === "SUCCESS_SUBMITTING_TRANSACTION") {
        return { ...prevState, submissionState: ProcessingStates.Processed };
      }
      if (action.type === "FAILURE_SUBMITTING_TRANSACTION") {
        return {
          ...prevState,
          submissionState: ProcessingStates.Error,
          submissionError: action.payload,
        };
      }
      return prevState;
    },
    {
      focus: initialFocus,
      submissionState: ProcessingStates.Idle,
      submissionError: null,
      modified: {},
      visited: {},
      dirty: initialDirty,
      values: sanitize(initialValues),
      errors: {},
    },
    initialState => {
      const errors = validate(initialState.values);
      return { ...initialState, errors };
    },
  );

  const submissionAttempted = useMemo(() => {
    return state.submissionState !== ProcessingStates.Idle;
  }, [state.submissionState]);

  const errorKeys = useMemo(() => {
    return Object.keys(state.errors);
  }, [state.errors]);

  const shouldSubmissionButtonBeDisabled = useMemo(() => {
    return (
      state.submissionState === ProcessingStates.Processing ||
      (state.submissionState === ProcessingStates.Dirty &&
        errorKeys.length > 0) ||
      Object.entries(state.dirty).some(([key, value]) => {
        if (value) {
          return errorKeys.includes(key);
        }
        return false;
      }) ||
      (state.submissionState === ProcessingStates.Dirty && errorKeys.length > 0)
    );
  }, [errorKeys, state.dirty, state.submissionState]);

  const setValues = useCallback(
    (updaterFn: (v: T) => T, modifiedKeys: string[]) => {
      dispatch({ type: "SET_VALUES", payload: [updaterFn, modifiedKeys] });
    },
    [],
  );

  const handleChange = useCallback((key, e) => {
    const value = e?.target?.value ?? e;
    dispatch({ type: "CHANGE_INPUT", payload: { key, value } });
  }, []);

  const handleFocus = useCallback(key => {
    dispatch({ type: "FOCUS_INPUT", payload: key });
  }, []);

  const handleBlur = useCallback(key => {
    dispatch({ type: "BLUR_INPUT", payload: key });
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      // Return early if any inline errors
      if (errorKeys.length > 0) {
        dispatch({
          type: "SUBMISSION_INLINE_ERROR",
          payload: errorKeys[0],
        });
        return;
      }

      try {
        dispatch({ type: "SUBMITTING" });
        await submit(state.values);
        dispatch({
          type: "SUCCESS_SUBMITTING_TRANSACTION",
        });
      } catch (error) {
        dispatch({
          type: "FAILURE_SUBMITTING_TRANSACTION",
          payload: error,
        });
      }
    },
    [errorKeys, state.values, submit],
  );

  const form = useMemo(() => {
    return {
      ...state,
      submit,
      initialValues,
      initialDirty,
      validate,
      setValues,
      handleChange,
      handleFocus,
      handleBlur,
      handleSubmit,
      errorKeys,
      submissionAttempted,
      shouldSubmissionButtonBeDisabled,
    };
  }, [
    errorKeys,
    setValues,
    handleBlur,
    handleChange,
    handleFocus,
    handleSubmit,
    initialDirty,
    initialValues,
    submissionAttempted,
    shouldSubmissionButtonBeDisabled,
    state,
    submit,
    validate,
  ]);

  return form;
}

const MammonFormContext = React.createContext<any>({});

export const useMammonForm = () => useContext(MammonFormContext);

export const useMammonField = <T extends unknown = string>(key: string) => {
  const {
    handleBlur,
    handleChange,
    handleFocus,
    values,
    dirty,
    errors,
    focus,
    submissionAttempted,
  } = useMammonForm();

  const value = useMemo(() => {
    return values[key];
  }, [key, values]);

  // Only changes when the key changes, since our parent handlers
  // are memoized
  //
  // NOTE: This is unsafe to batch memoize with other dependencies since
  // the callbacks return new functions
  const inputHandlers = useMemo(() => {
    return {
      onBlur: () => handleBlur(key),
      onChange: (e: any) => handleChange(key, e),
      onFocus: () => handleFocus(key),
    };
  }, [key, handleBlur, handleChange, handleFocus]);

  // This is safe to batch memoize since all of the returned values are
  // primitives
  const fieldMetadata = useMemo(() => {
    const isErrored =
      (dirty[key] || submissionAttempted) && Boolean(errors[key]);
    return {
      error: errors[key],
      isErrored,
      hasFocus: focus === key,
      hasValue: Boolean(values[key]),
    };
  }, [key, dirty, errors, focus, values, submissionAttempted]);

  return [value, inputHandlers, fieldMetadata] as [
    T,
    MammonInputHandlers,
    MammonFieldMetadata,
  ];
};

export function MammonForm<T>({
  children,
  ...restProps
}: { children: ReactNode } & MammonFormProps<T>) {
  const form = useMammonFormBuilder<T>(restProps);
  return (
    <MammonFormContext.Provider value={form}>
      {typeof children === "function" ? children(form) : children}
    </MammonFormContext.Provider>
  );
}

// Allows for attaching an existing form object to our context
// tree. Useful for components who want to create the form at the top of
// their component with useMammonFormBuilder(), to get all the goodies of
// hooks in the function body, then just attach to context with this
// component
export function MammonProvideForm({
  form,
  children,
}: {
  form: any;
  children: ReactNode;
}) {
  return (
    <MammonFormContext.Provider value={form}>
      {children}
    </MammonFormContext.Provider>
  );
}

const PerformantMammonFieldAndInput = React.memo(function({
  label,
  isErrored,
  error,
  assist,
  hasValue,
  hasFocus,
  icon,
  value,
  inputId,
  boxType,
  disabled,
  readOnly,
  onChange,
  onFocus,
  onBlur,
  ...restProps
}: { error?: string } & Omit<MammonFieldProps, "children" | "inputBoxType"> &
  MammonInputProps &
  InputProps) {
  return (
    <MammonField
      label={label}
      assist={isErrored ? error : assist}
      hasValue={hasValue}
      hasFocus={hasFocus}
      isErrored={isErrored}
      disabled={disabled}
      readOnly={readOnly}
      icon={icon}
      inputId={inputId}
      inputBoxType={boxType}
    >
      <MammonInput
        id={inputId}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        hidePlaceholder={!hasFocus && Boolean(label)}
        hasFocus={hasFocus}
        disabled={disabled}
        readOnly={readOnly}
        isErrored={isErrored}
        hasIcon={Boolean(icon)}
        boxType={boxType}
        {...restProps}
      />
    </MammonField>
  );
});

export function ConnectedMammonFieldAndInput({
  fieldKey,
  label,
  assist,
  icon,
  ...restProps
}: { fieldKey: string; error?: string } & Omit<
  MammonFieldProps,
  "children" | "inputBoxType"
> &
  MammonInputProps &
  InputProps) {
  const [value, inputHandlers, fieldMetadata] = useMammonField(fieldKey);
  const generatedInputId = useUniqueId();
  return (
    <PerformantMammonFieldAndInput
      as="input"
      type="text"
      label={label}
      assist={fieldMetadata.isErrored ? fieldMetadata.error : assist}
      icon={icon}
      hasIcon={Boolean(icon)}
      value={value}
      inputId={generatedInputId}
      {...fieldMetadata}
      {...inputHandlers}
      {...restProps}
    />
  );
}

export function ConnectedMammonCheckboxFieldAndInput({
  fieldKey,
  onCheck = () => {},
  disabled,
  ...restProps
}: {
  fieldKey: string;
} & Optional<Omit<MammonInputCheckboxProps, "checked">, "onCheck">) {
  const [value, inputHandlers, fieldMetadata] = useMammonField<boolean>(
    fieldKey,
  );
  const { onBlur, onChange, onFocus } = inputHandlers;
  const { isErrored, error, hasFocus } = fieldMetadata;
  const handleCheck = useCallback(
    (checked: boolean) => {
      onChange(checked);
      onCheck(checked);
    },
    [onChange, onCheck],
  );
  return (
    <MammonFieldCheckbox
      isErrored={isErrored}
      assist={isErrored ? error : null}
      disabled={disabled}
    >
      <MammonInputCheckbox
        hasFocus={hasFocus}
        checked={value}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onCheck={handleCheck}
        {...restProps}
      />
    </MammonFieldCheckbox>
  );
}
