import { MammonOverlay } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import {
  ErrorScreen,
  ErrorScreenProps,
} from "@earnnest/earnnest-ui-web-library/src/TakeoverScreen/ErrorScreen";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { GetHelpOverlayContent } from "./GetHelpOverlayContent";

const GetHelpOverlayContext = React.createContext({
  isOpened: false,
  toggle: (show: boolean) => {},
});

export const useGetHelpOverlay = () => useContext(GetHelpOverlayContext);

export function GetHelpOverlayProvider({ children }: { children: ReactNode }) {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const toggle = useCallback((show: boolean) => {
    setShow(show);
  }, []);
  const context = useMemo(() => {
    return { isOpened: show, toggle };
  }, [show, toggle]);
  return (
    <GetHelpOverlayContext.Provider value={context}>
      <MammonOverlay onClose={handleClose}>
        {show && <GetHelpOverlayContent onClose={handleClose} />}
      </MammonOverlay>
      {children}
    </GetHelpOverlayContext.Provider>
  );
}

export function GetHelpErrorScreen(props: ErrorScreenProps) {
  const { toggle } = useGetHelpOverlay();
  const handleContactSupport = useCallback(() => {
    toggle(true);
  }, [toggle]);
  return <ErrorScreen onContactSupport={handleContactSupport} {...props} />;
}
