/** @jsx jsx */
import { jsx } from "theme-ui";
import { IconProps, IconSvg } from "./Icon";

export function IconSearch({ size, color = "purple1" }: IconProps) {
  return (
    <IconSvg size={size} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43523 11.5C6.43523 8.70281 8.70281 6.43523 11.5 6.43523C14.2972 6.43523 16.5648 8.70281 16.5648 11.5C16.5648 14.2972 14.2972 16.5648 11.5 16.5648C8.70281 16.5648 6.43523 14.2972 6.43523 11.5ZM11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20C13.3258 20 15.0171 19.4244 16.4024 18.4447C16.4092 18.4518 16.4161 18.4588 16.4231 18.4658L19.5342 21.5769C20.0983 22.141 21.0128 22.141 21.5769 21.5769C22.141 21.0128 22.141 20.0983 21.5769 19.5342L18.4658 16.4231C18.4588 16.4161 18.4518 16.4092 18.4447 16.4024C19.4244 15.0171 20 13.3258 20 11.5C20 6.80558 16.1944 3 11.5 3Z"
        sx={{ fill: color }}
      />
    </IconSvg>
  );
}
