import React from "react";
import {
  Button,
  ButtonProps,
  useThemeUI,
  LinkProps,
  Box,
  Flex,
} from "theme-ui";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import { ActivityIndicator } from "../ActivityIndicator/ActivityIndicator";
import { Optional } from "../common";
import { IconCheck } from "../IconCheck";
import { IconSize } from "../Icon";

export enum MammonButtonState {
  Initial,
  Disabled,
  Processing,
  Processed,
}
type Dictionary = { [key: string]: any };
export type MammonButtonProps = Optional<RouterLinkProps, "to"> &
  LinkProps &
  ButtonProps & {
    size?: "small" | "medium" | "large";
    fillMode?: "filled" | "hollow";
    color?: "dark" | "green" | "light";
    state?: MammonButtonState;
  };

const DEFAULT_SX = {};

export function MammonButton({
  size = "large",
  fillMode = "filled",
  color = "dark",
  state = MammonButtonState.Initial,
  sx = DEFAULT_SX,
  children,
  ...restProps
}: MammonButtonProps) {
  // We do this in order to not pick up any of the default Theme UI
  // button styles
  const BoxAsButton = (Box as unknown) as typeof Button;
  const { theme: themeUi } = useThemeUI();
  const activityIndicatorWidth = { small: 36, medium: 42, large: 48 };
  const iconCheckSize = {
    small: IconSize.SM,
    medium: IconSize.SM,
    large: IconSize.MD,
  };
  const iconCheckHeight = { small: 14, medium: 16, large: 20 };
  const sizeSx = (themeUi.buttons as Dictionary)[size];
  const colorSx = (themeUi.buttons as Dictionary)[color][fillMode];
  const stateSx = (themeUi.buttons as Dictionary)[state][fillMode];
  return (
    <BoxAsButton
      as="button"
      type="button"
      sx={{
        ...themeUi.buttons?.reset,
        ...themeUi.buttons?.base,
        ...sizeSx,
        ...colorSx,
        ...stateSx,
        ...sx,
      }}
      disabled={
        state === MammonButtonState.Disabled ||
        state === MammonButtonState.Processing ||
        state === MammonButtonState.Processed
      }
      {...restProps}
    >
      {state === MammonButtonState.Processed ? (
        <Flex
          sx={{
            height: iconCheckHeight[size],
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconCheck
            size={iconCheckSize[size]}
            color={stateSx.color || colorSx.color}
          />
        </Flex>
      ) : state === MammonButtonState.Processing ? (
        <ActivityIndicator
          width={activityIndicatorWidth[size]}
          color={stateSx.color || colorSx.color}
        />
      ) : (
        children
      )}
    </BoxAsButton>
  );
}
