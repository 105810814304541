import { useMemo } from "react";

type FeatureTestFunc = () => boolean;
type FeatureTests = {
  [k: string]: FeatureTestFunc;
};

export enum BrowserFeatureTest {
  AlwaysFail,
  ForOf,
}

const featureTests: FeatureTests = {
  [BrowserFeatureTest.AlwaysFail]: () => false,
  [BrowserFeatureTest.ForOf]: () => {
    try {
      // eslint-disable-next-line
      eval("for (var i of []);");
      return true;
    } catch (ex) {
      return false;
    }
  },
};

export function checkForBrowserFeatures(features: BrowserFeatureTest[]) {
  for (let i = 0; i < features.length; ++i) {
    const currentFeature = features[i];
    const featureTest = featureTests[currentFeature];
    const isUnsupported = !featureTest();
    if (isUnsupported) {
      return false;
    }
  }
  return true;
}

export function useCheckForSupportedBrowser(features: BrowserFeatureTest[]) {
  const allTestsPass = useMemo(() => {
    return checkForBrowserFeatures(features);
  }, [features]);

  return allTestsPass;
}
