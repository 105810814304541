/*
 * Legacy auth uses HTTPOnly cookies to transfer refresh tokens to a
 * home-rolled authentication server (see `earnnest-accounts` project), from
 * which the authentication server will determine if it can issue an
 * access token.
 */
import { useAuth0 } from "@auth0/auth0-react";
import { buildApiRequestSettingsFromConfigAndToken } from "@earnnest/earnnest-ui-web-library/src/apiHelpers";
import {
  Auth0Env,
  AuthModes,
  Tail,
  ThenArg,
} from "@earnnest/earnnest-ui-web-library/src/common";
import { useSearchParams } from "@earnnest/earnnest-ui-web-library/src/useSearchParams";
import React, { useCallback, useState } from "react";
import { RequiredConfigType, useRequiredConfig } from "../Config/Config";
import {
  createAuthAudienceFromTemplate,
  createAuthDomainFromTemplate,
  PROD_AUTH_DOMAIN,
  STAG_AUTH_DOMAIN,
  SANDBOX_AUTH_DOMAIN,
  SANDBOX_GATEWAY_ORIGIN,
  DEMO_GATEWAY_ORIGIN,
  DEVELOPMENT_GATEWAY_ORIGIN,
} from "../constants";
import {
  RequireLegacyAuth,
  SetupLegacyAuth,
  useLegacyAuth,
} from "./AuthLegacy";
import { RequireModernAuth, SetupModernAuth } from "./AuthModern";

export { AuthRedirectCallback } from "./AuthModern";

export function useAuthMode() {
  const sp = useSearchParams();
  const [mode] = useState<AuthModes>(() => {
    const modeType =
      "legacy" === sp.get("auth") ? AuthModes.Legacy : AuthModes.Modern;
    return modeType;
  });
  return mode;
}

export function RequireAuth(props: any) {
  const authMode = useAuthMode();
  const [RequireAuthComponent] = useState<any>(() => {
    const authComponents = {
      [AuthModes.Legacy]: RequireLegacyAuth,
      [AuthModes.Modern]: RequireModernAuth,
    };
    return authComponents[authMode];
  });
  return <RequireAuthComponent {...props} />;
}

export function useAuth() {
  const authMode = useAuthMode();
  const authHooks = {
    [AuthModes.Legacy]: useLegacyAuth(),
    [AuthModes.Modern]: useAuth0(),
  };
  return authHooks[authMode];
}

export function SetupAuth(props: any) {
  const authMode = useAuthMode();
  const [AuthComponent] = useState<any>(() => {
    const authComponents = {
      [AuthModes.Legacy]: SetupLegacyAuth,
      [AuthModes.Modern]: SetupModernAuth,
    };
    console.log(
      "[Auth] Determined auth mode to use [%s].",
      AuthModes[authMode],
    );
    return authComponents[authMode];
  });
  return <AuthComponent {...props} />;
}

export function useAuthenticatedApiEndpoint<T extends (...args: any) => any>(
  endpoint: T,
) {
  const { config } = useRequiredConfig();
  const authMode = useAuthMode();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth();
  const endpointWithAuth = useCallback(
    async (...params: Tail<Parameters<T>>) => {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently();
      } catch (error) {
        const errorType = error.error ?? "unknown";

        console.log(
          "[Auth] An error occurred trying to retrieve an access token silently [%s].",
          errorType,
        );
        console.error(error);

        // Check the error.error type. If it's 'login_required', send the user
        // to login again, by calling `loginWithRedirect()` with a toast.
        //
        // In the future, we could attempt to use `loginWithPopup()`,
        // followed by some kind of refresh mechanism, if we wanted to not
        // have the user leave their current UI state. Would need to do some
        // discovery on this.
        //
        // In the future, if it's `consent_required`, allow the user
        // to get consent through `getTokenWithPopup()`

        if (
          "login_required" === errorType ||
          "consent_required" === errorType ||
          "interaction_required" === errorType
        ) {
          await loginWithRedirect({ earnnest_error: errorType });
        } else {
          throw error;
        }
      }

      const requestSettings = buildApiRequestSettingsFromConfigAndToken(
        authMode,
        config,
        accessToken,
      );

      return (await endpoint(requestSettings, ...params)) as ThenArg<
        ReturnType<T>
      >;
    },
    [authMode, config, endpoint, getAccessTokenSilently, loginWithRedirect],
  );
  return endpointWithAuth;
}

export function isApiEnvAPullRequest(apiEnv: string) {
  return Boolean(!isNaN(parseInt(apiEnv, 10)));
}

export function buildAuthEnvFromApiEnv(apiEnv: string) {
  const apiEnvIsPr = isApiEnvAPullRequest(apiEnv);
  const authEnv =
    apiEnv === "prod"
      ? Auth0Env.Prod
      : apiEnv === "demo"
      ? Auth0Env.Demo
      : apiEnv === "sandbox"
      ? Auth0Env.Sandbox
      : apiEnv === "release" || apiEnv === "stag"
      ? Auth0Env.Stag
      : apiEnvIsPr
      ? Auth0Env.Preview
      : Auth0Env.Dev;
  return authEnv;
}

export function buildAuthSetupPropsFromConfig(config: RequiredConfigType) {
  const authEnv = buildAuthEnvFromApiEnv(config.apiEnv);
  const authDomain =
    authEnv === Auth0Env.Prod
      ? PROD_AUTH_DOMAIN
      : authEnv === Auth0Env.Stag
      ? STAG_AUTH_DOMAIN
      : authEnv === Auth0Env.Sandbox
      ? SANDBOX_AUTH_DOMAIN
      : createAuthDomainFromTemplate({ env: authEnv });
  const audienceSuffix =
    authEnv === Auth0Env.Prod
      ? ""
      : authEnv === Auth0Env.Stag
      ? "_staging"
      : `_${authEnv}`;
  const authAudience =
    authEnv === Auth0Env.Sandbox
      ? `${SANDBOX_GATEWAY_ORIGIN}/`
      : authEnv === Auth0Env.Demo
      ? `${DEMO_GATEWAY_ORIGIN}/`
      : authEnv === Auth0Env.Dev
      ? `${DEVELOPMENT_GATEWAY_ORIGIN}/`
      : createAuthAudienceFromTemplate({ suffix: audienceSuffix });
  return {
    domain: authDomain,
    audience: authAudience,
  };
}
