import { AppState } from "@auth0/auth0-react/dist/auth0-provider";
import {
  buildAuthSetupPropsFromConfig,
  SetupAuth,
} from "@earnnest/earnnest-ui-web-library/src/Auth/Auth";
import { useRequiredConfig } from "@earnnest/earnnest-ui-web-library/src/Config/Config";
import { DefaultGlobalStyles } from "@earnnest/earnnest-ui-web-library/src/DefaultGlobalStyles";
import { ErrorBoundaryWithGlobalErrorListener } from "@earnnest/earnnest-ui-web-library/src/GlobalErrorListener";
import {
  MammonNotificationList,
  SetupMammonNotifications,
} from "@earnnest/earnnest-ui-web-library/src/MammonNotifications/MammonNotifications";
import { BrowserFeatureTest } from "@earnnest/earnnest-ui-web-library/src/platformHelpers";
import { RequireBrowserFeatures } from "@earnnest/earnnest-ui-web-library/src/RequireBrowserFeatures";
import { theme } from "@earnnest/earnnest-ui-web-library/src/theme";
import { SetupTracking } from "@earnnest/earnnest-ui-web-library/src/Tracking";
import React, { ReactNode, Suspense, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import {
  GetHelpErrorScreen as ErrorScreen,
  GetHelpOverlayProvider,
} from "./GetHelpOverlayProvider";
import { GlobalStyles } from "./GlobalStyles";

export function SetupApp({ children }: { children: ReactNode }) {
  const origin = window.location.origin;
  const { config } = useRequiredConfig();
  const location = useLocation();
  const history = useHistory();

  const onRedirectCallback = useCallback(
    (appState: AppState) => {
      const returnTo = appState?.returnTo || location.pathname;
      history.replace(returnTo);
    },
    [history, location],
  );

  const handleGlobalError = useCallback((error: Error) => {
    // TODO:
    // Report to our error reporting service
    //
    // Extract out a common utility for understanding our error
    // versions and reporting appropriately
    console.log("TODO: Report error [%s]...", error.message);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet defaultTitle="Earnnest" titleTemplate="%s — Earnnest" />
      <DefaultGlobalStyles />
      <GlobalStyles />
      <SetupTracking>
        <Suspense
          fallback={
            // At the moment, until Suspense is properly supported, this fallback
            // is only invoked when hitting a lazy React component (code splitted
            // route).
            //
            // In the future, it could be hit for more than that when React
            // supports more features, like data fetching.
            //
            // It may be worthwhile to have a "load" design for when components
            // are lazy loaded, but ideally we prefetch the chunks to where this
            // isn't relevant.
            //
            // In addition, there should be a Suspense layer at each "branch"
            // as a *direct* parent of where code splitting can occur. That way,
            // the whole App tree is not detached while a component lower in
            // the stack is loading
            null
          }
        >
          <GetHelpOverlayProvider>
            <ErrorBoundaryWithGlobalErrorListener
              fallbackRender={({ error }) => {
                // Most of the error handling UI logic is within the ErrorScreen
                // itself. See that component for the different paths that
                // can occur based on the supplied error definition
                //
                // NOTE: If an error is triggered during the initialization
                // process of a <Component /> stack, and the initialization
                // process (useEffect()) doesn't clean up successfully, it
                // will result in a React state race condition error. This
                // happens in our NotFound trigger, for instance, from the Auth0
                // initialization not cleaning up.
                return <ErrorScreen error={error} />;
              }}
              onError={handleGlobalError}
            >
              <SetupAuth
                {...buildAuthSetupPropsFromConfig(config)}
                clientId={config.authClientIdForPortalApp}
                scope="openid"
                redirectUri={origin}
                onRedirectCallback={onRedirectCallback}
              >
                <SetupMammonNotifications>
                  <RequireBrowserFeatures features={[BrowserFeatureTest.ForOf]}>
                    {children}
                  </RequireBrowserFeatures>
                  {/* Fixed position div in top right of screen */}
                  <MammonNotificationList />
                </SetupMammonNotifications>
              </SetupAuth>
            </ErrorBoundaryWithGlobalErrorListener>
          </GetHelpOverlayProvider>
        </Suspense>
      </SetupTracking>
    </ThemeProvider>
  );
}
