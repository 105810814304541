import { transitions } from "polished";
import React, { ReactNode } from "react";
import { Flex, Label, Text } from "theme-ui";
import { MammonInputBoxType } from "./MammonInput";

export type MammonFieldProps = {
  label?: ReactNode;
  hasFocus?: boolean;
  hasValue?: boolean;
  isErrored?: boolean;
  assist?: ReactNode;
  icon?: ReactNode;
  inputId?: string | undefined;
  inputBoxType?: MammonInputBoxType;
  disabled?: boolean;
  readOnly?: boolean;
  children: ReactNode;
};

export function MammonField({
  hasFocus = false,
  isErrored = false,
  hasValue = false,
  label,
  assist = null,
  icon = null,
  inputId,
  inputBoxType = MammonInputBoxType.Underline,
  disabled = false,
  readOnly = false,
  children,
}: MammonFieldProps) {
  const isLabelInThePlaceholderPosition = !hasFocus && !hasValue;
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ flexDirection: "column" }}>
        <Flex paddingY={4} paddingX={8}>
          <Text variant="form.inputLabel">
            {label ? (
              <Label
                htmlFor={inputId}
                sx={{
                  color: isLabelInThePlaceholderPosition ? "grey3" : "grey1",
                  transformOrigin: "top left",
                  ...transitions(["transform", "color"], ".15s ease-out"),
                  ...getLabelTransformation({
                    inputBoxType,
                    isLabelInThePlaceholderPosition,
                  }),
                }}
              >
                {label}
              </Label>
            ) : (
              <Text>&nbsp;</Text>
            )}
          </Text>
        </Flex>
        <Flex sx={{ flexDirection: "column", position: "relative" }}>
          <Flex sx={{ flexDirection: "column" }}>{children}</Flex>
          {icon && (
            <Flex
              sx={{
                pointerEvents: "none",
                width: 24,
                height: 24,
                position: "absolute",
                right: 8,
                top: "50%",
                transform: "translateY(-50%)",
                justifyContent: "center",
                alignItems: "center",
                opacity: disabled && !readOnly ? 0.5 : undefined,
              }}
            >
              {icon}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex paddingY={4} paddingX={8}>
        <Text
          variant="form.inputAssist"
          color={isErrored ? "error" : "grey1"}
          sx={{ transition: "color .15s ease-out" }}
        >
          {assist ? <>{assist}</> : <>&nbsp;</>}
        </Text>
      </Flex>
    </Flex>
  );
}

function getLabelTransformation({
  inputBoxType,
  isLabelInThePlaceholderPosition,
}: Pick<MammonFieldProps, "inputBoxType"> & {
  isLabelInThePlaceholderPosition: boolean;
}) {
  if (isLabelInThePlaceholderPosition) {
    const x = inputBoxType === MammonInputBoxType.Outline ? 1 : 0;
    const yOffset = inputBoxType === MammonInputBoxType.Outline ? 1 : 0;
    const y = 12 * 1.2 + 4 + 8 + 2 + yOffset;
    return { transform: `translate(${x}px, ${y}px) scale(${16 / 12})` };
  }
  return { transform: "translate(0, 0) scale(1.0)" };
}
