import { useDispatchOnlyIfMounted } from "@earnnest/earnnest-ui-web-library/src/useDispatchOnlyIfMounted";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useEffectReducer } from "use-effect-reducer";
import { ProcessDotEnvConfigSource } from "./ProcessDotEnvConfigSource";

export const STORAGE_KEY = "earnnest__config";
export const SEARCH_PARAM_KEY = "earnnestConfig";

export type ConfigType = {
  apiEnv?: string;
  apiRouting?: string;
  apiSecret?: string;
  authClientIdForDesktopApp?: string;
  authClientIdForPortalApp?: string;
  legacyAuthApiOrigin?: string;
  segmentWriteKey?: string;
  portalListingAgents?: string;
};

export type RequiredConfigType = Required<ConfigType>;

export type ConfigUpdaterFn = (prevConfig: ConfigType) => ConfigType;

export type ConfigContextType = {
  loading: boolean;
  config?: ConfigType;
  setConfig: (updaterFn: ConfigUpdaterFn) => void;
};
export type RequiredConfigContextType = ConfigContextType & {
  config: RequiredConfigType;
};

export type SetupConfigProps = {
  children: ReactNode;
  sources?: ConfigSource[];
};

type ConfigReducerState = {
  loading: boolean;
  config?: ConfigType;
};

type ConfigReducerAction =
  | { type: "config_loaded"; payload: ConfigType }
  | { type: "invoke_updater_fn"; payload: ConfigUpdaterFn };

const DEFAULT_SET_CONFIG = (updaterFn: ConfigUpdaterFn) => {
  const prevConfig = {} as ConfigType;
  updaterFn(prevConfig);
};

const ConfigContext = React.createContext<ConfigContextType>({
  loading: true,
  config: undefined,
  setConfig: DEFAULT_SET_CONFIG,
});

export enum ConfigSourceMode {
  Writable,
  ReadOnly,
}

export interface ConfigSource {
  mode: ConfigSourceMode;
  getConfig: () => ConfigType;
  setConfig: (config: ConfigType) => void;
}

export const useConfig = () => useContext(ConfigContext);
export const useRequiredConfig = () => {
  const result = useConfig();
  if (result.loading) {
    throw new Error("Unable to get required config. It's not loaded yet");
  }
  return result as RequiredConfigContextType;
};

const DEFAULT_SOURCES: ConfigSource[] = [new ProcessDotEnvConfigSource()];

export function SetupConfig({
  children,
  sources = DEFAULT_SOURCES,
}: SetupConfigProps) {
  const [state, dispatch] = useEffectReducer(
    (state: ConfigReducerState, action: ConfigReducerAction, exec: any) => {
      switch (action.type) {
        case "config_loaded":
          // For some reason, TypeScript requires typing this with undefined
          // explicitly, even though the action below does not require it.
          // Not sure why this is needed in this case.
          const updatedConfig = action.payload as ConfigType | undefined;
          return {
            ...state,
            config: updatedConfig,
            loading: false,
          };
        case "invoke_updater_fn":
          const updaterFn = action.payload as ConfigUpdaterFn;
          const config = updaterFn(state.config as ConfigType);
          // When config changes, persist to our writable sources
          exec(() => {
            const writableSources = sources.filter(source => {
              return source.mode === ConfigSourceMode.Writable;
            });
            writableSources.forEach(source => {
              source.setConfig(config);
            });
          });
          return { ...state, config };
        default:
          throw new Error("[SetupConfig] Invalid action type");
      }
    },
    {
      loading: true,
      config: undefined,
    },
  );
  const dispatchOnlyIfMounted = useDispatchOnlyIfMounted(dispatch);

  const setConfig = useCallback(
    (updaterFn: ConfigUpdaterFn) => {
      dispatchOnlyIfMounted({ type: "invoke_updater_fn", payload: updaterFn });
    },
    [dispatchOnlyIfMounted],
  );

  const mergedConfig = useMemo(() => {
    const mergedConfig = sources.reduce((mergedConfig, source) => {
      const config = source.getConfig();
      return { ...mergedConfig, ...config };
    }, {});
    return mergedConfig;
  }, [sources]);

  useEffect(() => {
    dispatchOnlyIfMounted({ type: "config_loaded", payload: mergedConfig });
  }, [dispatchOnlyIfMounted, mergedConfig]);

  const context = useMemo(() => {
    return { loading: state.loading, config: state.config, setConfig };
  }, [setConfig, state.config, state.loading]);

  return (
    <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
  );
}

export function RequireConfig({ children }: { children: ReactNode }) {
  const { loading } = useConfig();

  if (loading) {
    return null;
  }

  return <>{children}</>;
}

export function SetupAndRequireConfig({
  children,
  ...props
}: SetupConfigProps) {
  return (
    <SetupConfig {...props}>
      <RequireConfig>{children}</RequireConfig>
    </SetupConfig>
  );
}
