import {
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";
import {
  Auth0Error,
  EarnnestClientAuthError,
} from "@earnnest/earnnest-ui-web-library/src/errors";
import { useSearchParams } from "@earnnest/earnnest-ui-web-library/src/useSearchParams";
import { Location as HLocation } from "history";
import React, {
  ComponentType,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  ApiGatewayChannel,
  ApiRoutingMode,
  buildApiRoutingFromConfig,
} from "../apiHelpers";
import { NamedApiEnv } from "../common";
import { useRequiredConfig } from "../Config/Config";

export const SSO_SEARCH_PARAM = "sso";

export type SetupAuthProps = Auth0ProviderOptions;

export type RequireAuthProps = {
  children: ReactNode;
};

const Auth0OptionsContext = React.createContext<any>({});

export const useAuth0Options = () => useContext(Auth0OptionsContext);

export function SetupModernAuth(props: SetupAuthProps) {
  const origin = window.location.origin;
  const { config } = useRequiredConfig();
  const location = useLocation();

  const auth0OptionsFromSso = useMemo(() => {
    let params = {};
    const sp = new URLSearchParams(location.search);
    if (!sp.has(SSO_SEARCH_PARAM)) {
      return params;
    }

    // sso=<param>
    //
    // The sso param is encoded first as base64, then URL encoded.
    // We have to do the reverse of that to get the original SSO params
    // as a query string "a=b&b=c&d=e".
    const encodedSso = sp.get(SSO_SEARCH_PARAM) as string;
    const decodedSso = atob(decodeURIComponent(encodedSso));

    // Finally, we pull out the query string into a URLSearchParams interface
    // for easy maneuverability. Then map them onto the params object.
    const ssoSearchParams = new URLSearchParams(decodedSso);
    for (let [key, value] of ssoSearchParams.entries()) {
      params = { ...params, [key]: value };
    }

    return {
      ...params,
      // Force sync means that even for non-interactive authentications (i.e.
      // silent auth), it will make sure to synchronize the Auth0 data with
      // Earnnest's system.
      //
      // This ensures that any updated data from an integration partner is
      // carried over to Earnnest, even if the user happens to already be
      // authenticated when accessing Earnnest from an integrator.
      earnnest_force_sync: "true",
    };
  }, [location.search]);

  // On non-production environments, allow for overriding the API origin
  // in the Auth0 authorization pipeline
  const auth0OptionsFromConfig = useMemo(() => {
    // Overrides not allowed on prod
    if (config.apiEnv === NamedApiEnv.Prod) {
      return {};
    }

    const { origin, subidentifier, routingMode } = buildApiRoutingFromConfig(
      ApiGatewayChannel.ClientCredentials,
      config,
    );

    let overrides = {};
    overrides = {
      ...overrides,
      earnnest_api_override: origin,
      ...(subidentifier ? { earnnest_api_sub_override: subidentifier } : {}),
    };

    if (routingMode === ApiRoutingMode.Direct) {
      overrides = {
        ...overrides,
        earnnest_api_gateway_secret: config.apiSecret,
      };
    }

    console.log("[Auth0] Derived overrides from environment.");
    console.log(overrides);
    return overrides;
  }, [config]);

  const auth0OptionsFromLocation = useMemo(() => {
    return {
      earnnest_initiated_url: origin + getTargetPathForAppState(location),
    };
  }, [origin, location]);

  const options = useMemo(() => {
    return {
      ...auth0OptionsFromConfig,
      ...props,
      ...auth0OptionsFromSso,
      ...auth0OptionsFromLocation,
    };
  }, [
    auth0OptionsFromConfig,
    auth0OptionsFromLocation,
    auth0OptionsFromSso,
    props,
  ]);

  return (
    <Auth0OptionsContext.Provider value={options}>
      <Auth0Provider {...options} />
    </Auth0OptionsContext.Provider>
  );
}

const defaultOnRedirecting = (): JSX.Element => <></>;
const defaultReturnTo = (): string => getTargetPathForAppState(window.location);

// This is virtually pulled from the auth0-react project, however, it adds
// support for pushing users to the login page, if `prompt=login` is
// supplied as an option to Auth0
const withPromptSupportedAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => (props: P): JSX.Element => {
  const { prompt } = useAuth0Options();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const {
    returnTo = defaultReturnTo,
    onRedirecting = defaultOnRedirecting,
    loginOptions = {},
  } = options;
  const isReallyAuthenticated = isAuthenticated && "login" !== prompt;

  // Note that if an error occurs initializing our Auth0 instance,
  // this effect will re-run without being authenticated, and while not
  // loading anymore, which will mean that it will redirect the user to the
  // auth0 login page.
  //
  // This is intended, but something to be aware of.
  //
  // TODO: Send any initialization errors to reporting service.
  useEffect(() => {
    if (isLoading || isReallyAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      ...(prompt ? { prompt } : {}),
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [
    isLoading,
    isReallyAuthenticated,
    loginWithRedirect,
    loginOptions,
    prompt,
    returnTo,
  ]);

  return isReallyAuthenticated ? <Component {...props} /> : onRedirecting();
};

export const RequireModernAuth = withPromptSupportedAuthenticationRequired(
  RequireModernAuthPlaceholder,
);

function RequireModernAuthPlaceholder({ children }: RequireAuthProps) {
  const { error } = useAuth0();

  // If an error leaks beyond the withPromptSupportedAuthenticationRequired()
  // handler, send it to our closest ErrorBoundary. Nearly all errors will be
  // handled through the withPromptSupportedAuthenticationRequired()
  // component directly, by issuing a loginWithRedirect().
  //
  // The only way this could be reached is if the user authenticed while also
  // getting an error in the process. So, weird state, but just in case...
  useAuthErrorHandler(error as Auth0Error);

  return <>{children}</>;
}

export function checkIsAuthRedirectCallback(searchParams: URLSearchParams) {
  return searchParams.has("code") || searchParams.has("error");
}

// Allows a component to be wrapped with logic to not render while
// handling a redirect callback
export function AuthRedirectCallback({ fallback }: { fallback: ReactNode }) {
  const { error } = useAuth0();
  const [, setState] = useState();
  const searchParams = useSearchParams();
  const isAuthRedirectCallback = checkIsAuthRedirectCallback(searchParams);

  // If we retrieve an error back from Auth0 in the capturing of the callback,
  // render our closest error boundary.
  //
  // In the ErrorBoundary, we should handle whatever meaningful error type
  // might have been thrown here.
  //
  // Note that the Auth0 handler doesn't always result in an error that
  // matches what the login page returned in our callback URL.
  //
  // At the very least, we should log what's in the URL. In the future, we
  // may want to consider creating a special Error class that can store
  // this information as well.
  //
  // Bottom line is that we don't want to lose the information passed back
  // from the originating login page, even if our auth transaction has gone
  // stale.
  //
  useEffect(() => {
    if (error) {
      const errorInUrl = searchParams.get("error");
      const errorDescriptionInUrl = searchParams.get("error_description");
      console.log(
        "[AuthModern] Received an error from Auth0 on redirect callback. Logging the URL errors separately.",
      );
      console.log(
        "[AuthModern] URL Error: [%s]; URL Error Description: [%s]",
        errorInUrl,
        errorDescriptionInUrl,
      );
      setState(() => {
        throw EarnnestClientAuthError.fromAuth0Error(error as Auth0Error);
      });
    }
  }, [error, searchParams]);

  return isAuthRedirectCallback ? null : <>{fallback}</>;
}

function getTargetPathForAppState(location: HLocation | Location) {
  const { pathname, search, hash } = location;
  const targetPath = pathname + removeSSOFromSearch(search) + hash;
  return targetPath;
}

function removeSSOFromSearch(inSearch: string) {
  const searchParams = new URLSearchParams(inSearch);
  searchParams.delete("sso");
  const spString = searchParams.toString();
  const newSearch = spString.length > 0 ? "?" + spString : "";
  return newSearch;
}

function useAuthErrorHandler(error?: Auth0Error) {
  if (error) {
    throw EarnnestClientAuthError.fromAuth0Error(error);
  }
}
