import {
  useCheckForSupportedBrowser,
  BrowserFeatureTest,
} from "./platformHelpers";
import React, { ReactNode } from "react";
import { EarnnestClientError, EarnnestClientErrorType } from "./errors";

function RequireBrowserFeatures({
  features,
  children,
}: {
  features: BrowserFeatureTest[];
  children: ReactNode;
}) {
  const isSupportedBrowser = useCheckForSupportedBrowser(features);
  if (!isSupportedBrowser) {
    throw new EarnnestClientError(
      "Unsupported browser",
      EarnnestClientErrorType.UnsupportedBrowser,
    );
  }
  return <>{children}</>;
}

export { RequireBrowserFeatures };
