import React, { useMemo } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link, LinkProps } from "theme-ui";
import { ActAsButton, ActAsButtonProps } from "../ActAsButton/ActAsButton";
import { Optional } from "../common";

export type InlineLinkProps = Optional<RouterLinkProps, "to"> &
  LinkProps &
  ActAsButtonProps;

export function InlineLink({ href, to, sx, ...restProps }: InlineLinkProps) {
  const linkSx = useMemo(() => {
    return {
      font: "inherit",
      margin: 0,
      padding: 0,
      textDecoration: "underline",
      userSelect: "none",
      cursor: "pointer",
    };
  }, []);
  if (href || to) {
    return (
      <Link
        color="green"
        {...(href ? { href } : {})}
        {...(to ? { to } : {})}
        as={href ? "a" : RouterLink}
        sx={(theme: any) => ({
          ...theme.buttons.actionText.green,
          ...linkSx,
        })}
        {...restProps}
      />
    );
  }
  return (
    <ActAsButton
      color="green"
      sx={(theme: any) => ({
        ...theme.buttons.actionText.green,
        ...linkSx,
      })}
      {...restProps}
    />
  );
}
