import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";
import kebabCase from "lodash/kebabCase";
import snakeCase from "lodash/snakeCase";
import { RequiredConfigType } from "./Config/Config";
import { AuthModes } from "./common";
import {
  DEMO_GATEWAY_ORIGIN,
  DEVELOPMENT_GATEWAY_ORIGIN,
  DEV_GATEWAY_ORIGIN,
  PROD_GATEWAY_ORIGIN,
  SANDBOX_GATEWAY_ORIGIN,
  UNITY_GATEWAY_ORIGIN,
  createDefaultApiOriginFromTemplate,
  createPreviewApiOriginFromTemplate,
} from "./constants";
import {
  EarnnestClientError,
  EarnnestClientErrorType,
  EarnnestServerError,
} from "./errors";
import { camelCaseKeysOnObjectRecursively } from "./objectHelpers";

export enum ApiAuthProvider {
  Auth0,
  LegacyEarnnest,
}

export enum ApiAuthMode {
  Token,
  Direct,
}

export enum ApiRoutingMode {
  Gateway,
  Direct,
}

export enum ApiGatewayChannel {
  Public, // /legacy(_staging)
  ClientCredentials, // /api_auth(_staging)
  UserCredentials, // /api(_staging)
}

export type ApiTokenAuth = {
  accessToken: string;
};

export type ApiDirectAuth = {
  userId: string;
  secret: string;
};

export type ApiRequestSettings = {
  authProvider: ApiAuthProvider;
  authMode: ApiAuthMode;
  authSettings: ApiDirectAuth | ApiTokenAuth;
  routingMode: ApiRoutingMode;
  gatewayChannel?: ApiGatewayChannel;
  origin: string;
  subidentifier?: string;
};

export enum JsonApiResourceTypes {
  User = "user",
  InboundRequest = "inbound_request",
}

export type ParsedResponse = {
  status: number;
  body: any;
  headers: Headers;
};

export type ApiErrorType = Error & {
  status: number;
  body: Object;
};

export function ApiError(
  this: ApiErrorType,
  message: string,
  status: number,
  json: Object,
) {
  var error = Error.call(this, message);

  this.name = "ApiError";
  this.message = error.message;
  this.stack = error.stack;
  this.status = status;
  this.body = json;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ApiError);
  }
}
ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;

export async function makeJsonApiRequest(href: string, options: any = {}) {
  const { headers, body: requestBody, ...restOptions } = options;
  const fetchRequest = new Request(href, {
    headers: {
      ...getJsonApiHeaders(),
      ...headers,
    },
    body: JSON.stringify(requestBody),
    ...restOptions,
  });
  const fetchResponse = await window.fetch(fetchRequest);
  const parsedResponse = await getParsedResponse(fetchResponse);
  if (parsedResponse.status < 200 || parsedResponse.status >= 300) {
    throw new EarnnestServerError(
      coerceErrorMessageFromParsedResponseBody(parsedResponse.body),
      fetchRequest,
      parsedResponse,
    );
  }
  const { body, ...restResponse } = parsedResponse;
  return { body: handleJsonApiResponse(body), ...restResponse };
}

async function getParsedResponse(response: Response): Promise<ParsedResponse> {
  let rawResponseBody = await response.text();
  try {
    rawResponseBody = JSON.parse(rawResponseBody);
  } catch (error) {
    console.log("[Api] Error converting response to JSON");
  }
  return {
    status: response.status,
    headers: response.headers,
    body: rawResponseBody,
  };
}

//
// Try to find a meaningful message in the error returned.
// We can make this cleaner once the API has error normalization.
//
// The error handling is very defensive right now, but that's simply
// the nature of the beast.
//
// TODO: Work with API to come up with a normalized API contract
//
export function coerceErrorMessageFromParsedResponseBody(body: any) {
  if (isString(body)) {
    return body;
  }

  if (
    body &&
    body.error &&
    body.error.body &&
    body.error.body.message &&
    isString(body.error.body.message)
  ) {
    return body.error.body.message;
  }

  if (body && body.message && isString(body.message)) {
    return body.message;
  }

  // Sometimes the message can be an array of errors, like when Dwolla
  // sends back a list of errors to our API
  if (
    body &&
    body.message &&
    isArray(body.message) &&
    body.message.length > 0 &&
    body.message[0].message
  ) {
    const [firstError] = body.message;
    const firstMessage = firstError.message;
    return firstMessage;
  }

  if (
    body &&
    body.errors &&
    isArray(body.errors) &&
    body.errors.length > 0 &&
    body.errors[0].detail &&
    isString(body.errors[0].detail)
  ) {
    return body.errors[0].detail;
  }

  if (
    body &&
    body.errors &&
    isArray(body.errors) &&
    body.errors.length > 0 &&
    body.errors[0].title &&
    isString(body.errors[0].title)
  ) {
    return body.errors[0].title;
  }

  return "An unknown error occurred";
}

export function getDefaultHeaders(requestSettings: ApiRequestSettings) {
  let headers = {};

  if (requestSettings.authMode === ApiAuthMode.Direct) {
    const { userId, secret } = requestSettings.authSettings as ApiDirectAuth;
    headers = { "x-user-id": userId, "x-gateway-secret": secret };
  }

  if (requestSettings.authMode === ApiAuthMode.Token) {
    const { accessToken } = requestSettings.authSettings as ApiTokenAuth;
    headers = {
      [ApiAuthProvider.LegacyEarnnest]: {
        "Legacy-Authorization": `Bearer ${accessToken}`,
      },
      [ApiAuthProvider.Auth0]: { authorization: `Bearer ${accessToken}` },
    }[requestSettings.authProvider];
  }

  if (requestSettings.subidentifier) {
    headers = { ...headers, "x-sub-id": requestSettings.subidentifier };
  }

  return headers;
}

export function getJsonApiHeaders() {
  return {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  };
}

//
// Serializes a JSON API request in a friendly way.
//
//  {
//    "type": "recordc",
//    "id": 1,
//    "firstName": "Steve",
//    "relateda": {
//      "type": "recorda",
//      "id": 1
//    },
//    "relatedb": [
//      {
//        "type": "recordb",
//        "id": 2
//      }
//    ]
//  }
//
export function serializeJsonApiRequest(data: any, opts: any = {}) {
  const { type, id, ...restData } = data;
  const { excludeAsRelationship = [] } = opts;

  const primitiveEntries = Object.entries(restData).filter(
    entry => !relationshipEntryFilter(excludeAsRelationship, entry),
  );
  const relationshipEntries = Object.entries(restData).filter(entry =>
    relationshipEntryFilter(excludeAsRelationship, entry),
  );

  let serializedData: any = { type, id };

  // Handle primitives
  if (primitiveEntries.length > 0) {
    const attributes = primitiveEntries.reduce((serialized, [key, value]) => {
      return { ...serialized, [kebabCase(key)]: value };
    }, {});
    serializedData = { ...serializedData, attributes };
  }

  // Handle relationships
  if (relationshipEntries.length > 0) {
    const relationships = relationshipEntries.reduce(
      (serialized, [key, value]) => {
        // Multi-relationship
        if (isArray(value)) {
          return {
            ...serialized,
            [kebabCase(key)]: {
              data: value.map(v => serializeJsonApiRequest(v)).map(v => v.data),
            },
          };
        }

        // Single relationship
        return {
          ...serialized,
          [kebabCase(key)]: serializeJsonApiRequest(value),
        };
      },
      {},
    );
    serializedData = { ...serializedData, relationships };
  }

  return { data: serializedData };
}

//
// Deserializes a JSONAPI response.
//
// 1) Flattens returned "data" objects into a single object
// 2) Maps relationships and their includes to keys under objects
//
export function deserializeJsonApiResponse(data: any, included = []) {
  // Note that in order for this to work properly, we have to mutate
  // the original data object. Not a lovely thing, but it is indeed
  // on purpose in this case

  if (isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      data[i] = deserializeJsonApiResponse(data[i], included);
    }
    return data;
  }

  if (isPlainObject(data)) {
    // Prevent infinite circular references by marking if this node has
    // already been processed
    if (data.__processed__) {
      return data;
    }

    data.__processed__ = true;

    for (const key in data.relationships) {
      const relationshipData = data.relationships[key].data;
      let value;
      if (isArray(relationshipData)) {
        value = [];
        for (let i = 0; i < relationshipData.length; i++) {
          let include = included.find(matchDataToInclude(relationshipData[i]));
          if (include) {
            include = deserializeJsonApiResponse(include, included);
            value.push(include);
          } else {
            value.push(relationshipData[i]);
          }
        }
      } else if (relationshipData) {
        let include = included.find(matchDataToInclude(relationshipData));
        if (include) {
          include = deserializeJsonApiResponse(include, included);
          value = include;
        } else {
          value = relationshipData;
        }
      } else {
        value = relationshipData;
      }
      data[camelCase(key)] = value;
    }

    for (const key in data.attributes) {
      data[camelCase(key)] = data.attributes[key];
    }

    delete data.relationships;
    delete data.attributes;
    delete data.__processed__;

    return data;
  }
  return data;
}

//
// Shortcut to handling a raw response for JSONAPI responses.
//
export function handleJsonApiResponse(response: any) {
  const { meta, links, data, included } = response;
  return {
    meta: camelCaseKeysOnObjectRecursively(meta),
    links,
    data: deserializeJsonApiResponse(data, included),
  };
}

//
// Builds a search string based on JSONAPI spec query parameters. Can evolve
// to accommodate our specific needs in the future.
//
// Uses URLSearchParams to take care of encoding and other URL homework.
//
// For now, this is what it can take to build a search string:
//
// {
//   format: 'csv',
//   include: ["relation1", "relation2"],
//   sort: ["field1", "field2"],
//   filter: { name: 'blah', miles: 50 },
//   page: { limit: 50, cursorAfter: "", cursorBefore: "" }
// }
//
export function buildQueryStringFromJsonApiParams(opts: any = {}) {
  let tuples: any[] = [];
  if (opts.format) {
    tuples = [...tuples, ["format", opts.format]];
  }
  if (opts.include) {
    tuples = [...tuples, ["include", opts.include]];
  }
  if (opts.sort && isArray(opts.sort)) {
    tuples = [...tuples, ["sort", opts.sort.join(",")]];
  } else if (opts.sort) {
    tuples = [...tuples, ["sort", opts.sort]];
  }
  if (opts.filter) {
    const filterTuple = Object.keys(opts.filter).reduce(
      (filter: any, filterKey) => {
        return [
          ...filter,
          [`filter[${snakeCase(filterKey)}]`, opts.filter[filterKey]],
        ];
      },
      [],
    );
    tuples = [...tuples, ...filterTuple];
  }
  if (opts.page) {
    const pageTuple = Object.keys(opts.page).reduce((page: any, pageKey) => {
      return [...page, [`page[${snakeCase(pageKey)}]`, opts.page[pageKey]]];
    }, []);
    tuples = [...tuples, ...pageTuple];
  }
  const searchParams = new URLSearchParams(tuples);
  const search = searchParams.toString();
  return search.length > 0 ? `?${search}` : "";
}

//
// url('/api/records', { include: ['resource1', 'resource2'] })
// -> '/api/records?include=resource1,resource2'
//
export function buildUrlFromJsonApiParams(path: any, opts = {}) {
  return path + buildQueryStringFromJsonApiParams(opts);
}

export function matchDataToInclude(data: any) {
  return (i: any) => {
    return i.type === data.type && i.id === data.id;
  };
}

export function relationshipEntryFilter(
  excludeAsRelationship: any,
  [key, value]: any,
) {
  return (
    (isArray(value) || isPlainObject(value)) &&
    !excludeAsRelationship.find((k: any) => k === key)
  );
}

export function buildGatewayChannelFromAuthMode(authMode: AuthModes) {
  return false
    ? ApiGatewayChannel.ClientCredentials
    : authMode === AuthModes.Legacy
    ? ApiGatewayChannel.Public
    : ApiGatewayChannel.UserCredentials;
}

export function buildApiRoutingFromConfig(
  gatewayChannel: ApiGatewayChannel,
  config: RequiredConfigType,
) {
  const routingMode =
    config.apiRouting === "gateway"
      ? ApiRoutingMode.Gateway
      : ApiRoutingMode.Direct;

  const apiEnvIsPr = !isNaN(parseInt(config.apiEnv, 10));

  // Build subidentifier
  const subidentifier =
    apiEnvIsPr && routingMode === ApiRoutingMode.Gateway
      ? config.apiEnv
      : undefined;

  // Build origin
  let origin;

  // Gateway
  if (routingMode === ApiRoutingMode.Gateway) {
    const gatewayOrigin =
      config.apiEnv === "prod"
        ? PROD_GATEWAY_ORIGIN
        : config.apiEnv === "stag"
        ? DEV_GATEWAY_ORIGIN
        : DEVELOPMENT_GATEWAY_ORIGIN;
    const gatewayChannelSuffix =
      gatewayChannel === ApiGatewayChannel.Public
        ? "legacy"
        : gatewayChannel === ApiGatewayChannel.ClientCredentials
        ? "api_auth"
        : "api";
    const gatewayEnvSuffix = apiEnvIsPr
      ? "_preview"
      : config.apiEnv === "prod"
      ? ""
      : config.apiEnv === "stag"
      ? "_staging"
      : "_" + config.apiEnv;
    origin = gatewayOrigin + "/" + gatewayChannelSuffix + gatewayEnvSuffix;
  }

  if (routingMode === ApiRoutingMode.Gateway && config.apiEnv === "sandbox") {
    origin = SANDBOX_GATEWAY_ORIGIN;
  }

  if (routingMode === ApiRoutingMode.Gateway && config.apiEnv === "demo") {
    origin = DEMO_GATEWAY_ORIGIN;
  }

  if (routingMode === ApiRoutingMode.Gateway && config.apiEnv === "unity") {
    origin = UNITY_GATEWAY_ORIGIN;
  }

  if (routingMode === ApiRoutingMode.Direct && config.apiEnv === "prod") {
    throw new EarnnestClientError(
      "[API Routing] must be set to [Gateway] for production use.",
      EarnnestClientErrorType.InvalidConfig,
    );
  }

  if (routingMode === ApiRoutingMode.Direct) {
    const directOrigin =
      config.apiEnv === "demo" ||
      config.apiEnv === "release" ||
      config.apiEnv === "stag"
        ? createDefaultApiOriginFromTemplate({ env: config.apiEnv })
        : apiEnvIsPr
        ? createPreviewApiOriginFromTemplate({ env: config.apiEnv })
        : config.apiEnv;

    origin = directOrigin;
  }

  return {
    routingMode,
    origin: origin as string,
    subidentifier,
  };
}

export function buildApiAuthFromConfigAndToken(
  authMode: AuthModes,
  routingMode: ApiRoutingMode,
  config: RequiredConfigType,
  accessToken: string,
) {
  let authSettings;
  let apiAuthMode;
  if (authMode === AuthModes.Legacy || routingMode === ApiRoutingMode.Gateway) {
    authSettings = { accessToken } as ApiTokenAuth;
    apiAuthMode = ApiAuthMode.Token;
  } else {
    const jwtDecode = require("jwt-decode");
    const payload = jwtDecode(accessToken);
    const { sub: userId } = payload;
    authSettings = {
      userId,
      secret: config.apiSecret,
    } as ApiDirectAuth;
    apiAuthMode = ApiAuthMode.Direct;
  }

  const authProvider =
    authMode === AuthModes.Legacy
      ? ApiAuthProvider.LegacyEarnnest
      : ApiAuthProvider.Auth0;

  return {
    authProvider,
    authMode: apiAuthMode,
    authSettings,
  };
}

export function buildApiRequestSettingsFromConfigAndToken(
  authMode: AuthModes,
  config: RequiredConfigType,
  accessToken: string,
): ApiRequestSettings {
  const gatewayChannel = buildGatewayChannelFromAuthMode(authMode);
  const apiRouting = buildApiRoutingFromConfig(gatewayChannel, config);
  const apiAuth = buildApiAuthFromConfigAndToken(
    authMode,
    apiRouting.routingMode,
    config,
    accessToken,
  );
  return { gatewayChannel, ...apiRouting, ...apiAuth };
}
