import { useRef, useCallback } from "react";

export function useInternalKeyManager(name: string) {
  const keyIndexRef = useRef<number>(0);
  const getKey = useCallback(() => {
    const key = String(name + "-" + (keyIndexRef.current + 1));
    keyIndexRef.current = keyIndexRef.current + 1;
    return key;
  }, [name]);
  return getKey;
}
