/*
 * Legacy auth uses HTTPOnly cookies to transfer refresh tokens to a
 * home-rolled authentication server (see `earnnest-accounts` project), from
 * which the authentication server will determine if it can issue an
 * access token.
 */
import {
  EarnnestClientAuthError,
  EarnnestClientError,
} from "@earnnest/earnnest-ui-web-library/src/errors";
import { runPromiseForMinimumTime } from "@earnnest/earnnest-ui-web-library/src/promiseHelpers";
import { useDispatchOnlyIfMounted } from "@earnnest/earnnest-ui-web-library/src/useDispatchOnlyIfMounted";
import { useSearchParams } from "@earnnest/earnnest-ui-web-library/src/useSearchParams";
import React, {
  ComponentType,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useRequiredConfig } from "../Config/Config";

type LegacyAuthContextType = {
  getAccessTokenSilently: () => Promise<any>;
  logout: () => Promise<void>;
  loginWithRedirect: () => Promise<void>;
  isLoading: boolean;
  isAuthenticated: boolean;
  error?: EarnnestClientAuthError;
};

const LegacyAuthContext = React.createContext<LegacyAuthContextType>({
  getAccessTokenSilently: async () => {},
  logout: async () => {},
  loginWithRedirect: async () => {},
  isLoading: true,
  isAuthenticated: false,
  error: undefined,
});

export const useLegacyAuth = () => useContext(LegacyAuthContext);

export function SetupLegacyAuth({ children }: { children: ReactNode }) {
  const { config } = useRequiredConfig();
  const searchParams = useSearchParams();
  const query = useMemo(() => {
    return Object.fromEntries(searchParams.entries());
  }, [searchParams]);

  const origin = config.legacyAuthApiOrigin as string;

  const [state, dispatch] = useReducer(
    function reducer(state: any, action: any) {
      switch (action.type) {
        case "successfully_authenticated_via_exchange":
        case "successfully_authenticated_via_silence":
          return { ...state, isAuthenticated: true, isLoading: false };
        case "failed_exchange":
          return {
            ...state,
            isAuthenticated: false,
            isLoading: false,
            error: action.payload,
          };
        default:
          throw new EarnnestClientError("Invalid legacy auth action type");
      }
    },
    {
      isLoading: true,
      isAuthenticated: false,
      error: undefined,
    },
  );
  const dispatchOnlyIfMounted = useDispatchOnlyIfMounted(dispatch);

  const logout = useCallback(async () => {
    await runPromiseForMinimumTime(abandonSession(origin));
    console.log("Successfully logged out.");
  }, [origin]);

  // This is a noop. With legacy auth, a login with redirect mechanism isn't
  // supported. We have this simply to keep the contract of our Auth0
  // handler.
  const loginWithRedirect = useCallback(async () => {}, []);

  const doAuthentication = useCallback(async () => {
    const accessToken = await getAccessTokenSilently(origin);
    return accessToken;
  }, [origin]);

  const doExchange = useCallback(
    async query => {
      const {
        source,
        user_id: sourceUserId,
        correlation_id: sourceCorrelationId,
        agent_first_name: firstName,
        agent_last_name: lastName,
        agent_email: email,
        agent_phone: phone,
      } = query;
      await authorize(origin, {
        source,
        sourceUserId,
        sourceCorrelationId,
        role: "agent",
        agent: {
          firstName,
          lastName,
          email,
          phone,
        },
      });
      const accessToken = await doAuthentication();
      return accessToken;
    },
    [origin, doAuthentication],
  );

  const exchangeUser = useCallback(async () => {
    try {
      await runPromiseForMinimumTime(doExchange(query));
      console.log("[AuthLegacy] Successfully authenticated through exchange.");
      dispatchOnlyIfMounted({
        type: "successfully_authenticated_via_exchange",
      });
    } catch (error) {
      console.log(
        "[AuthLegacy] Unable to successfully authorize user [%s].",
        query.user_id,
      );
      dispatchOnlyIfMounted({ type: "failed_exchange", payload: error });
    }
  }, [doExchange, query, dispatchOnlyIfMounted]);

  const tryToAuthenticate = useCallback(async () => {
    try {
      await runPromiseForMinimumTime(doAuthentication());
      console.log("[AuthLegacy] Successfully authenticated.");
      dispatchOnlyIfMounted({ type: "successfully_authenticated_via_silence" });
    } catch (error) {
      console.log(
        "[AuthLegacy] Unable to successfully authenticate silently. Initiating exchange of source information.",
      );
      exchangeUser();
    }
  }, [doAuthentication, exchangeUser, dispatchOnlyIfMounted]);

  const getAccessTokenSilentlyWithOrigin = useCallback(async () => {
    return await getAccessTokenSilently(origin);
  }, [origin]);

  const context = useMemo(() => {
    return {
      getAccessTokenSilently: getAccessTokenSilentlyWithOrigin,
      logout,
      loginWithRedirect,
      isLoading: state.isLoading,
      isAuthenticated: state.isAuthenticated,
      error: state.error,
    };
  }, [
    getAccessTokenSilentlyWithOrigin,
    loginWithRedirect,
    logout,
    state.error,
    state.isAuthenticated,
    state.isLoading,
  ]);

  useEffect(() => {
    console.log("[AuthLegacy] Initializing authentication.");
    tryToAuthenticate();
  }, [tryToAuthenticate]);

  return (
    <LegacyAuthContext.Provider value={context}>
      {children}
    </LegacyAuthContext.Provider>
  );
}

// With this legacy access token, we create an object so we can determine the
// type in our API file.
//
// Icky but works.
export async function getAccessTokenSilently(origin: string) {
  const fetchResponse = await fetch(origin + "/api/authenticate", {
    method: "post",
    credentials: "include",
    headers: { Accept: "application/json" },
  }).then(rejectBadStatus);
  const jsonResponse = await fetchResponse.json();
  return jsonResponse.accessToken as string;
}

export async function authorize(
  origin: string,
  { source, sourceUserId, sourceCorrelationId, role, agent }: any,
) {
  const fetchResponse = await fetch(origin + "/api/authorize", {
    method: "post",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: "exchange",
      source,
      sourceUserId,
      sourceCorrelationId,
      role,
      agent,
    }),
  }).then(rejectBadStatus);
  const jsonResponse = await fetchResponse.json();
  return jsonResponse;
}

export async function abandonSession(origin: string) {
  const fetchResponse = await fetch(origin + "/api/abandon", {
    method: "post",
    credentials: "include",
    headers: { Accept: "application/json" },
  }).then(rejectBadStatus);
  await fetchResponse.json();
}

// This is virtually pulled from the auth0-react project, however, it adds
// support for pushing users to the login page, if `prompt=login` is
// supplied as an option to Auth0
const withLegacyAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: any = {},
): FC<P> => (props: P): JSX.Element => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error,
  } = useLegacyAuth();

  useLegacyAuthErrorHandler(error);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    // We keep the contract the same as our Auth0 handler, but this will be
    // a noop for our legacy handler
    (async (): Promise<void> => {
      await loginWithRedirect();
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? <Component {...props} /> : <>{null}</>;
};

export const RequireLegacyAuth = withLegacyAuthenticationRequired(
  RequireLegacyAuthPlaceholder,
);

function RequireLegacyAuthPlaceholder({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

function useLegacyAuthErrorHandler(error?: EarnnestClientAuthError) {
  if (error) {
    throw error;
  }
}

function rejectBadStatus(response: Response) {
  if (response.ok) {
    return response;
  } else {
    const error = new EarnnestClientAuthError(response.statusText);
    // @ts-ignore
    error.response = response;
    return Promise.reject(error);
  }
}
