/** @jsx jsx */
import { useEffect } from "react";
import { Key } from "./common";

export function useEscape(onEscape: () => void) {
  useEffect(() => {
    function onKeydown(e: KeyboardEvent) {
      if (e.keyCode === Key.Escape) {
        onEscape();
      }
    }
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onEscape]);
}
