import { InlineLink } from "@earnnest/earnnest-ui-web-library/src/InlineLink/InlineLink";
import React from "react";
import { Helmet } from "react-helmet";
import {
  EarnnestClientError,
  EarnnestServerError,
  isErrorFromFailureLoadingChunk,
  EarnnestClientErrorType,
} from "../errors";
import { MarkScreenAsDonePrepping } from "../MarkScreenAsDonePrepping";
import { ErrorClientScreen } from "./ErrorClientScreen";
import { ErrorServerScreen } from "./ErrorServerScreen";
import { TakeoverScreen } from "./TakeoverScreen";

export type ErrorScreenProps = {
  error?: Error;
  onContactSupport?: () => void;
};

const DEFAULT_ON_CONTACT_SUPPORT = () => {};

export function ErrorScreen(props: ErrorScreenProps) {
  const { error, onContactSupport = DEFAULT_ON_CONTACT_SUPPORT } = props;
  return error && isErrorFromFailureLoadingChunk(error) ? (
    // Goes to the same client error screen, but...
    //
    // This error is notable because it's not entirely unknown, and in
    // fact, recoverable. In a future version, we may want to offer
    // the user the ability to reload the page to recover from a
    // stale chunk.
    //
    // Ideally, chunks are prefetched and won't go stale for most
    // users. Some browsers will have old browsers that can't do
    // pre-fetching.
    <ErrorClientScreen
      {...props}
      error={
        new EarnnestClientError(
          "Error loading file",
          EarnnestClientErrorType.CodeSplitError,
        )
      }
    />
  ) : error instanceof EarnnestServerError ? (
    <ErrorServerScreen {...props} error={error} />
  ) : error instanceof EarnnestClientError ? (
    <ErrorClientScreen {...props} error={error} />
  ) : (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <MarkScreenAsDonePrepping />
      <TakeoverScreen
        primaryMessage={<>Well, that’s awkward.</>}
        details={
          <>
            <>We encountered an error and could not complete your request.</>
            <>
              {" "}
              Try refreshing the page. If this issue persists, please{" "}
              <InlineLink onClick={onContactSupport}>
                contact support
              </InlineLink>
              .
            </>
          </>
        }
      />
    </>
  );
}
